import { BrowserRouter, Routes, Route} from "react-router-dom";
import AdminPanel from "./pages/AdminPanel";
import LoginRoute from './pages/LoginRoute';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AdminPanel />} />
      <Route path="/login" element={<LoginRoute />} />
    </Routes>
  </BrowserRouter>
)


export default App;
