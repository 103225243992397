import CommonChart from '../components/CommonChart'
import { useContext, useState } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

type SumItemsType = 'MWh' | 'tCO2' | 'count'

type UbaYearType = '2022' | '2023' | 'total'
type StatusType =
  | 'requested'
  | 'submitted'
  | 'approved'
  | 'declined'
  | 'payout'
  | 'total'

type StatsType = Record<
  UbaYearType,
  Record<StatusType, Record<SumItemsType, number>>
>

export type QuotaAnalyticsType = {
  total: StatsType
  monthly: {
    month: string
    data: StatsType
  }[]
}

const QuotaAnalytics = () => {
  const [ubaYearFilter, setUbaYearFilter] = useState<UbaYearType>('total')
  const [statusFilter, setStatusFilter] = useState<StatusType>('total')

  const { analytics } = useContext(GlobalContext)
  /* Checking if the data is loaded. If it is not loaded, it will show a loading spinner. */
  if (!analytics?.quotas?.res?.monthly?.length) return <CircularProgress />

  /* Destructuring the data from the global state. */
  const quotaAnalytics = analytics.quotas.res as QuotaAnalyticsType
  const monthly = quotaAnalytics.monthly

  /**
   * It takes a type of SumItemsType, and returns an array of numbers
   * @param {SumItemsType} type - SumItemsType
   */
  const sum = (type: SumItemsType): number[] =>
    monthly.map(({ data }) => data[ubaYearFilter][statusFilter][type])

  /**
   * It returns an array of numbers, where each number is the count of a particular status for a
   * particular month
   * @param {StatusType} status - StatusType
   */
  const stats = (status: StatusType): number[] =>
    monthly.map(({ data }) =>
      ['total', status].includes(statusFilter)
        ? data[ubaYearFilter][status].count
        : 0
    )

  /* Creating a data object for the chart. */
  const data = {
    labels: monthly
      .map(data => data.month)
      .map(date => date.split('-').reverse().join('/')),
    datasets: [
      {
        type: 'line' as const,
        fill: true,
        label: 'Count',
        text: 'Count',
        data: sum('count'),
        backgroundColor: '#FFF',
      },
      {
        type: 'line' as const,
        borderColor: '#FFEE93',
        borderWidth: 2,
        fill: false,
        label: 'MWh',
        text: 'MWh',
        data: sum('MWh'),
        backgroundColor: '#FFEE93',
        yAxisID: 'y1',
      },
      {
        type: 'line' as const,
        borderColor: '#FFC09F',
        borderWidth: 2,
        fill: true,
        label: 'tCO2',
        text: 'tCO2',
        data: sum('tCO2'),
        backgroundColor: '#FFC09F',
        yAxisID: 'y1',
      },
      {
        type: 'bar' as const,
        label: 'User Requested',
        text: 'User Requested',
        data: stats('requested'),
        backgroundColor: '#809BCE',
      },
      {
        type: 'bar' as const,
        label: 'Uba Submitted',
        text: 'Uba Submitted',
        data: stats('submitted'),
        backgroundColor: '#EAC4D5',
      },
      {
        type: 'bar' as const,
        label: 'Approved',
        text: 'Approved',
        data: stats('approved'),
        backgroundColor: '#D6EADF',
      },
      {
        type: 'bar' as const,
        label: 'Declined',
        text: 'Declined',
        data: stats('declined'),
        backgroundColor: '#B8E0D2',
      },
      {
        type: 'bar' as const,
        label: 'Payout',
        text: 'Payout',
        data: stats('payout'),
        backgroundColor: '#95B8D1',
      },
    ].filter(dataset => dataset?.label),
  }

  return (
    <Box>
      {/* A radio group that allows the user to select a filter. */}
      <Box display='flex' alignItems='center'>
        <Typography mr={4}>Uba Year:</Typography>
        <RadioGroup
          row
          onChange={event =>
            setUbaYearFilter(event.target.value as UbaYearType)
          }
        >
          <FormControlLabel
            value='2022'
            control={<Radio />}
            label='2022'
            checked={ubaYearFilter === '2022'}
          />
          <FormControlLabel
            value='2023'
            control={<Radio />}
            label='2023'
            checked={ubaYearFilter === '2023'}
          />
          <FormControlLabel
            value='total'
            control={<Radio />}
            label='All'
            checked={ubaYearFilter === 'total'}
          />
        </RadioGroup>
      </Box>

      {/* A radio group that allows the user to select a subfilter. */}
      <Box display='flex' alignItems='center'>
        <Typography mr={4}>Status:</Typography>
        <RadioGroup
          row
          onChange={event => setStatusFilter(event.target.value as StatusType)}
        >
          <FormControlLabel
            value={'requested'}
            control={<Radio />}
            label='Requested'
            checked={statusFilter === 'requested'}
          />
          <FormControlLabel
            value={'submitted'}
            control={<Radio />}
            label='Submitted'
            checked={statusFilter === 'submitted'}
          />
          <FormControlLabel
            value={'approved'}
            control={<Radio />}
            label='Approved'
            checked={statusFilter === 'approved'}
          />
          <FormControlLabel
            value={'declined'}
            control={<Radio />}
            label='Declined'
            checked={statusFilter === 'declined'}
          />
          <FormControlLabel
            value={'payout'}
            control={<Radio />}
            label='Payout'
            checked={statusFilter === 'payout'}
          />
          <FormControlLabel
            value={'total'}
            control={<Radio />}
            label='All'
            checked={statusFilter === 'total'}
          />
        </RadioGroup>
      </Box>

      {/* A component that takes in data and renders a chart. */}
      <CommonChart data={data} />
    </Box>
  )
}

export default QuotaAnalytics
