import { Button } from "@mui/material"
import LockResetIcon from '@mui/icons-material/LockReset'
import toast from "react-hot-toast"
import { ContextScopeEnum } from "../../context/GlobalState"
import { resetPassword } from '../api/resetPassword'

const maxUsersSelected = 100;

/**
 * It returns a button that resets the password of the selected user
 * @param props - { selectedUsernames: string[], setModal: Function, context: ContextScopeEnum }
 * @returns A button that resets the password of a user.
 */
const ResetPasswordAction = (props: { selectedUsernames: string[], setModal: Function, context: ContextScopeEnum }) => {
  const hasUserContext = props.context === ContextScopeEnum.USERS
  const hasSelectedWithLimit = props?.selectedUsernames?.length >= 1 && props?.selectedUsernames?.length < maxUsersSelected
  const selectedUsers = props?.selectedUsernames;
  const show = hasUserContext && hasSelectedWithLimit

  const triggerPassowordReset = async (usernames: string[]) => {
    const failures = [];
    for(let username of usernames) {
      try {
        const res = await resetPassword(username.trim())
        if(res.status === 404) {
          failures.push(username)
        }
      } catch(err) {
        failures.push(username)
      }
    }
    if(!failures.length) {
      toast.success(`Password reset code${usernames.length > 1 ? 's':''} sent`);
    } else {
      toast.error(`Failed to reset password for theese users: ${JSON.stringify(failures)}`)
    }
    return
  }

  if (show) {
    return (
      <Button color="inherit" onClick={() => triggerPassowordReset(selectedUsers)}>
        <LockResetIcon />
        &nbsp;{'Reset Password'}
      </Button>
    )
  }
  return <></>
}

export default ResetPasswordAction
