import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Delete vehicles.
 */
export const deleteVehicles = (token: string): Promise<any> => {
  const subUrl = `vehicle-registrations/${token}`;
  const url = `${API_URL}/${subUrl}`;
  const method = 'DELETE';
  /**
   * Send the request to the provided endpoint
   * and check if the response was successful
   * or not.
   */
  return fetcher(url, {
    method,
  });
};
