import { Stack, TextField, useTheme } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { GlobalContext } from "../context/GlobalState"
import { getUsersPromise } from "./api/getUsers"
import { searchUsersPromise } from "./api/searchUsers"

const SearchUsers = () => {
  const { setUsers } = useContext(GlobalContext)
  const theme = useTheme()
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [usernames, setUsernames] = useState('')

  const AllSetters: any = {
    setUsername: setUsername,
    setUsernames: setUsernames,
    setEmail: setEmail,
    setFirstName: setFirstName,
    setLastName: setLastName,
  };

  const handleFieldUpdate = (setterName: string, value: string) => {
    setUsername('')
    setEmail('')
    setFirstName('')
    setLastName('')
    setUsernames('')

    const theValue = setterName === 'setEmail' ? value.toLowerCase() : value;
    AllSetters[setterName](theValue);
  }

  const search = async () => {
    try {
      if (usernames) {
        const { data } = await searchUsersPromise(usernames)
        setUsers(data)
      } else {
        const { data } = await getUsersPromise({
          username,
          email,
          firstName,
          lastName,
        })
        setUsers(data)
      }
    } catch (err) {
      toast.error('Failed to fetch user data')
    }
  }
  useEffect(() => {
    const delaySearch = setTimeout(search, 800)
    return () => clearTimeout(delaySearch)
  }, [username, email, firstName, lastName, usernames])

  return (
    <Stack direction='row' spacing={theme.spacing(2)}>
      <TextField
        label='Username'
        variant='outlined'
        value={username}
        onChange={event => handleFieldUpdate('setUsername', event.target.value)}
      />
      <TextField
        label='Email'
        variant='outlined'
        value={email}
        onChange={event => handleFieldUpdate('setEmail', event.target.value)}
      />
      <TextField
        label='First Name'
        variant='outlined'
        value={firstName}
        onChange={event => handleFieldUpdate('setFirstName', event.target.value)}
      />
      <TextField
        label='Last Name'
        variant='outlined'
        value={lastName}
        onChange={event => handleFieldUpdate('setLastName', event.target.value)}
      />
      <TextField
        label='Usernames'
        variant='outlined'
        value={usernames}
        onChange={event => handleFieldUpdate('setUsernames', event.target.value)}
        helperText='Bulk search users by usernames (comma seperated)'
      />
    </Stack>
  )
}

export default SearchUsers
