import { calculateVinCheckDigit } from "../../../../utils/vin-utils";

/**
 * Enumerates the type of validation
 * errors the user might encounter.
 * Based on this enum we identify what
 * type of error message should be
 * displayed to the user.
 */
enum VALIDATION_ERROR_TYPE {
  MIN_LENGTH,
  MAX_LENGTH,
  EMAIL,
  REQUIRED,
  OWNERUSERNAME,
  EQUALS,
  INCORRECT_STATUS,
  INCORRECT_VIN
}

/**
 * Check if the provided value is truthy
 * or falsey.
 * 
 * @param value any
 * @returns boolean
 */
export const required = (value: any): string => {
  return !!value ? '' : errorMessages.find(e => e.type === VALIDATION_ERROR_TYPE.REQUIRED)?.message || 'required';
}

/**
 * Check if there is a value because it depends
 * if the email address was filled and the owner
 * id was found.
 * 
 * @param value any
 * @returns boolean
 */
 export const ownerUsernameFound = (value: any): string => {
  return !!value ? '' : errorMessages.find(e => e.type === VALIDATION_ERROR_TYPE.OWNERUSERNAME)?.message || '';
}

/**
 * Check if the provided value is a valid
 * email address.
 * 
 * @param value string
 * @returns boolean
 */
export const email = (value: string): string => {
  const regex = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  return regex.test(value) ? '' : errorMessages.find(e => e.type === VALIDATION_ERROR_TYPE.EMAIL)?.message || '';
}

/**
 * Check if the provided value has the provided
 * minimum length.
 * 
 * @param value string
 * @param length number
 * @returns boolean
 */
export const minLength = (value: string, length: number = 3): string => {
  return value && value.length >= length ? '' : errorMessages.find(e => e.type === VALIDATION_ERROR_TYPE.MIN_LENGTH)?.message || '';
}

/**
 * Check if the provided value has the provided
 * maximum length.
 * 
 * @param value string
 * @param length number
 * @returns boolean
 */
export const maxLength = (value: string, length: number = 30): string => {
  return value && value.length <= length ? '' : errorMessages.find(e => e.type === VALIDATION_ERROR_TYPE.MAX_LENGTH)?.message || '';
}

/**
 * Check if the provided value matches the
 * provided property value.
 * 
 * @param value string
 * @param requiredValue any
 * @returns boolean
 */
export const equals = (requiredValues: any[]): (value: any) => string => {
  return (value: any) => 
    value && requiredValues.includes(value) ? '' : errorMessages.find(e => e.type === VALIDATION_ERROR_TYPE.EQUALS)?.message || '';
}

/**
 * Check if the provided value matches the
 * provided property value.
 * 
 * @param value string
 * @param requiredValue any
 * @returns boolean
 */
export const noValue = (value: any): string => {
  return !!!value ? '' : errorMessages.find(e => e.type === VALIDATION_ERROR_TYPE.EQUALS)?.message || '';
}

/**
 * Check if the provided value matches the
 * provided property value.
 * 
 * @param value string
 * @param requiredValue any
 * @returns boolean
 */
 export const hasCorrectStatus = (value: any): string => {
  return ['INCOMPLETE_RESUBMIT', 'UPLOADED', 'AI_PARSED'].includes(value) ? '' : errorMessages.find(e => e.type === VALIDATION_ERROR_TYPE.INCORRECT_STATUS)?.message || '';
}

/**
 * It returns an error message if the value is not equal to 'INCOMPLETE'
 * @param {any} value - any - the value of the field being validated
 * @returns A function that takes a value and returns a string.
 */
export const hasIncompleteStatus = (value: any): string => {
  return value === 'INCOMPLETE'
    ? ''
    : errorMessages.find(e => e.type === VALIDATION_ERROR_TYPE.INCORRECT_STATUS)
        ?.message || ''
}

/**
 * Defines the error messages based on the type
 * of validation error.
 */
const errorMessages = [
  { type: VALIDATION_ERROR_TYPE.MIN_LENGTH, message: `Sollte mindestens 3 Zeichen haben.` },
  { type: VALIDATION_ERROR_TYPE.MAX_LENGTH, message: `Sollte maximal 30 Zeichen lang sein.` },
  { type: VALIDATION_ERROR_TYPE.EMAIL, message: `Keine gültige E-Mail-Adresse.` },
  { type: VALIDATION_ERROR_TYPE.REQUIRED, message: `Dieses Feld sollte einen Wert enthalten.` },
  { type: VALIDATION_ERROR_TYPE.OWNERUSERNAME, message: `E-Mail ausfüllen und Suchen drücken.` },
  { type: VALIDATION_ERROR_TYPE.EQUALS, message: `Das Feld entspricht nicht dem erforderlichen Wert.` },
  { type: VALIDATION_ERROR_TYPE.INCORRECT_STATUS, message: `Das Fahrzeug hat nicht den richtigen Status.` },
  { type: VALIDATION_ERROR_TYPE.INCORRECT_VIN, message: `Die FIN scheint nicht korrekt zu sein.` },
];

export const isValidVin = (value: string | undefined, checkDigit?: string) => {
  if (!value) {
    return '';
  }

  if (!checkDigit || checkDigit !== calculateVinCheckDigit(value)) {
    return errorMessages.find(e => e.type === VALIDATION_ERROR_TYPE.INCORRECT_VIN).message;
  }
  
  return '';
}

/**
 * The configuration of the validators used
 * for fields.
 */
 export const validatorsConfigurationByFieldName = [
  { fieldName: 'firstName', validators: [] },
  { fieldName: 'lastName', validators: [required] },
  { fieldName: 'email', validators: [required, email] },
  { fieldName: 'ownerUsername', validators: [ownerUsernameFound] },
  { fieldName: 'remoteEmail', validators: [required, email] },
  { fieldName: 'TemplateName', validators: [required, minLength] },
  { fieldName: 'SubjectPart', validators: [required, minLength] },
];

