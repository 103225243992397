import { Button } from "@mui/material";
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import PersonIcon from '@mui/icons-material/Person';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param selectedUsernames string
 * @returns boolean
 */
const AddUserAction = (props: { selectedUsernames: string[], setModal: Function, context: ContextScopeEnum }) => {
  const hasNoSelection = !props.selectedUsernames.length;
  const hasOneOrNoSelection = !props.selectedUsernames.length || (props.selectedUsernames && props.selectedUsernames.length === 1);
  const hasVehicleContext = props.context === ContextScopeEnum.USERS;
  const isSelection = props.selectedUsernames && props.selectedUsernames.length;
  const isEdit = isSelection;
  const show = hasOneOrNoSelection && hasVehicleContext;

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.setModal({ type: ContextActionEnum.ADD_USER })}>
        <PersonIcon />
        &nbsp;{isEdit ? 'Edit User' : 'New User'}
      </Button>
    );
  }
  return <></>;
}

export default AddUserAction;