import { Button } from "@mui/material";
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import PersonIcon from '@mui/icons-material/Person';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param selectedUsernames string
 * @returns boolean
 */
const DeleteUserAction = (props: { selectedUsernames: string[], setModal: Function, context: ContextScopeEnum }) => {
  const hasUserContext = props.context === ContextScopeEnum.USERS;
  const hasLestThan50Selection = props.selectedUsernames.length >= 1 && props.selectedUsernames.length <= 50
  const show = hasUserContext && hasLestThan50Selection

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.setModal({ type: ContextActionEnum.DELETE_USER })}>
        <PersonIcon />
        &nbsp;{`Delete ${props.selectedUsernames.length} Users`}
      </Button>
    );
  }
  return <></>;
}

export default DeleteUserAction;