import { Button } from "@mui/material";
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { IFilterItem } from "../../common/FilterMenu";

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds number
 * @returns boolean
 */
const AddVehicleAction = (props: { predefinedFilters: IFilterItem[], selectedIds: number[], setModal: Function, context: ContextScopeEnum }) => {
  const hasOneOrNoSelection = (props.selectedIds && props.selectedIds.length === 1) || !props.selectedIds.length;
  const hasVehicleContext = props.context === ContextScopeEnum.VEHICLES;
  const isSelection = props.selectedIds && props.selectedIds.length;
  const show = hasOneOrNoSelection && hasVehicleContext;
  const isEdit = isSelection;

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.setModal({ type: ContextActionEnum.ADD_VEHICLE })}>
        <ElectricCarIcon />
        &nbsp;{isEdit ? 'Edit vehicle' : 'New Vehicle'}
      </Button>
    );
  }
  return <></>;
}

export default AddVehicleAction;