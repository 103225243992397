import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'
import { IDenseTableRow } from '../../common/dialog/ResultsTable/ResultsTable'
import { ContextActionEnum } from '../../context/GlobalState'

/**
 * It takes an array of numbers, and returns a promise that resolves to null
 * @param {number[]} ids - number[] - an array of quota sale ids
 * @returns A promise that resolves to null.
 */
const declineQuotaIDs = (ids: number[]): Promise<any> => {
  if (!ids.length) return new Promise(resolve => resolve(null))
  const url = `${API_URL}/quota-sales/markFailed`
  return fetcher(`${url}`, {
    method: 'PUT',
    body: JSON.stringify({ quotaSaleIds: ids }),
  }).then()
}

/**
 * Fetch the latest quota sale id for the list of
 * provided vehicle ids.
 */
export const declineAllQuotaIds = (
  currentState: IDenseTableRow[],
  triggerActionCallback: Function,
  setCurrentState: Function,
  stateWhenDone: any
) => {
  /**
   * Decide which endpoint we have to
   * call.
   */
  if (currentState && currentState.length) {
    ;(async () => {
      let currentRequest = 1

      const quotaSaleIds = currentState.map(row => row.quotaId as number)
      const response = await declineQuotaIDs(quotaSaleIds)
      const body = await response?.data

      currentState.forEach(row => {
        try {
          const found = body.find((qs: any) => qs.id === row.quotaId)?.id
          if (!found) throw new Error()
          row.declineStatus = 200
          // Set the trigger action update.
          triggerActionCallback({
            type: ContextActionEnum.BULK_DECLINE_QUOTA_SALES,
            progress: (currentRequest++ * 100) / currentState.length,
          })
        } catch (error) {
          row.declineStatus = 409
          // Set the trigger action update.
          triggerActionCallback({
            type: ContextActionEnum.BULK_DECLINE_QUOTA_SALES,
            progress: (currentRequest++ * 100) / currentState.length,
          })
        }
      })
      setCurrentState(stateWhenDone)
    })()
  }
}
