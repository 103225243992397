import { Button } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { ContextScopeEnum, ContextActionEnum } from "../../context/GlobalState";
import { IFilterItem, FILTER_TYPE_ENUM } from "../../common/FilterMenu";

/**
 * Trigger the Quota Sale approval process for
 * the selected vehicles.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds number
 * @returns boolean
 */
const CreateUBASubmissionQuotaSalesAction = (props: { predefinedFilters: IFilterItem[], selectedIds: number[], setModal: Function, context: ContextScopeEnum }) => {
  const hasRequiredContext = props.context === ContextScopeEnum.QUOTAS;
  const hasPredefinedFilters = !!props.predefinedFilters && !!props.predefinedFilters.length;
  const isUserRequested = props?.predefinedFilters?.find(
    filter => filter?.type === FILTER_TYPE_ENUM.USER_REQUESTED
  )
  const hasNoneSelected = !!!props.selectedIds.length;
  const show = hasPredefinedFilters && hasNoneSelected && isUserRequested && hasRequiredContext;

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.setModal({ type: ContextActionEnum.CREATE_UBA_SUBMISSION, ids: props.selectedIds })}>
        <DownloadIcon />
        &nbsp;UBA Abgabe erstellen
      </Button>
    );
  }
  return <></>;
}

export default CreateUBASubmissionQuotaSalesAction;