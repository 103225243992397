import { FC, Dispatch, SetStateAction, MouseEventHandler } from 'react'
import { Paper, Typography, Button } from '@mui/material';

export type PdfPreviewPaginationProps = {
  pageNumber: number;
  numPages: number;
  visible?: boolean;
  setPageNumber: Dispatch<SetStateAction<number>>;
}

export const PdfPreviewPagination: FC<PdfPreviewPaginationProps> = ({ pageNumber, numPages, visible, setPageNumber }) => {
  if (numPages <= 0) {
    return null;
  }

  const showPreviousPage: MouseEventHandler = (event) => {
    event.stopPropagation();
    setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  }

  const showNextPage: MouseEventHandler = (event) => {
    event.stopPropagation();
    setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));
  }

  return (
    <Paper
      data-testid="pdf-preview-pagination"
      elevation={5}
      sx={{
        position: 'absolute',
        bottom: '5%',
        left: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'common.white',
        opacity: visible ? 100 : 0,
        transform: 'translate(-50%)',
        transition: 'opacity ease-in-out .2s',
        borderRadius: 1
      }}
    >
      <Button
        data-testid="pdf-preview-pagination-prev"
        type="button"
        disabled={pageNumber <= 1}
        sx={{ px: 2, minWidth: 0 }}
        onClick={showPreviousPage}
      >‹</Button>
      <Typography component="span">{`${pageNumber} / ${numPages}`}</Typography>
      <Button
        data-testid="pdf-preview-pagination-next"
        type="button"
        sx={{ px: 2, minWidth: 0 }}
        disabled={pageNumber >= numPages}
        onClick={showNextPage}
      >›</Button>
    </Paper>
  );
}
