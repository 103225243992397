import { Button } from "@mui/material";
import { ContextScopeEnum } from "../../context/GlobalState";
import DownloadIcon from '@mui/icons-material/Download';

/**
 * "If the context is vehicles and there are no selected vehicles, then show the download vehicles CSV
 * button."
 * 
 * The function is a React component that returns a button. The button is only shown if the context is
 * vehicles and there are no selected vehicles
 * @param props - { downloadVehiclesCSV: Function, context: ContextScopeEnum, selectedIds: number[] }
 * @returns A button that will download a CSV of all vehicles.
 */
const DownloadVehiclesCSVAction = (props: { downloadVehiclesCSV: Function, context: ContextScopeEnum, selectedIds: number[] }) => {
  const show = props.context === ContextScopeEnum.VEHICLES;
  const hasNoneSelected = !props.selectedIds.length;

  if (show && hasNoneSelected) {
    return (
      <Button color="inherit" onClick={() => props.downloadVehiclesCSV(false)}>
        <DownloadIcon />
        &nbsp;Vehicles CSV
      </Button>
    );
  }
  return <></>;
}

export default DownloadVehiclesCSVAction;