import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Approve or fail the vehicle audit.
 *
 * @param vehicleId number
 * @param approve boolean
 * @returns Promise<any>
 */
export const manuallyMarkVehicleAudit = (
  vehicleId: number,
  approve: boolean
): Promise<any> => {
  const actionString = approve ? 'markApproved' : 'markFailed';
  const url = `${API_URL}/vehicle-registrations/${vehicleId}/${actionString}`;
  return fetcher(`${url}`, {
    method: 'PUT',
  }, undefined, { skipReadOfBody: true });
};
