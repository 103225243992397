import { Button } from "@mui/material";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import { IFilterItem, FILTER_TYPE_ENUM } from "../../common/FilterMenu";

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds number
 * @returns boolean
 */
const SellQuotaSalesAction = (props: { predefinedFilters: IFilterItem[], selectedIds: number[], setModal: Function, context: ContextScopeEnum }) => {
  const hasRequiredContext = props.context === ContextScopeEnum.QUOTAS;
  const hasPredefinedFilters = !!props.predefinedFilters && !!props.predefinedFilters.length;
  const isUBAApproved = props?.predefinedFilters?.find(
    filter => filter?.type === FILTER_TYPE_ENUM.UBA_APPROVED
  )
  const hasNoneSelected = !props.selectedIds.length;
  const show = hasPredefinedFilters && isUBAApproved && hasRequiredContext;

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.setModal({ type: ContextActionEnum.BULK_SELL_QUOTAS, ids: props.selectedIds })}>
        <AddBusinessIcon />
        &nbsp;{hasNoneSelected ? 'Verkaufen Sie ALLE genehmigten Quoten' : 'Ausgewählte Quoten verkaufen'}
      </Button>
    );
  }
  return <></>;
}

export default SellQuotaSalesAction;