import { Box, Stack, Switch, Toolbar, Typography } from '@mui/material'
import { useState } from 'react'
import Dashboard from './components/Dashboard'
import Summary from './components/Summary'

const Analytics = () => {
  const [tab, setTab] = useState<'summary' |'dashboard'>('summary')

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: theme =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />

      <Stack direction='row' spacing={1} alignItems='center'>
        <Typography>Dashboard</Typography>
        <Switch checked={tab === 'summary'} onChange={event => setTab(event.target.checked ? 'summary' : 'dashboard')} />
        <Typography>Summary</Typography>
      </Stack>

      {tab === 'summary' && <Summary />}
      {tab === 'dashboard' && <Dashboard />}
    </Box>
  )
}

export default Analytics
