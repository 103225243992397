// import { isDev, isProd } from '../constants/environment';
import { WHITELABEL_TENANT as defaultTenant } from '../constants/api';

export const useTenant = (): 'carbonify' | 'adac' | 'allianz' => {
  // const { query } = useRouter();

  /**
   * Don't allow this logic if we are on production
   */
  // if (isProd) {
  //   return defaultTenant;
  // }

  /**
   * Extracts the tenant string from the url
   */
  const createTenant = (): 'carbonify' | 'adac' | 'allianz' => {
    // if (!query.tenant) {
    //   return defaultTenant;
    // }

    // switch (query.tenant) {
    //   case 'carbonify':
    //     return 'carbonify';
    //   case 'adac':
    //     return 'adac';
    //   case 'allianz':
    //     return 'allianz';
    //   default:
    //     return defaultTenant;
    // }
    return 'carbonify'
  };

  return createTenant();
};
