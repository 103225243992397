import { Button } from "@mui/material";
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import DownloadIcon from '@mui/icons-material/Download';
import { useFeature } from "../../../../hooks/useFeature";

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds number
 * @returns boolean
 */
const DownloadB2BtoXML = (props: { downloadXML: Function, context: ContextScopeEnum, selectedIds: number[] }) => {
  const tab = props.context === ContextScopeEnum.INVOICING;
  const hasNoneSelected = !props.selectedIds.length;
  const allowedFeature = useFeature('DownloadBankTransferExports')
  const show = tab && hasNoneSelected && allowedFeature

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.downloadXML()}>
        <DownloadIcon />
        &nbsp;Download B2B XML
      </Button>
    );
  }
  return <></>;
}

export default DownloadB2BtoXML;