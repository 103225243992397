import { useNavigate } from "react-router-dom"
import { removeTokens } from '../utils/auth/auth';

export const useLogout = () => {
  const navigate = useNavigate();

  return () => {
    removeTokens();
    navigate('/login');
  };
};
