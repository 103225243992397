import jwt_decode from "jwt-decode";

export const TOKEN_KEY = 'carbonify-auth-token';
export const REFRESH_TOKEN_KEY = 'carbonify-refresh-token';

/**
 * Saves the cookie to browser cookie storage
 */
export const saveTokens = (token: string, refreshToken: string) => {
  // setCookies(TOKEN_KEY, token);
  setTimeout(() => {
    document.cookie = `${TOKEN_KEY}=${token}; path=/`;
    document.cookie = `${REFRESH_TOKEN_KEY}=${refreshToken}; path=/`;
  }, 0);
};

/**
 * Gets the token from the cookie storage
 */
export const readToken = () => {
  // return getCookie(TOKEN_KEY);
  let name = `${TOKEN_KEY}=`;
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
/**
 * Gets the token from the cookie storage
 */
export const readRefreshToken = () => {
  // return getCookie(TOKEN_KEY);
  let name = `${REFRESH_TOKEN_KEY}=`;
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/**
 * Removes the cookie from browser cookie storage
 */
export const removeTokens = () => {
  // removeCookies(TOKEN_KEY);
  document.cookie = `${TOKEN_KEY}=${''}; path=/`;
  document.cookie = `${REFRESH_TOKEN_KEY}=${''}; path=/`;
};

/**
 * Check if the currently stored JWT token as a cookie
 * contains the admin role.
 * 
 * @returns boolean
 */
export const isAdmin = () => {
  // const token = readTokenServerSide(ctx);
  const token = readToken();
  if (token) {
    // Decode the jwt token.
    const properties: any = jwt_decode(token.toString());
    // Check if it contains the custom:role property.
    const hasCustomRole = ('custom:role' in properties);
    // Check if custom:role is admin.
    const hasAdminRole = properties['custom:role'] === 'admin';
    return hasCustomRole && hasAdminRole;
  }
  return false;
}

/**
 * Creates authentication headers for making authed requests
 */
export const createAuthHeaders = {
  Authorization: `Bearer ${readToken()}`,
};
