import { Button } from '@mui/material'
import PublishIcon from '@mui/icons-material/Publish'
import { ContextActionEnum, ContextScopeEnum } from '../../context/GlobalState'
import { IFilterItem, FILTER_TYPE_ENUM } from '../../common/FilterMenu'

/**
 * It returns a button that opens a modal to mark quotas as submitted
 * @param props - {
 * @returns A button that will mark quotas as submitted.
 */
const SubmitQuotaSalesAction = (props: {
  predefinedFilters: IFilterItem[]
  selectedIds: number[]
  setModal: Function
  context: ContextScopeEnum
}) => {
  const hasRequiredContext = props?.context === ContextScopeEnum.QUOTAS
  const hasPredefinedFilters = !!props?.predefinedFilters?.length
  const isUserRequested = props?.predefinedFilters?.find(
    filter => filter?.type === FILTER_TYPE_ENUM.USER_REQUESTED
  )
  const hasSelectedQuotas = props?.selectedIds?.length
  /* Checking if the user has selected any quotas and if the user has the correct context. */
  const show =
    hasPredefinedFilters &&
    isUserRequested &&
    hasRequiredContext &&
    hasSelectedQuotas

  if (show) {
    return (
      <Button
        color='inherit'
        onClick={() =>
          props.setModal({
            type: ContextActionEnum.SUBMIT_QUOTAS,
            ids: props.selectedIds,
          })
        }
      >
        <PublishIcon />
        &nbsp;Mark Submitted
      </Button>
    )
  }
  return <></>
}

export default SubmitQuotaSalesAction
