import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
  Avatar,
  Box,
  Chip,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Slider,
} from '@mui/material';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { blue } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { templateNames } from '../../../../utils/api/templateNames';
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export interface IBulkEmailDialogProps {
  selectedUsernames: string[]
  setModal: Function
  sendBulkEmails: Function
  total: number
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function BulkEmailDialog(props: IBulkEmailDialogProps) {
  // Set the template loading state.
  const [loading, setLoading] = useState<boolean>(false);
  // Set the list of templates that have been remotely fetched.
  const [templates, setTemplates] = useState<any[]>([]);
  // Holds the selected template name.
  // We use this to highlight the user selection.
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');

  /**
   * Get the available templates that can
   * be used for sending out bulk emails.
   */
  const getTemplates = () => {
    setLoading(true);
    templateNames()
      .then((result) => {
        setTemplates([...result.templateNames]);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  /**
   * Get the templates when this modal opens
   * up.
   */
  useEffect(() => {
    getTemplates();
  }, []);

  /**
   * Handle the selection of a template.
   */
  const handleSelect = (templateName: string) => {
    setSelectedTemplate(templateName);
    // props.sendBulkEmails(props.selectedUsernames, templateName);
  };

  /**
   * Handle the selection change via the
   * available slider.
   *
   * @param selected number
   */
  const handleSelectionChange = (event: Event, selected: number | number[]) => {
    // console.log('selected:', selected);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => props.setModal(null)}
        >
          Send bulk email action.
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* General action description. */}
          <Typography>
            You are about to send bulk email to {props.selectedUsernames.length}{' '}
            selected {props.selectedUsernames.length > 1 ? 'users' : 'user'} out of a
            total of {props.total}.
          </Typography>

          {/* Indication about increasing bulk emails number. */}
          {/* <Typography>
            Please adjust the slider if you want to change the number of bulk emails you want to send.
          </Typography> */}

          {/* Selection slider. */}
          {/* <Slider
            aria-label="Temperature"
            size='medium'
            defaultValue={props.selectedUsernames.length}
            valueLabelDisplay="auto"
            step={100}
            onChange={handleSelectionChange}
            marks
            min={10}
            max={props.total}
          /> */}

          {/* Select template description. */}
          <Typography gutterBottom>
            Please chose the template you wish to use for sending out the bulk
            emails.
          </Typography>

          <Box display="flex" justifyContent="">
            <Paper style={{ width: '100%', maxHeight: 300, overflow: 'auto' }}>
              {/* Show the list of templates if loaded. */}
              {!loading && templates && templates.length && (
                <List sx={{ pt: 1 }}>
                  {templates.map((template: any) => (
                    <ListItem
                      key={template}
                      button
                      selected={selectedTemplate === template}
                      onClick={() => handleSelect(template)}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                          <ChromeReaderModeIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={template} />
                    </ListItem>
                  ))}
                </List>
              )}

              {/* Show progress indicator if templates still loading. */}
              {loading && <CircularProgress />}
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            disabled={!selectedTemplate}
            onClick={() =>
              props.sendBulkEmails(props.selectedUsernames, selectedTemplate)
            }
          >
            Confirm
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
