import { stringify } from 'query-string'
import { fetcher } from '../fetcher'
import { API_URL } from '../../constants/api'
/**
 * It takes in a username or email and returns a Cognito user object
 * @param  - username - the username of the user
 */
export const getCognitoUser = ({ username, email }: { username?: string, email?: string }) => {
  const stringParams = stringify({ username, email })
  const url = `${API_URL}/users/user?${stringParams}`

  return fetcher(url, {
    method: 'GET',
  })
}
