import { API_URL } from "../../../../../constants/api";
import { fetcher } from "../../../../../utils/fetcher";

/**
 * Delete the quota sale with the
 * provided ID.
 * 
 * @param id number
 * @returns Promise<any>
 */
export const deleteQuotaSale = (id: number): Promise<any> => {
  if (!id) return new Promise((resolve, reject) => reject(null));
  const url = `${API_URL}/quota-sales/${id}`;
  return fetcher(`${url}`, {
    method: 'DELETE',
  })
  .then();
};
