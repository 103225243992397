import { Button } from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import { FILTER_TYPE_ENUM, IFilterItem } from "../../common/FilterMenu";

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selected string
 * @returns boolean
 */
const GenerateInvoiceAction = (props: {
  predefinedFilters: IFilterItem[]
  selectedIds: number[]
  setModal: Function
  context: ContextScopeEnum
}) => {
  const hasRequiredContext = props.context === ContextScopeEnum.QUOTAS
  const hasNoQuotasSelected = !props.selectedIds.length
  const isTabSold = props?.predefinedFilters?.find(
    filter => filter?.type === FILTER_TYPE_ENUM.SOLD
  )
  const show = hasRequiredContext && hasNoQuotasSelected && isTabSold

  if (show) {
    return (
      <Button
        color='inherit'
        onClick={() =>
          props.setModal({ type: ContextActionEnum.GENERATE_INVOICE })
        }
      >
        <ForwardToInboxIcon />
        &nbsp;Generate invoices
      </Button>
    )
  }
  return <></>
}

export default GenerateInvoiceAction;