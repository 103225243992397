import { Box, Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

/**
 * It's a text field that takes a number and a button that jumps to that page
 * @param  - `jump` - a function that takes a page number and jumps to that page
 * @returns A React component
 */
const JumpTo = ({
  jump,
  currentPage,
}: {
  jump: Function
  currentPage: number
}) => {
  const [jumpTo, setJumpTo] = useState(0)

  const handleJump = (event: any) => {
    try {
      const page = parseInt(event.target.value)
      if (page) setJumpTo(page)
      else setJumpTo(0)
    } catch (err) {
      setJumpTo(0)
    }
  }

  useEffect(() => setJumpTo(currentPage), [currentPage])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        m: 2,
      }}
    >
      <TextField
        label='Jump to page'
        size='small'
        variant='outlined'
        value={jumpTo}
        onChange={handleJump}
      />
      <Button variant='contained' size='small' onClick={() => jump(jumpTo)}>
        Jump
      </Button>
    </Box>
  )
}

export default JumpTo
