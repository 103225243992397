import { toast } from 'react-hot-toast';
import { refreshToken } from './api/refreshToken';
import { readRefreshToken, readToken, removeTokens, saveTokens } from './auth/auth';
import { jsonifyAndInjectTotalCount } from './jsonifyAndInjectTotalCount';

type ICustomHeaders = { [key: string]: string }

export interface FetcherConfig {
  skipReadOfBody?: boolean;
  skipErrorHandling?: boolean;
}

/**
 * Generate the request response.
 * It will also set the totalCount property on the
 * response object when it's the case.
 *
 * @param input RequestInfo
 * @param options RequestInit
 * @param customHeaders ICustomHeaders
 * @returns 
 */
const performRequest = async (input: RequestInfo, options?: RequestInit, customHeaders?: ICustomHeaders, config?: FetcherConfig): Promise<any> => {
  // Replace the common headers with the custom headers if any.
  const headersInput = customHeaders ?? {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${readToken()}`,
  };
  const headers = new Headers(headersInput);
  const requestConfig = options ? { ...options, headers } : { headers };

  /**
   * Add the x-total-count from the response header.
   * If it's not present then it will have value 0.
   */
  try {
    const response = await fetch(input, requestConfig);

    /* Skip the reading of the body of the response, if required */
    if (config?.skipReadOfBody) {
      return response;
    }

    /* Default error handler */
    if (!response.ok && !config?.skipErrorHandling) {
      const error = await parseResponseJsonError(response);
      toast.error('Leider ist ein Fehler aufgetreten. Bitte sende einen Screenshot an unsere IT-Abteilung.' + (error ? ` Nachricht vom Server: ${error}.` : ''));
    }

    /**
     * Inject the totalCount object into the response
     * data and re-create the promise so the rest of
     * the application is not affected.
     * 
     * TODO the function is expected to return undefined
     * in case of errors. This has to be improved.
     */
    return response.ok ?
      // Normal handle of the response for this case.
      jsonifyAndInjectTotalCount(response) :
      // Check if response 401 so we can init refresh of token.
      handleNotOk(response);
  } catch (error) {
    if (config?.skipReadOfBody) {
      throw error;
    }
    
    /* Default error handler */
    if (!config?.skipErrorHandling) {
      toast.error(`Leider ist ein Fehler aufgetreten. Bitte sende einen Screenshot an unsere IT-Abteilung. Nachricht vom Server: ${error}`);
    }
    
    /**
     * TODO the function is expected to return undefined
     * in case of errors. This has to be improved.
     */
    return undefined;
  }
};

const handleNotOk = (response: Response) => {
  // The case where we are unauthorized.
  if (!response.ok && response.status === 401) {
    return { message: 'Unauthorized' };
  }
  if (!response.ok && response.status === 400) {
    throw new Error('Invalid request');
  }
  return undefined;
}

/**
 * Renew the existing token if the provided response has the
 * status 401.
 *
 * @param response any
 * @param input RequestInfo
 * @param options RequestInit
 * @returns 
 */
const renewToken = async (input: RequestInfo, options?: RequestInit): Promise<any> => {
  // use refresh token to re-authenticate user
  const refrToken = readRefreshToken()
  const refreshResp = await refreshToken(refrToken)
  // if refresh fails
  if (!refreshResp?.token) {
    // logout
    removeTokens();
    // navigate
    window.location.replace('/login')
    return
  }
  // update tokens
  saveTokens(refreshResp?.token, refreshResp?.refreshToken);
  // re-call the api, create new request to apply new tokens
  return await performRequest(input, options)
}

/**
 * Prepare and get the API request response
 * or throw the necessary errors if it's the case.
 *
 * @param input RequestInfo
 * @returns Promise<any>
 */
export const fetcher = async (input: RequestInfo, options?: RequestInit, customHeaders?: ICustomHeaders, config?: FetcherConfig): Promise<any> => {
  // make request
  const response = await performRequest(input, options, customHeaders, config);

  // Check if the response status is 401 then refresh the used token.
  if (response?.message === 'Unauthorized') {
    return renewToken(input, options);
  }

  return response;
};

export const parseResponseJsonError = async (response: Response): Promise<string | null> => {
  if (response.ok) {
    return null;
  }

  const jsonResponse = await response.json();
  if (typeof jsonResponse !== 'object' || typeof jsonResponse?.error !== 'string') {
    return null;
  }

  return jsonResponse.error;
};