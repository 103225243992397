import { Container, Grid, Typography } from '@mui/material'
import { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import SummaryItem from './SummaryItem'

/* A React component that is used to display the summary of the analytics. */
const Summary = () => {
  const { analytics } = useContext(GlobalContext)

  if (!analytics.users || !analytics.vehicles || !analytics.quotas) return <>No data yet</>

  /* Configuration for summary analytics. */
  const config: Record<string, Record<string, string | number>> = {
    Registrierungen: {
      Benutzer: analytics.users.res.total.all,
      'Nutzer < 50 FZS': '-----',
      'Flottennutzer > 50 FZS': '-----',
      Fahrzeuge: analytics.vehicles.res.total.all,
    },
    Fahrzeuge: {
      M1: analytics.vehicles.res.total.M1,
      N1: analytics.vehicles.res.total.N1,
      M3: analytics.vehicles.res.total.M3,
      Andere: analytics.vehicles.res.total.other,
    },
    'THG-Quoten': {
      Angefordert: analytics.quotas.res.total.total.requested.count,
      Eingereicht: analytics.quotas.res.total.total.submitted.count,
      Genehmigt: analytics.quotas.res.total.total.approved.count,
      Abgelehnt: analytics.quotas.res.total.total.declined.count,
      Ausgezahlt: analytics.quotas.res.total.total.payout.count,
    },
    'THG-Bilanz': {
      'tCO2 - Zertifiziert': analytics.quotas.res.total.total.total.tCO2,
      'MWh - Zertifiziert': analytics.quotas.res.total.total.total.MWh,
      'Ausgezahlt [€]': '-----',
    },
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth={false}>
      <Grid
        container
        display='flex'
        justifyContent='space-evenly'
        // alignItems='center'
      >
        {Object.keys(config).map(title => (
          <Grid item>
            <Typography
              variant='h5'
              align='center'
              sx={{ textDecoration: 'underline' }}
            >
              {title}
            </Typography>
            {Object.keys(config[title]).map(item => (
              <SummaryItem name={item} value={config[title][item]} />
            ))}
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Summary
