import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
// date-fns
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material'
import { FC, MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { ContextScopeEnum, GlobalContext } from '../../context/GlobalState';
import { saveTemplate } from '../../templates-admin/api/saveTemplate';
import { saveUser } from '../../users-admin/api/saveUser';
import { saveVehicle } from '../../vehicles-admin/api/saveVehicle';
import EmailSearch from '../cards/email-search/EmailSearch';
import VehicleOverview from '../cards/vehicle-overview/VehicleOverview';
import BusinessChangeDialog from '../dialog/BusinessChangeDialog/BusinessChangeDialog';
import { TabPanel } from '../dialog/TabPanel'
import VehicleImages from '../VehicleImages';
import { FormHelper } from './formHelper';
import { FormConfigurationTypeEnum, IActiveTab, IButtonState, IFieldOption, IFormConfig, IFormField } from './interfaces';
import { saveInvoiceTemplate } from '../../invoice-templates-admin/api/saveInvoiceTemplate';
import DeleteBankData from '../cards/deleteBankData/DeleteBankData';
import { calculateVinCheckDigit } from '../../../../utils/vin-utils';
import { isValidVin } from './validators';
import { InvalidVinConfirmation } from './invalid-vin-confirmation';
import { FieldValidationResult } from './validationHelper';

/**
 * Defines the properties required by the FormGenerator.
 */
export interface IFormGeneratorProps {
  type: FormConfigurationTypeEnum;
  currentObject: any;
  activeTab: IActiveTab;
  setActiveTab: Function;
  shouldSave: boolean;
  setShouldSave: Function;
  isValid: boolean;
  setIsValid: Function;
  setFields: Function;
  fields: IFormField[];
  buttonStates?: IButtonState[];
  setButtonStates?: Function;
  inputReference?: any;
  setCallback?: Function;
  nextSelectedId?: number | null;
}

/**
 * Generates a form dynamically using the provided
 * properties.
 * 
 * @param props IFormGeneratorProps
 */
export default function FormGenerator(props: IFormGeneratorProps) {
  // Helps transform form elements according to needs.
  let formHelper: FormHelper = new FormHelper(props.type);
  // Holds the list of fields.
  // const [ fields, setFields ] = useState<IFormField[]>([]);
  // Holds the configuration data for the current form.
  const [formConfig, setFormConfig] = useState<IFormConfig>(formHelper.getFormConfiguration(props.type));
  // Indicate if the business change model should be shown or not.
  const [showChangeBusinessType, setShowChangeBusinessType] = useState<any>(false);
  // Handles the dialog for confirming and invalid VIN value as saving
  const [isInvalidVinConfirmDialogOpen, setInvalidVinConfirmDialogOpen] = useState(false);
  /**
   * Get the required input style for the provided field.
   * 
   * @param field IFormField
   * @returns any
   */
  const inputStyle = (field: IFormField) => ({
    mt: 2,
    mr: 2,
    ml: field.type === 'boolean' ? 2 : 0,
    mb: 1,
    width: `${25 * (field.columns || 1)}ch`,
    visibility: field.hidden ? 'hidden' : 'visible',
    color: field.color
  });
  /**
   * Indicates if the value of a field has changed
   */
  // Get some properties from the global state.
  const {
    setSelectedIds,
    setModal,
    refreshList,
    setRefreshList,
    currentScope,
    updateVehicleInList
  } = useContext(GlobalContext);

  /**
   * Pass parentFields as ref, so it gets the lastest value when it's called 
   */
  const parentFieldsRef = useRef<IFormField[]>(props.fields)
  parentFieldsRef.current = props.fields
  // Indicate if the vehicle image dropzone should be shown or not. (check for licensePlate is for showing edit vr modal in user table)
  const showVehicleImageDropzone = props?.fields?.length && (currentScope === ContextScopeEnum.VEHICLES || props?.fields?.some(f => f?.key === 'licensePlate'))

  /**
   * Load the current vehicle information into a
   * separate state so we can easily make changes.
   */
  useEffect(() => {
    /**
     * Check if there are tabs that should be
     * hidden in the current mode (edit or create).
     */
    props.setActiveTab({ current: 1, total: formConfig?.tabs.length });
    /**
     * Check if we are in edit mode or
     * create mode. For create mode we
     * need to use an already prepared
     * empty object.
     */
    const defaultValues = formHelper.getEmptyObject();
    let curObject = applyDefaultValues(props.currentObject || {}, defaultValues);
    // Convert the current vehicle to a list of fields.
    const mode = !!props.currentObject ? 'edit' : 'create';
    /* Converting the content object to a string. */
    if (props.type === FormConfigurationTypeEnum.INVOICE_TEMPLATE) {
      curObject = { ...curObject, content: JSON.stringify(curObject.content) }
    }
    /**
     * Get the list of fields.
     */
    const allFields = formHelper.getFieldList(curObject, formConfig as IFormConfig, mode)
      /**
       * Apply sorting so the order of the fields
       * doesn't change while editing the fields.
       */
      .sort((fieldA: IFormField, fieldB: IFormField) => fieldA.id - fieldB.id);

    /**
     * Check if the list of fields should be saved by the
     * parent component or not.
     */
    props.setFields(allFields);
    /**
     * Set the fields of the provided fields array from
     * the parent component if it's the case. 
     * Note: this feature is used when we want the parent
     * component to be able to control the 
     */
    if (props.setCallback) {
      const insertPlaceholderIntoField = () =>
        (startPos: number, endPos: number, placeholder: string, parentFieldsCollection: IFormField[]) => {
          let allFields = parentFieldsCollection;
          // Get the TextPart field.
          const textPartField = allFields.find(f => f.key === 'TextPart');
          // Get the new field value to set.
          if (textPartField) {
            const newValue = (textPartField.value as string).substring(0, startPos) +
              `{{${placeholder}}}` +
              (textPartField.value as string).substring(endPos, (textPartField.value as string).length);
            // Set the value on the current field.
            handleFieldChange(textPartField, { target: { value: newValue } }, allFields);
          }
        }
      props.setCallback(insertPlaceholderIntoField);
    }
    /**
     * Update the provided button states when
     * generating the list of fields.
     */
    if (!!props.buttonStates && !!props.buttonStates.length && !!props.setButtonStates) {
      formHelper.updateButtonStates(props.buttonStates, allFields, props.setButtonStates);
    }
  }, [props.currentObject]);

  const moveToNextSelection = () => {
    if (props.nextSelectedId) {
      setSelectedIds([props.nextSelectedId])
      toast.success('moving to next form', { duration: 3000 })
      // props.nextSelectedId && setModal({ type: ContextActionEnum.ADD_VEHICLE })
    } else {
      // new behavior save & keep modal open
      // reload is performed through useEffect
      toast.success('reloading form', { duration: 3000 })
    }
  };

  /**
   * ************ SAVE THE FORM ***************
   *
   * Monitor if the parent component launched a
   * save request.
   */
  useEffect(() => {
    /**
     * TODO: Check if there are validation errors and
     * if not then check all fields for validation
     * errors before performing the submit action.
     */
    if (!props.shouldSave) return;
    const fieldsValidation = formHelper.validateForm(props.fields);
    let isValid = fieldsValidation.length === 0;
    /**
     * Confirm the invalid VIN value before saving if this is the only validation error.
     */
    if (props.shouldSave && fieldsValidation.length === 1 && hasInvalidVin(fieldsValidation)) {
      setInvalidVinConfirmDialogOpen(true);
    }
    /**
     * Set the validation errors on the parent component
     * so the parent knows when the errors are gone.
     */
    props.setIsValid(isValid);
    /**
     * If there are invalid fields detected
     * at this point then abort the save.
     */
    if (!isValid) {
      props.setShouldSave(false);
      return;
    }
    /**
     * If the shouldSave is false or the parent
     * indicates that the form is not valid then
     * abort the save.
     */
    if (!props.shouldSave || !props.isValid) {
      props.setShouldSave(false);
      return;
    }
    // TODO: remove image fields for now.
    const mode = !!props.currentObject ? 'edit' : 'create';
    /**
     * Check which fields should be removed
     * given the current save mode (create | edit).
     */
    let filteredFields = formHelper.filterFieldsByMode(props.fields, mode);
    /**
     * Remove the fields which are marked to not be
     * saved if changes didn't occur.
     */
    filteredFields = formHelper.filterFieldsByChange(filteredFields);
        /**
     * CREATE mode: empty fields will be removed.
     * EDIT mode: empty field values will be set to NULL.
     */
    // Also, remove 'remoteEmail' field when you create a new vehicle
    filteredFields = formHelper
      .removeEmptyFields(filteredFields, mode)
      .filter(field => props.type !== FormConfigurationTypeEnum.VEHICLE || field.key !== 'remoteEmail');
        // Convert the list of fields to a vehicle object.
    const saveObject = filteredFields.reduce((prev: any, currField: IFormField) => {
      return ({ ...prev, [currField.key]: currField.value });
    }, {});
    // Call the PUT / POST endpoint based on the type of object.
    switch (props.type) {
      case FormConfigurationTypeEnum.USER:
        saveUser(saveObject)
          .then(result => {
            if (result) {
              toast.success('Benutzerdaten wurden aktualisiert', { duration: 3000 });
              // Refresh the current undelying list.
              setRefreshList(Math.floor(Math.random() * 10000));
              // Move to next selection, if you have one
              moveToNextSelection();
            }
          });
        break;
      case FormConfigurationTypeEnum.VEHICLE:
        saveVehicle(saveObject)
          .then(result => {
            if (result) {
              updateVehicleInList(result);
              toast.success('Fahrzeugdaten wurden aktualisiert', { duration: 3000 });
              // Move to next selection, if you have one
              moveToNextSelection();
            }
          });
        break;
      case FormConfigurationTypeEnum.TEMPLATE:
        saveTemplate(saveObject)
          .then(result => {
            if (result) {
              toast.success('Vorlagenänderungen wurden aktualisiert', { duration: 3000 });
              // Refresh the current undelying list.
              setRefreshList(Math.floor(Math.random() * 10000));
              // Move to next selection, if you have one
              moveToNextSelection();
            }
          });
        break
      case FormConfigurationTypeEnum.INVOICE_TEMPLATE:
        saveInvoiceTemplate(saveObject)
          .then(result => {
            if (result) {
              toast.success('Rechnungsvorlagenänderungen wurden aktualisiert', { duration: 3000 });
              // Refresh the current undelying list.
              setRefreshList(Math.floor(Math.random() * 10000));
              // Move to next selection, if you have one
              moveToNextSelection();
            }
          });
        break;
      default:
        break;
    }
    // Set the shouldSave back to false.
    props.setShouldSave(false);
  }, [props.shouldSave])

  /**
   * Handle the event of changing tabs.
   *
   * @param event any
   * @param newValue number
   * @returns void
   */
  const handleActiveTab = (event: any, newValue: number) => {
    props.setActiveTab({ current: newValue, total: props.activeTab.total });
  }

  /**
   * Apply the changes to the current field and
   * replace that field inside the list of fields.
   *
   * @param currentField IFormField
   * @param event any
   * @returns void
   */
  const handleFieldChange = (currentField: IFormField, event: any, parentFieldsCollection: IFormField[]): void => {
    let newFieldsList: IFormField[] = parentFieldsCollection;
    /**
     * Get the updated list of fields so we
     * can set them again locally.
     */
    newFieldsList = formHelper.applyChange(newFieldsList, currentField, event, props.setIsValid);
    // Apply sorting so we keep order of fields.
    newFieldsList
      .sort((fieldA: IFormField, fieldB: IFormField) => fieldA.id - fieldB.id);
    /**
     * Update the provided button states when
     * a field value has changed.
     */
    if (!!props.buttonStates && !!props.buttonStates.length && !!props.setButtonStates) {
      formHelper.updateButtonStates(props.buttonStates, newFieldsList, props.setButtonStates);
    }
    // Replace the existing list of fields with the new one.
    props.setFields([...newFieldsList]);
  }

  /**
   * If the user is editing an existing object, then show a modal to confirm the change. Otherwise,
   * just update the field
   * @param event - React.ChangeEvent<HTMLInputElement>
   * @param {IFormField} field - IFormField
   * @returns return isModeEdit
   *     ? setShowChangeBusinessType(event)
   *     : handleFieldChange(field, event, parentFieldsRef.current)
   */
  const handleB2bUpdate = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: IFormField
  ) => {
    const isModeEdit = !!props.currentObject

    return isModeEdit
      ? setShowChangeBusinessType(event)
      : handleFieldChange(field, event, parentFieldsRef.current)
  }

  return (
    <Box component="form">

      {/* If we have a currentObject then we show the image slides. */}
      {showVehicleImageDropzone &&
        <VehicleImages
          vehicleId={props.fields.find(f => f.key === 'id')?.value as number}
          frontImageField={props.fields.find(f => f.key === 'frontImage') as IFormField}
          frontImageFieldType={props.currentObject?.frontImageFieldType}
          backImageField={props.fields.find(f => f.key === 'backImage') as IFormField}
          backImageFieldType={props.currentObject?.backImageFieldType}
          onChange={(currentField: IFormField, event: any) => handleFieldChange(currentField, event, parentFieldsRef.current)}
          isEdit={!!props.currentObject} />
      }

      {/* Tab titles */}
      <Tabs value={props.activeTab.current} onChange={handleActiveTab} centered>
        {formConfig?.tabs.map(tab =>
          <Tab key={tab.index} label={tab.name} value={tab.index} />
        )}
      </Tabs>

      <Divider />

      {/* Tab panels */}
      {formConfig?.tabs.map(tab =>
        <TabPanel key={tab.index} value={props.activeTab.current} index={tab.index}>
          {
            tab.groups.map(group =>

              <Box key={group.index} component="span" sx={{
                ml: 4,
                display: 'flex',
                flexWrap: 'wrap',
                px: 1,
                mx: 1,
                bgcolor: 'background.paper',
                width: 800,
                borderRadius: 1,
              }}>

                {props.fields.filter(f => group.fields.includes(f.key)).map((field: IFormField, fieldIndex: number) =>
                  <>
                    {/* Displays the remote-email component. */}
                    {['remote-email'].includes(field.type) &&
                      <EmailSearch
                        key={field.id}
                        emailField={field}
                        ownerUsernameField={props.fields.find(f => f.key === 'ownerUsername') as IFormField}
                        onChange={(field: IFormField, event: any) => handleFieldChange(field, event, parentFieldsRef.current)} />
                    }
                    {['delete-bank-data'].includes(field.type) &&
                      <DeleteBankData
                        key={field.id}
                        accountOwnerField={props.fields.find(f => f.key === 'accountOwner') as IFormField}
                        ibanField={props.fields.find(f => f.key === 'iban') as IFormField}
                        onChange={(field: IFormField, event: any) => handleFieldChange(field, event, parentFieldsRef.current)} />
                    }
                    {['business-changer'].includes(field.type) &&
                      <FormControlLabel
                        sx={() => inputStyle(field)}
                        key={field.id}
                        control={<Checkbox
                          checked={!!field.value}
                          color={field.validationErrorText ? 'error' : 'default'}
                          onChange={(event) => handleB2bUpdate(event, field)} />}
                        label={field.name}
                      />
                    }
                    {/* Displays the vehicle overview component. */}
                    {['overview'].includes(field.type) &&
                      <VehicleOverview key={field.id} vehicle={props.currentObject} />
                    }
                    {['string', 'number'].includes(field.type) &&
                      <TextField
                        key={field.id}
                        type={field.type}
                        error={!!field.validationErrorText}
                        helperText={field.validationErrorText || field.message}
                        disabled={field.disabled}
                        id={field.key}
                        label={field.label || field.name}
                        value={field.value}
                        onChange={(event: any) => handleFieldChange(field, event, parentFieldsRef.current)}
                        variant="standard"
                        color={inputStyle(field).color}
                        sx={() => inputStyle(field)}
                      />
                    }
                    {['textarea'].includes(field.type) &&
                      <TextField
                        key={field.id}
                        type={field.type}
                        multiline={true}
                        inputRef={props.inputReference && field.key === 'TextPart' ? props.inputReference : null}
                        error={!!field.validationErrorText}
                        helperText={field.validationErrorText}
                        disabled={field.disabled}
                        id={field.key}
                        label={field.name}
                        value={field.value}
                        onChange={(event: any) => handleFieldChange(field, event, parentFieldsRef.current)}
                        sx={() => inputStyle(field)}
                      />
                    }
                    {['select'].includes(field.type) && field.options && field.options.length > 0 &&
                      <FormControl key={field.id} variant="standard" sx={() => inputStyle(field)}>
                        <InputLabel>{field.name}</InputLabel>
                        <Select
                          value={field.value || 0}
                          error={!!field.validationErrorText}
                          onChange={(event: any) => handleFieldChange(field, event, parentFieldsRef.current)}
                          label={field.key}
                        >
                          {field?.options.map((option: IFieldOption) =>
                            <MenuItem key={option.name} value={option.value}>{option.name}</MenuItem>
                          )}
                        </Select>
                        {field.validationErrorText && <FormHelperText>{field.validationErrorText}</FormHelperText>}
                      </FormControl>
                    }
                    {['date'].includes(field.type) &&
                      <FormControl key={field.id} variant="standard" sx={() => inputStyle(field)}>
                        <LocalizationProvider
                          key={field.id}
                          dateAdapter={AdapterDateFns}
                        >
                          <DesktopDatePicker
                            label={field.name}
                            inputFormat="dd/MM/yyyy"
                            value={field.value || null}
                            onChange={(event: any) => handleFieldChange(field, event, parentFieldsRef.current)}
                            renderInput={(params: any) => <TextField {...params} variant="standard" />}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    }
                    {['boolean'].includes(field.type) &&
                      <FormControlLabel
                        sx={() => inputStyle(field)}
                        key={field.id}
                        labelPlacement='start'
                        control={
                          <Checkbox
                            checked={!!field.value}
                            color={field.validationErrorText ? 'error' : 'default'}
                            onChange={(event) => handleFieldChange(field, event, parentFieldsRef.current)}
                          />
                        }
                        label={field.name} />
                    }
                    {['vin'].includes(field.type) &&
                      <VinTextField
                        key={field.id}
                        field={field}
                        showInvalidVinConfirmDialog={isInvalidVinConfirmDialogOpen}
                        parentFieldsRef={parentFieldsRef}
                        handleFieldChange={handleFieldChange}
                        onInvalidVinConfirmDialogClose={invalidVinConfirmed => {
                          if (invalidVinConfirmed) {
                            props.setShouldSave(true);
                          }
                          setInvalidVinConfirmDialogOpen(false);
                        }}
                      />
                    }
                  </>
                )}
              </Box>
            )}
        </TabPanel>
      )}

      {showChangeBusinessType &&
        <BusinessChangeDialog
          title="Geschäftsmodell ändern"
          content="Bitte stellen Sie sicher, dass dies die Änderungen sind, die Sie vornehmen möchten"
          username={props?.currentObject?.username}
          changeTo={showChangeBusinessType?.target?.checked}
          cancelCallback={() => setShowChangeBusinessType(false)}
        />
      }
    </Box>
  )
}

interface VinTextFieldProps {
  field: IFormField;
  disableValidation?: boolean;
  showInvalidVinConfirmDialog?: boolean;
  parentFieldsRef: MutableRefObject<IFormField[]>;
  handleFieldChange: (currentField: IFormField, event: any, parentFieldsCollection: IFormField[]) => void;
  onInvalidVinConfirmDialogClose?: (invalidVinConfirmed: boolean) => any;
}

const VinTextField: FC<VinTextFieldProps> = ({
  field,
  disableValidation,
  showInvalidVinConfirmDialog,
  parentFieldsRef,
  handleFieldChange,
  onInvalidVinConfirmDialogClose
}) => {
  const [checkDigit, setCheckDigit] = useState(
    field.validationErrorText ? '' : calculateVinCheckDigit(field.value as string | undefined)
  );

  const valueChanged = useRef(false);

  useEffect(() => {
    replaceDynamicVinValidator(field, (value?: string) => isValidVin(value, checkDigit));

    if (valueChanged.current) {
      handleFieldChange(field, field.value, parentFieldsRef.current);
    }

    return () => removeDynamicVinValidator(field);
  }, [checkDigit, disableValidation]);

  const handleInvalidVinConfirmDialogClose = (invalidVinConfirmed: boolean) => {
    if (invalidVinConfirmed) {
      replaceDynamicVinValidator(field, () => '');
      handleFieldChange(field, field.value, parentFieldsRef.current);
    }
    onInvalidVinConfirmDialogClose?.(invalidVinConfirmed);
  };

  return (
    <Box
      sx={() => ({
        ...inputStyle(field),
        display: 'flex',
        gap: 1
      })}
    >
      <TextField
        key={field.id}
        type="text"
        error={!!field.validationErrorText}
        helperText={field.validationErrorText}
        disabled={field.disabled}
        id={field.key}
        label={field.name}
        value={field.value}
        variant="standard"
        color={inputStyle(field).color}
        onChange={event => {
          valueChanged.current = true;
          handleFieldChange(field, event.target.value.toUpperCase(), parentFieldsRef.current);
        }}
      />
      <TextField
        key={`${field.id}_check_digit`}
        type="text"
        error={!!field.validationErrorText}
        disabled={field.disabled}
        id={`${field.key}_check_digit`}
        label="Prüf"
        value={checkDigit}
        variant="standard"
        color={inputStyle(field).color}
        inputProps={{ maxLength: 1 }}
        sx={{ maxWidth: '30px' }}
        onChange={event => {
          valueChanged.current = true;
          setCheckDigit(event.target.value.toUpperCase());
        }}
      />
      <InvalidVinConfirmation
        open={showInvalidVinConfirmDialog}
        onClose={handleInvalidVinConfirmDialogClose}
      />
    </Box>
  );
};

const addDynamicVinValidator = (field: IFormField, validator: (value?: string) => string) => {
  (validator as any).dynamicVinValidator = true;
  if (field.validators) {
    field.validators.push(validator);
  } else {
    field.validators = [validator];
  }
};

const removeDynamicVinValidator = (field: IFormField) => {
  if (field.validators) {
    field.validators = field.validators.filter(validator => !(validator as any).dynamicVinValidator);
  }
};

const replaceDynamicVinValidator = (field: IFormField, validator: (value?: string) => string) => {
  removeDynamicVinValidator(field);
  addDynamicVinValidator(field, validator);
};

const hasInvalidVin = (fieldsValidation: FieldValidationResult[]) => {
  return !!fieldsValidation.find(validation => validation.fieldName === 'carId');
};

/**
 * Get the required input style for the provided field.
 * 
 * @param field IFormField
 */
const inputStyle = (field: IFormField) => ({
  mt: 2,
  mr: 2,
  ml: field.type === 'boolean' ? 2 : 0,
  mb: 1,
  width: `${25 * (field.columns || 1)}ch`,
  visibility: field.hidden ? 'hidden' : 'visible',
  color: field.color
});

const applyDefaultValues = (
  currentValues: Record<string, any>,
  defaultValues: Record<string, any> | null,
) => {
  const finalObject = {
    ...currentValues
  };

  if (defaultValues) {
    Object.entries(defaultValues).forEach(([name, defaultValue]) => {
      const currentValue = currentValues[name];
  
      if (currentValue === null || typeof currentValue === 'undefined') {
        finalObject[name] = defaultValue;
      }
    });
  }

  return finalObject;
};