import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'

/**
 * It sends invoices to customers
 * @param  - `limit` is the number of invoices to send
 * @param  - `skip` is the number of invoices to skip
 */
export const sendInvoices = ({
  limit,
  skip,
}: {
  limit: number
  skip: number
}) => {
  const url = `${API_URL}/quota-sales/sendInvoice?take=${limit}&skip=${skip}`
  return fetcher(url, {
    method: 'POST',
  })
}
