import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'

/**
 * It updates the status of a vehicle registration
 * @param {number} vehicleId - The id of the vehicle you want to update
 * @param action - 'reupload'
 * @returns The response from the API call.
 */
export const updateVehicleStatus = async (vehicleId: number, action: 'reupload') => {
  const url = `${API_URL}/vehicle-registrations/${vehicleId}/${action}`
  return await fetcher(`${url}`, {
    method: 'PUT',
  })
}
