import { Theme } from "@mui/material";

/**
 * Get the style for the provided name.
 * 
 * @param theme Theme
 * @param styleName string
 * @returns any
 */
export const getStyle = (theme: Theme, tenant: string, styleName: string) => {
  const styles: any = {
    carbonify: {
      parentBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingY: theme.spacing(10),
      },
      heading: {
        fontSize: '30px',
        lineHeight: '40px',
        letterSpacing: '-1px',
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
        textAlign: 'left',
      },
      formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        maxWidth: '355px',
        width: '100%',
        gap: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
          paddingX: theme.spacing(2),
        },
      }
    },
    adac: {
      parentBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 500,
        mt: 4
      },
      heading: {
        fontSize: '30px',
        lineHeight: '40px',
        letterSpacing: '-1px',
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
        textAlign: 'left',
      },
      formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        maxWidth: '355px',
        width: '100%',
        gap: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
          paddingX: theme.spacing(2),
        },
      }
    },
    allianz: {
      parentBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 500,
        mt: 4
      },
      heading: {
        fontSize: '30px',
        lineHeight: '40px',
        letterSpacing: '-1px',
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
        textAlign: 'left',
      },
      formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        maxWidth: '355px',
        width: '100%',
        gap: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
          paddingX: theme.spacing(2),
        },
      }
    }
  }
  const foundStyle = styles[tenant][styleName];
  return foundStyle || null;
}