import toast from "react-hot-toast";
import { API_URL } from "../../../../constants/api";
import { fetcher } from "../../../../utils/fetcher";

/**
 * Calls the required endpoint to download the
 * backend generated XML file for the B2B sample
 * invoices.
 */
export const downloadFile = async (type: string, link: string, fileName: string, fileDownloadLink: any, v2?: boolean) => {
  // Empty the existing CSV data.
  toast(`Downloading ${type} file. Please wait...`);

  await fetcher(`${API_URL}${link}`)
    .then((data: any) => {
      const a = fileDownloadLink.current;
      a.download = fileName;
      a.href = v2 ? data.link : data.signedUrl;
      a.target = '_blank';
      a.click();
      a.href = '';
    });
}