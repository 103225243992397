import { canUseFeature } from '../../../../hooks/canUseFeature';
import { IFormConfig } from '../form-generator/interfaces';

const shouldHaveMembershipOptions = canUseFeature('MembershipOptionsInEditUser');

export const userFormConfig: IFormConfig = {
  /**
   * Here you can configure the layout of
   * the current form into tabs.
   */
  tabs: [
    {
      index: 1,
      name: 'Persönliche Daten',
      groups: [
        {
          index: 1,
          fields: ['firstName', 'lastName', 'email'],
        },
        {
          index: 2,
          fields: ['company', 'isCompany'],
        },
        {
          index: 3,
          fields: ['street', 'houseNumber', 'zip', 'city'],
        },
      ],
    },
    {
      index: 2,
      name: 'Finanzinformationen',
      groups: [
        // First row.
        {
          index: 1,
          fields: ['accountOwner', 'iban', 'delete-bank-data'],
        },
        // Second row.
        {
          index: 2,
          fields: [
            'vatReclaimEntitled',
            'taxNumber',
            'vatId',
            // 'accountType',
            'membershipCode',
            'discountOption',
          ],
        },
      ],
    },
    {
      index: 3,
      name: 'Andere',
      groups: [
        // Third row.
        {
          index: 1,
          fields: ['acceptedTAndC', 'acceptedTAndC2023', 'regFlowComplete'],
        },
        {
          index: 2,
          fields: ['priceUpdateOptIn', 'remarketing2023'],
        },
        {
          index: 3,
          fields: ['isNoRightOfRevocation', 'marketingOptIn', 'raffleCheckbox'],
        },
        {
          index: 4,
          fields: ['maxVehicleCount'],
        },
      ],
    },
  ],
  /**
   * Here you can configure the fields individually
   * so the field types and options can be handled
   * correctly by the form.
   */
  fieldsConfig: [
    { id: 1, fieldName: 'accountType', type: 'string' },
    { id: 2, fieldName: 'taxNumber', type: 'string' },
    { id: 3, fieldName: 'vatId', type: 'string' },
    { id: 4, fieldName: 'acceptedTAndC', type: 'boolean' },
    { id: 5, fieldName: 'accountOwner', type: 'string', disabled: true },
    { id: 6, fieldName: 'city', type: 'string' },
    { id: 7, fieldName: 'company', type: 'string' },
    { id: 8, fieldName: 'createdAt', type: 'date' },
    { id: 9, fieldName: 'debtorNumber', type: 'string' },
    { id: 10, fieldName: 'email', type: 'string', required: true },
    { id: 11, fieldName: 'firstName', type: 'string', required: true },
    { id: 12, fieldName: 'houseNumber', type: 'string' },
    { id: 13, fieldName: 'iban', type: 'string', disabled: true },
    { id: 14, fieldName: 'id', type: 'number' },
    // { id: 15, fieldName: 'isCompany', type: 'boolean' },
    { id: 15, fieldName: 'isCompany', type: 'business-changer' },
    { id: 16, fieldName: 'lastName', type: 'string', required: true },
    { id: 17, fieldName: 'marketingOptIn', type: 'boolean' },
    { id: 18, fieldName: 'priceUpdateOptIn', type: 'boolean' },
    { id: 19, fieldName: 'regFlowComplete', type: 'boolean' },
    { id: 20, fieldName: 'street', type: 'string' },
    { id: 21, fieldName: 'uniqueId', type: 'string' },
    { id: 22, fieldName: 'vatReclaimEntitled', type: 'boolean' },
    { id: 23, fieldName: 'zip', type: 'string' },
    { id: 24, fieldName: 'membershipCode', type: 'string', hidden: !shouldHaveMembershipOptions },
    {
      id: 25,
      fieldName: 'discountOption',
      type: 'select',
      hidden: !shouldHaveMembershipOptions,
      options: [
        { name: 'No Option', value: null },
        { name: 'Gutschrift', value: 0 },
        { name: 'Auszahlung', value: 1 },
      ],
    },
    { id: 26, fieldName: 'username', type: 'string' },
    { id: 27, fieldName: 'acceptedTAndC2023', type: 'boolean' },
    { id: 28, fieldName: 'isNoRightOfRevocation', type: 'boolean' },
    { id: 29, fieldName: 'raffleCheckbox', type: 'boolean' },
    { id: 30, fieldName: 'remarketing2023', type: 'boolean' },
    { id: 31, fieldName: 'delete-bank-data', type: 'delete-bank-data', saveOnlyIfChanged: true },
    {
      id: 32,
      fieldName: 'maxVehicleCount',
      label: 'max. Anzahl an Fahrzeugen',
      type: 'number',
      message: 'Wenn kein Wert festgelegt wird, wird der Standardwert des Portals verwendet.',
    },
  ],
};
