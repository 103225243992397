import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It makes a GET request to the `/quota-sales/archive/csv` endpoint and returns the response
 * @returns A promise that resolves to the data returned from the API.
 */
export const getUserArchivePreviewCSV = (): Promise<any> => {
  // Build up the complete url
  const url = `${API_URL}/quota-sales/archive/csv`;

  return fetcher(url, {
    method: 'GET',
  });
};
