import { Button, Typography } from '@mui/material'
import { useState } from 'react'
import {getCognitoUser} from '../../../utils/api/getCognitoUser'

/* A React component that takes an username as a prop and returns a button that when clicked will fetch
the user with that email and display the email. */
const GetEmailButton = ({username}: {username: string}) => {
  const [user, setUser] = useState(null)
  const [error, setError] = useState(false)

  /**
   * If the getCognitoUser function returns a user, set the user state to that user, otherwise set the
   * error state to true.
   */
  const getUser = async () => {
    try {
      const user = await getCognitoUser({username})
      setUser(user)
    } catch {
      setError(true)
    }
  }

  if (error) return <Button disabled>Failed</Button>
  if (user) return <Typography>{user?.email}</Typography>

  return <Button variant='outlined' onClick={async () => await getUser()}>Load Email</Button>
}

export default GetEmailButton
