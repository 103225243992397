import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ContextActionEnum, GlobalContext, ITriggerAction } from '../../../context/GlobalState';
import { fetchLatestQuotaIds } from '../../api/fetchLatestQuotaIds';
import { approveAllQuotaIds } from '../../api/approveQuotaIds';
import { DialogProgressCard } from '../../../vehicles-admin/dialogs/utils/DialogProgressCard';
import ResultsTable, { IDenseTableRow } from '../../../common/dialog/ResultsTable/ResultsTable';
import { BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import { IWizardStep } from '../../../common/wizard-steps';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export interface IApproveQuotaSalesDialogProps {
  selectedIds: number[];
  setModal: Function;
  total: number;
}

interface IApproveTriggerAction {
  type: ContextActionEnum;
  progress: number;
  // quotaSaleIds: number[];
}

/**
 * Defines the wizard states that
 * the user needs to follow.
 */
export const ApproveQuotasStateSteps: IWizardStep[] = [
  { id: 0, name: 'Fetch Quota IDs' },
  { id: 1, name: 'Approve Quotas' },
  { id: 2, name: 'Finished' }
];

export default function ApproveQuotaSalesDialog(props: IApproveQuotaSalesDialogProps) {
  // Get some required properties from the global state.
  const { modal, } = useContext(GlobalContext);
  // Defines the current state of the wizard.
  const [currentState, setCurrentState] = useState<IWizardStep>(ApproveQuotasStateSteps[0]);
  // Define the local actions so we know when to show the ProgressCard.
  const [trigger, setTrigger] = useState<IApproveTriggerAction>(null);
  // Holds all the quota sale ids that we obtained.
  const [quotaSaleIds, setQuotaSaleIds] = useState<IDenseTableRow[]>([]);

  /**
   * Create the table rows that we will
   * use to display the status of the
   * following actions.
   */
  useEffect(() => {
    const all: IDenseTableRow[] = props.selectedIds.map(
      (id: number, index: number) => ({
        order: index + 1,
        quotaId: id,
        fetchStatus: 1,
        approveStatus: 1,
      })
    )
    setQuotaSaleIds(all);
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.setModal(null)}>
          {modal?.type.toString()}
        </BootstrapDialogTitle>
        <DialogContent dividers>

          <Stepper activeStep={currentState.id} alternativeLabel sx={{ mb: 3 }}>
            {ApproveQuotasStateSteps.map((state) => (
              <Step key={state.id}>
                <StepLabel>{state.name}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {quotaSaleIds && quotaSaleIds.length !== 0 &&
            <Box sx={{ mt: 4, width: 550, flexGrow: 1, height: '30vh' }}>
              <ResultsTable rows={quotaSaleIds} />
            </Box>
          }

          {trigger && 
            <Box sx={{ mt: 3 }}>
              <DialogProgressCard trigger={trigger} />
            </Box>
          }

        </DialogContent>
        <DialogActions>
          {currentState.id === 0 &&
            <Button onClick={() => fetchLatestQuotaIds(quotaSaleIds, setTrigger, setCurrentState, ApproveQuotasStateSteps[1])}>
              Next - Fetch Quota IDs
            </Button>
          }
          {currentState.id === 1 &&
            <Button onClick={() => approveAllQuotaIds(quotaSaleIds, setTrigger, setCurrentState, ApproveQuotasStateSteps[2])}>
              Next - Approve them
            </Button>
          }
          {currentState.id === 2 &&
            <Button onClick={() => props.setModal(null)}>
              Done
            </Button>
          }
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
