import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';

const errorStatuses = [
  { code: 1, description: 'Not yet' },
  { code: 200, description: 'Success' },
  { code: 201, description: 'Created' },
  { code: 202, description: 'Accepted' },
  { code: 204, description: 'Success' },
  { code: 401, description: 'Unauthenticated' },
  { code: 403, description: 'Forbidden' },
  { code: 404, description: 'Not found' },
  { code: 409, description: 'Conflict' },
  { code: 500, description: 'Error' },
];

export interface IDenseTableRow {
  order: number
  vehicleId?: number
  userId?: number
  quotaId?: number
  status?: number
  fetchStatus?: number
  approveStatus?: number
  declineStatus?: number
  sellStatus?: number
  payStatus?: number
}

export default function ResultsTable(props: {rows: IDenseTableRow[] }) {
  return (
    <TableContainer sx={{ maxHeight: '30vh', overflowY: 'auto' }} component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          <TableCell>#</TableCell>
            {props.rows[0]?.vehicleId && <TableCell align="right">VehicleID</TableCell>}
            {props.rows[0]?.quotaId && <TableCell align="right">QuotaID</TableCell>}
            {props.rows[0]?.userId && <TableCell align="right">UserID</TableCell>}
            {props.rows[0]?.status && <TableCell align="right">Status</TableCell>}
            {props.rows[0]?.fetchStatus && <TableCell align="right">FetchQuota</TableCell>}
            {props.rows[0]?.approveStatus && <TableCell align="right">Approved</TableCell>}
            {props.rows[0]?.declineStatus && <TableCell align="right">Declined</TableCell>}
            {props.rows[0]?.sellStatus && <TableCell align="right">Sold</TableCell>}
            {props.rows[0]?.payStatus && <TableCell align="right">Paid</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow key={row?.order}>
              <TableCell align="left">{row.order}</TableCell>
              {row?.vehicleId && <TableCell align="right">{row.vehicleId}</TableCell>}
              {row?.quotaId && <TableCell align="right">{row.quotaId}</TableCell>}
              {row?.userId && <TableCell align="right">{row.userId}</TableCell>}
              {row?.status && <TableCell align="right">{errorStatuses.find(e => e.code === row.status)?.description}</TableCell>}
              {row?.fetchStatus && <TableCell align="right">{errorStatuses.find(e => e.code === row.fetchStatus)?.description}</TableCell>}
              {row?.approveStatus && <TableCell align="right">{errorStatuses.find(e => e.code === row.approveStatus)?.description}</TableCell>}
              {row?.declineStatus && <TableCell align="right">{errorStatuses.find(e => e.code === row.declineStatus)?.description}</TableCell>}
              {row?.sellStatus && <TableCell align="right">{errorStatuses.find(e => e.code === row.sellStatus)?.description}</TableCell>}
              {row?.payStatus && <TableCell align="right">{errorStatuses.find(e => e.code === row.payStatus)?.description}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}