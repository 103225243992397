import { Box, Button, IconButton } from '@mui/material'
import { Fragment, useEffect, useState } from 'react';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import MediaCard from './cards/MediaCard';
import { IFormField } from './form-generator/interfaces';
import VehicleImageDropzone from './VehicleImageDropzone';
import { PdfPreview } from '../pdf-preview';

/**
 * Defines the required parameters to instantiate
 * the current component.
 */
export interface IVehicleImagesProps {
  vehicleId: number;
  frontImageField: IFormField;
  frontImageFieldType?: string;
  backImageField: IFormField;
  backImageFieldType?: string;
  onChange: (field: IFormField, event: any) => void;
  isEdit: boolean;
}

function VehicleImages(props: IVehicleImagesProps) {
  /**
   * Indicates which slide button should
   * be active.
   */
  const [ showFront, setShowFront ] = useState<boolean>(true);
  const [ frontImage, setFrontImage ] = useState<string>('');
  const [ backImage, setBackImage ] = useState<string>('');

  const hasFrontImagePdf = frontImage && props.frontImageFieldType === 'application/pdf';
  const hasBackImagePdf = backImage && props.backImageFieldType === 'application/pdf';

  const hasFrontImage = frontImage && !hasFrontImagePdf;
  const hasBackImage = backImage && !hasBackImagePdf;

  const switchFrontAndBack =() => {
    setShowFront(!showFront);
  }

  /**
   * Extract the front and back image url
   * from the provided front image field.
   */
  useEffect(() => {
    setShowFront(true)
    // Get the frontImage field.
    const fImage = props.frontImageField && props.frontImageField.value;
    setFrontImage(fImage as string || '');
    // Get the backImage field.
    const bImage = props.backImageField && props.backImageField.value;
    setBackImage(bImage as string || '')
  }, [props.vehicleId]);

  /**
   * Remove the frontImage and backImage values so
   * we can upload these images again and the required
   * component will show.
   */
  const switchToUploadComponents = () => {
    setFrontImage('');
    props.frontImageField.value = '';
    setBackImage('');
    props.backImageField.value = '';
  }
  /**
   * Open the provided link in
   * a new browser tab.
   */
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  /**
   * If one of the image links are empty then
   * show the VehicleDropzone component instead
   * of the MediaCard component.
   */

  return (
    <Box
      sx={{
        width: 860,
        height: 400,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}
    >

      {/* If we need to view the images then we use the MediaCard component. */}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', width: 800, height: 350 }}>
        {/* Switch to the left */}
        <IconButton
          sx={{ mr: 4 }}
          color="primary"
          aria-label="Front image"
          component="span"
          onClick={switchFrontAndBack}
          disabled={showFront}
        >
          <ArrowCircleLeftIcon />
        </IconButton>

        {showFront &&
          <Fragment>
            {hasFrontImagePdf && <PdfPreview file={frontImage} />}
            {hasFrontImage && <MediaCard image={frontImage} />}
            {!frontImage &&
              <VehicleImageDropzone
                field={props.frontImageField}
                name="front"
                text="Vorderseite"
                onChange={props.onChange} />
            }
          </Fragment>
        }

        {!showFront &&
          <Fragment>
            {hasBackImagePdf && <PdfPreview file={backImage} />}
            {hasBackImage && <MediaCard image={backImage} />}
            {!backImage &&
              <VehicleImageDropzone
                field={props.backImageField}
                name="back"
                text="Rückseite"
                onChange={props.onChange} />
            }
          </Fragment>
        }

        {/* Switch to the right */}
        <IconButton
          sx={{ ml: 2 }}
          color="primary"
          aria-label="Back image"
          component="span"
          onClick={switchFrontAndBack}
          disabled={!showFront}
        >
          <ArrowCircleRightIcon />
        </IconButton>
      </Box>

      <Box sx={{ width: '70%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button size="small" variant="text" sx={{ }} onClick={() => switchToUploadComponents()}>Neue Bilder hochladen</Button>
        {showFront && frontImage && <Button onClick={() => openInNewTab(frontImage)}>Open in new tab</Button>}
        {!showFront && backImage && <Button onClick={() => openInNewTab(backImage)}>Open in new tab</Button>}
      </Box>

    </Box>
  );
}

export default VehicleImages