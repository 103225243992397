import { useTenant } from "../hooks/useTenant";
import { adacLabels } from "./labels/adac.labels";
import { allianzLabels } from "./labels/allianz.labels";
import { carbonifyLabels } from "./labels/carbonify.labels";
import { commonLabels } from "./labels/common.labels";

/**
 * Get the current client marker.
 */
enum CLIENT_TYPE { ADAC, CARBONIFY, ALLIANZ };

/**
 * Here you need to specify the list
 * of files that contain tenant labels.
 */
const tenantLabels = [
  { tenant: '', list: commonLabels },
  { tenant: 'carbonify', list: carbonifyLabels },
  { tenant: 'adac', list: adacLabels },
  { tenant: 'allianz', list: allianzLabels },
];

/**
 * Get the list of labels belonging to
 * a specific tenant and also the list
 * of common labels.
 * 
 * @param tenant string - The provided tenant
 * @returns list of labels
 */
const getListOfLabelsByTenant = (tenant: string): any[] => {
  // Get the list of tenant labels.
  const tLabels = tenantLabels.find(tl => tl.tenant === tenant)?.list;
  // Get the list of common labels.
  const cLabels = tenantLabels.find(tl => !tl.tenant)?.list;
  // Combine both lists and return a new list.
  const allLabels = [ ...tLabels, ...cLabels];
  // Form a new list and concatenate PAGE with KEY {PAGE}__{KEY}.
  return allLabels.map(l => ({ key: `${l.page}__${l.key}`, label: l.label }));
}
/**
 * Fetch the required label from the list
 * of available labels stored in the local
 * state.
 * 
 * Nomenclature for the forming of label keys:
 * {PAGE}__{KEY}
 * 
 * Example:
 * PROFILE_AREA__LICENSEPLATE
 * 
 * @param page string
 * @param key string
 * @returns string
 */
export default function getLabel(key: string, page?: string, unused?: string) {
  /**
   * Get the current tenant so we can figure out which
   * label to fetch.
   */
  // const tenant = useTenant();
  const tenant = 'carbonify';
  // Get the list of labels for the current tenant.
  const listOfLabels = getListOfLabelsByTenant(tenant);
  // Combine page and key into a single key {PAGE}__{KEY}.
  const combinedKey = `${page}__${key}`;
  // Get the required label by key.
  const foundLabel = listOfLabels.filter(l => l.key === combinedKey);
  // If there are more labels found then throw an error.
  if (foundLabel.length > 1) {
    throw new Error(`There are more labels with the key ${combinedKey}`);
  }
  if (!foundLabel.length) {
    return '<< No label found >>';
  }
  return foundLabel[0].label;

  /**
   * TODO: pass in params and replace the placeholders
   * with those params.
   * Rules:
   * 1. Placeholders need to have the same name as the
   * provided params.
   * 2. There can be multiple params provided.
   * 3. If a param has not been found then the placeholder
   * should be left in place.
   */
}