import { Alert, Divider, List, ListItem, Typography } from '@mui/material'
import { IDeleteVehicles } from './DeleteVehiclesDialog'

export interface IDeleteVehiclesDialogProps {
    loading: boolean
    error: string
    vehicles: IDeleteVehicles
}

const DeleteVehiclesDialogContent = (props: IDeleteVehiclesDialogProps) => {
    // when loading 
    if (props.loading) {
        return <Alert icon={false} color='info'>Laden... bitte warten</Alert>
    }

    // if we have any errors
    if (props.error) {
        return <Alert color='error'>{props.error}</Alert>
    }
    
    // if everything ok
    return (
        <>
            <Typography component='div' variant='h6' sx={{ textDecoration: 'underline' }}>
                Möchten Sie diese Fahrzeuge wirklich löschen?
            </Typography>
            {props.vehicles?.vehicleRegistrations?.length ? 
                <>
                    <Typography component='div' sx={{ mt: 4 }}>
                        Die folgenden Fahrzeuge werden gelöscht
                    </Typography>
                    <List sx={{color: '#f00'}}>
                        {props.vehicles?.vehicleRegistrations.map(vr => 
                            <ListItem key={vr.id}>{`Fahrzeug mit ID ${vr.id} und Status ${vr.status}`}</ListItem>
                        )}
                    </List>
                </> :
                <Typography component='div'>
                    Keine Fahrzeugregistrierungen gefunden
                </Typography>
            }
        </>
    )
}

export default DeleteVehiclesDialogContent