import { Box, Button, TextField } from '@mui/material'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { bulkUpdateQuotaStatus } from '../quotas-admin/api/bulkUpdateQuotaStatus'

const BulkUpdateQuotaStatus = (props: { action: string }) => {
  const [quotaIds, setQuotaIds] = useState<string>('')

  /**
   * It takes a string of comma separated quota ids, converts it into an array of integers, and then
   * calls the bulkUpdateQuotaStatus function and passes in the ids and action
   */
  const handleClick = async () => {
    try {
      /* Taking the string of comma separated quota ids and converting it into an array of integers. */
      const ids = quotaIds
        .split(',')
        .map(id => id.trim())
        .map(id => parseInt(id))

      /* Calling the bulkUpdateQuotaStatus function and passing in the ids and action. */
      await bulkUpdateQuotaStatus({ ids, action: props.action })
      toast.success(`Bulk ${props.action} triggered`)
    } catch (err) {
      /* A function from the `react-hot-toast` library that displays a message to the user. */
      toast.error('Bulk Update Failed')
    } finally {
      /* Resetting the quotaIds state to an empty string. */
      setQuotaIds('')
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        m: 2,
      }}
    >
      <TextField
        label='List of Quota Ids'
        size='small'
        variant='outlined'
        value={quotaIds}
        onChange={event => setQuotaIds(event.target.value)}
      />
      <Button variant='contained' size='small' onClick={handleClick}>
        Bulk {props.action} listed quota ids
      </Button>
    </Box>
  )
}

export default BulkUpdateQuotaStatus
