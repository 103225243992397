import { useState, useEffect } from 'react'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { getVehiclesPromise } from '../../api/getVehicles'
import { createQuota } from '../../api/createQuota'

/**
 * `createQuotaData` is an object with two properties, `vrId` and `uba_year`, where `vrId` is a number
 * and `uba_year` is a number.
 * @property {number} vrId - The id of the VR
 * @property {number} uba_year - The year for which the quota is being created.
 */
export type createQuotaData = {
  vrId: number
  uba_year: number
}

/**
 * It's a React component that takes a status prop and returns a disabled button with the status as the
 * text
 * @param  - { status: string }
 */
const AlreadyExistsButton = ({ status }: { status: string }) => (
  <Button disabled variant='contained'>
    {status}
  </Button>
)

/**
 * It takes a `vrId` and `uba_year` and returns a button that, when clicked, will create a quota for
 * that `vrId` and `uba_year`
 * @param {createQuotaData}  - vrId - the id of the VR you want to create a quota for
 * @returns A button that is disabled if the result is not null.
 */
const CreateQuotaButton = ({ vrId, uba_year }: createQuotaData) => {
  const [result, setResult] = useState<string>(null)

  const requestQuota = async () => {
    const quota = await createQuota({ vrId, uba_year })
    quota?.status ? setResult('Created') : setResult('Failed')
  }

  return (
    <Button disabled={!!result} variant='contained' onClick={requestQuota}>
      {result ?? 'Create'}
    </Button>
  )
}

/* It's a React component that takes a `vehicleIds` prop and returns a table with a row for each
vehicle. Each row has a cell for each year. If the vehicle has a quota for that year, the cell will
contain a disabled button with the status of the quota. If the vehicle does not have a quota for
that year, the cell will contain a button that, when clicked, will create a quota for that vehicle and
year. */
export const RequestQuotaTable = ({ vehicleIds }: { vehicleIds: number[] }) => {
  const uba_years = [2022, 2023]
  const [vehicles, setVehicles] = useState([])

  const hasQuotaWithStatus = (vehicle: any, uba_year: number) => {
    return vehicle?.quotaSales?.find((qs: any) => qs?.uba_year === uba_year)
      ?.status
  }

  useEffect(() => {
    getVehiclesPromise({
      id: 0,
      name: '',
      filter: { id: { in: vehicleIds } },
    }).then(res => setVehicles(res?.data ?? []))
  }, [vehicleIds])

  return (
    <TableContainer
      sx={{ maxHeight: '80vh', overflowY: 'auto' }}
      component={Paper}
    >
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>Vehicle ID</TableCell>
            <TableCell>Quota 2022</TableCell>
            <TableCell>Quota 2023</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Mapping over the vehicles array and returning a table row for each vehicle. */}
          {vehicles.map(vr => (
            <TableRow key={vr?.id}>
              <TableCell align='center'>{vr?.id}</TableCell>
              {/* Mapping over the uba_years array and returning a table cell for each year. */}
              {uba_years.map(uba_year => (
                <TableCell align='center'>
                  {hasQuotaWithStatus(vr, uba_year) ? (
                    <AlreadyExistsButton
                      status={hasQuotaWithStatus(vr, uba_year)}
                    />
                  ) : (
                    <CreateQuotaButton vrId={vr.id} uba_year={uba_year} />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
