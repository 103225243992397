import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Make an API request using the provided url.
 */
export const getB2BSampleXML = (): Promise<any> => {
  // Build up the complete url
  const url = `${API_URL}/v2/invoice/banktransfer/export/xml/b2bsample`;

  return fetcher(url, {
    method: 'GET',
  })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
