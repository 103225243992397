import Button from '@mui/material/Button';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { useContext } from 'react';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import { deleteTemplate } from '../../api/deleteTemplate';
import { ContextActionEnum, GlobalContext } from '../../../context/GlobalState';
import { IEmailTemplate } from '../../api/getTemplates';

/**
 * Defines the properties required by the
 * current dialog component.
 */
export interface IDeleteTemplateDialogProps {
  setModal: Function
  template: IEmailTemplate;
}

export default function DeleteTemplateDialog(props: IDeleteTemplateDialogProps) {
  const { setTriggerAction, setRefreshList } = useContext(GlobalContext);

  const handleDelete = (templateName: string) => {
    deleteTemplate(templateName)
      .then(result => {
        // Show the success status on main page.
        setTriggerAction({
          type: ContextActionEnum.DELETE_TEMPLATE,
          progress: 100
        });
        // Close this modal
        props.setModal(null);
        // Refresh the list of templates.
        setRefreshList(Math.floor(Math.random() * 10000));
      })
  }

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        maxWidth="sm"
        open={true}
      >

        {/* The title of the current dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.setModal(null)}>
          Vorlage löschen
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ height: 200 }}>

          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            Sind Sie sicher, dass Sie die Vorlage mit dem Namen { props.template.TemplateName } möchten
          </Typography>

        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>

          {/* The SAVE button */}
          <div style={{ flex: 1, width: 50, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => handleDelete(props.template.TemplateName)}>
              Löschen
            </Button>
          </div>

        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
