import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'
import { UserAnalyticsType } from '../tables/UserAnalytics'

/**
 * It fetches the user analytics from the API and returns the response and the current time
 * @returns An object with two properties: res and timestamp.
 */
export const getUserAnalytics = async (): Promise<{ res: UserAnalyticsType, timestamp: string }> => {
  const url = `${API_URL}/users/analytics`
  return { res: await fetcher(url), timestamp: new Date().toLocaleTimeString() }
}
