import Button from '@mui/material/Button';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import FormGenerator from '../../../common/form-generator/FormGenerator';
import { FormConfigurationTypeEnum, IActiveTab, IFormField } from '../../../common/form-generator/interfaces';

/**
 * Defines the properties required by the
 * current dialog component.
 */
export interface IEditUserDialogProps {
  setModal: Function
  user: any;
}

export default function EditUserDialog(props: IEditUserDialogProps) {
  // Holds the vehicle data.
  const [ activeTab, setActiveTab ] = useState<IActiveTab>({ current: 1, total: 1 });
  // Indicates if the user wishes to save the data or not.
  const [ shouldSave, setShouldSave ] = useState<boolean>(false);
  // Indicates if the form has a validation error.
  const [ isValid, setIsValid ] = useState<boolean>(true);
  // Holds the list of fields.
  const [ fields, setFields ] = useState<IFormField[]>([]);

  /**
   * Handle the next wizard step when
   * the user wishes to advance based
   * on the current tab.
   */
  const handleStepAdvance = () => {
    /**
     * Check if we are on the last tab and
     * if not then switch to it.
     */
    activeTab.current < 3 ? setActiveTab({ current: activeTab.current + 1, total: activeTab.total }) : setShouldSave(true);
  }

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open={true}
      >

        {/* The title of the current dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.setModal(null)}>
          {props.user ? 'Edit user' : 'Add new user'}
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ height: 600 }}>

          <FormGenerator
            type={FormConfigurationTypeEnum.USER}
            currentObject={props.user}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            shouldSave={shouldSave}
            setShouldSave={setShouldSave}
            isValid={isValid}
            setIsValid={setIsValid}
            setFields={setFields}
            fields={fields}
          />

        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>

          {/* Validation error message */}
          {!isValid && 
            <Typography component="div" sx={{ flex: 1, color: 'red', ml: 1 }}>
              Das Formular enthält Validierungsfehler
            </Typography>
          }

          {/* The SAVE button */}
          <div style={{ flex: 1, width: 50, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleStepAdvance}>
              {activeTab.current < activeTab.total ? 'Nächste' : 'Speichern'}
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
