import {
  Box,
  useTheme,
} from '@mui/material';

import { useState } from 'react';
import { LoginUserBody, loginUser } from '../../utils/api/loginUser';
import { saveTokens } from '../../utils/auth/auth';
import toast, { Toaster } from 'react-hot-toast';
import FormContainer from '../Form/FormContainer/FormContainer';
import { IFieldConfig } from '../Form/generator';
import InputField from '../Form/InputField';
import PasswordField from '../Form/PasswordField';
import FormButton from '../Form/FormButton';
import FormTitle from '../Form/FormTitle/FormTitle';
import FormAlert from '../Form/FormAlert/FormAlert';
import { IRedirectionTypeMessage } from './getRedirectMessage';
import { getStyle } from './styles';
import { useTenant } from '../../hooks/useTenant';
import getLabel from '../../constants/labels';
import { useNavigate } from 'react-router-dom';

export const LoginForm: React.FC = () => {
  const fields: IFieldConfig[] = [
    {
      name: 'email',
      type: 'text',
      label: 'EMAIL',
      placeholder: 'Email address',
      validators: []
    },
    {
      name: 'password',
      type: 'password',
      label: 'PASSWORD',
      placeholder: 'Your password',
      validators: []
    },
  ];
  const theme = useTheme();
  const navigate = useNavigate();
  /**
   * Get the current tenant so we can load
   * the required labels and styles.
   */
  const tenant = useTenant();
  /**
   * Update the labels inside all the fields
   * so the keys are translated to normal
   * labels.
   */
  const processedFields = fields.map(f => ({ ...f, label: getLabel(f.label, 'login') }));
  /**
   * Holds the error message generated
   * by the backend if login has failed.
   */
  const [redirectionType, setRedirectionType] =
    useState<IRedirectionTypeMessage>(null);

  const onSubmit = async (fields: any) => {
    
    const body: LoginUserBody = {
      username: fields.email.trim(),
      password: fields.password,
    };

    try {
      const postData = await loginUser(body);

      if (postData.status === 401) {
        toast.error('Die E-Mail oder das Passwort ist nicht korrekt.');
      }

      if (postData.token) {
        saveTokens(postData.token, postData.refreshToken);
        setTimeout(() => navigate('/'), 1000);
      }
    } catch (error) {
      toast.error('Die E-Mail oder das Passwort ist nicht korrekt.');
    }
  };

  /**
   * Handle the closing of the SnackBar message.
   * We need to empty the redirectionType property.
   */
  const handlePasswordResetSuccessMessageClose = () => {
    // Reset the redirectionType.
    setRedirectionType(null);
  };

  return (
    <Box sx={getStyle(theme, tenant, 'parentBox')}>
      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
            theme: {
              primary: '#13B365',
            },
          },
        }}
      />
      
      {/* Show the password reset success snackbar. */}
      {redirectionType &&
        <FormAlert onClose={handlePasswordResetSuccessMessageClose} autoHide={3000} severity={redirectionType.severity} message={redirectionType.message} />
      }

      {/* The form. */}
      <FormContainer fields={processedFields} onSubmit={onSubmit} style={getStyle(theme, tenant, 'formContainer')}>

        {/* Greeting message. */}
        <FormTitle label={getLabel('TITLE', 'login')} style={getStyle(theme, tenant, 'heading')} />

        {/* Email field. */}
        <InputField name="email" autofocus={true} />

        {/* Password field. */}
        <PasswordField name="password" />

        {/* The submit button. */}
        <FormButton label={getLabel('SUBMIT', 'login')} disabled={false}></FormButton>

      </FormContainer>

    </Box>
  );
};
