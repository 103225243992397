import { Button } from "@mui/material";
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import ElectricCarIcon from '@mui/icons-material/ElectricCar';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param selectedIds number
 * @returns boolean
 */
const DeleteVehiclesAction = (props: { selectedIds: number[], setModal: Function, context: ContextScopeEnum, sx?: any, overwriteShow?: boolean }) => {
  const hasVehicleContext = props.context === ContextScopeEnum.VEHICLES
  const hasSelection = Boolean(props.selectedIds?.length)
  const show = props.overwriteShow || (hasVehicleContext && hasSelection)

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.setModal({ type: ContextActionEnum.DELETE_VEHICLES })} sx={props.sx ?? {}}>
        <ElectricCarIcon />
        &nbsp;{props.selectedIds?.length > 1 ? 'Delete Vehicles' : 'Delete Vehicle'}
      </Button>
    );
  }
  return <></>;
}

export default DeleteVehiclesAction;