import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Delete the template with the provided name.
 *
 * @param templateName string
 */
export const deleteTemplate = (templateName: string): Promise<any> => {
  /**
   * Decide if we are dealing with a new or
   * existing vehicle. The request props changed
   * based on that.
   */
  const url = `${API_URL}/bulkmail/templates/${templateName}`;
  /**
   * Send the request to the provided endpoint
   * and check if the response was successful
   * or not.
   */
  return fetcher(url, {
    method: 'DELETE',
  });
};
