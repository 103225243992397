import { Box, TextField, Typography } from '@mui/material'

/**
 * It's a React component that renders a form with two inputs, one for the limit and one for the skip
 * @param props - {
 * @returns A React component
 */
const InvoiceBatch = (props: {
  limit: number
  skip: number
  setLimit: Function
  setSkip: Function
}) => {
  return (
    <Box my={2}>
      <Typography align='center' my={2}>
        Batch Options:
      </Typography>

      <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
        <TextField
          label='limit'
          type='number'
          value={props.limit}
          onChange={event => props.setLimit(event.target.value)}
        />
        <TextField
          label='skip'
          type='number'
          value={props.skip}
          onChange={event => props.setSkip(event.target.value)}
        />
      </Box>
    </Box>
  )
}

export default InvoiceBatch
