import { Button } from "@mui/material";
import { ContextScopeEnum } from "../../context/GlobalState";
import DownloadIcon from '@mui/icons-material/Download';

/**
 * "If the context is USERS and there are no selected ids, then show a button that downloads a CSV of
 * all users."
 * 
 * The function is a React component that returns a button. The button is only shown if the context is
 * USERS and there are no selected ids
 * @param props - { downloadUsersCSVAction: Function, context: ContextScopeEnum, selectedUsernames: string[]
 * }
 * @returns A button that will download a CSV of all users.
 */
const DownloadUsersCSVAction = (props: { downloadUsersCSV: Function, context: ContextScopeEnum, selectedUsernames: string[] }) => {
  const show = props.context === ContextScopeEnum.USERS;
  const hasNoneSelected = !props.selectedUsernames.length;

  if (show && hasNoneSelected) {
    return (
      <Button color="inherit" onClick={() => props.downloadUsersCSV(false)}>
        <DownloadIcon />
        &nbsp;Users CSV
      </Button>
    );
  }
  return <></>;
}

export default DownloadUsersCSVAction;