import { Box } from '@mui/material';

type Props = {
  width?: string;
  height?: string;
  sx?: any;
};

export const IconUpload = ({ width = '32px', height = '24px', sx }: Props) => {
  return (
    <Box
      component="svg"
      width={width}
      height={height}
      viewBox="0 0 32 24"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      sx={sx}
    >
      <path
        d="m194 58.0004185v7.9995815l-30-.0004185v-7.9995815m15-12.4544025v13.8177738m4-11.3632713-4.000327-4.0001-3.999673 4.0001"
        fill="none"
        stroke="#2c75ff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(-163 -43)"
      />
    </Box>
  );
};
