import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'
import { QuotaAnalyticsType } from '../tables/QuotaAnalytics'

/**
 * It makes a GET request to the `/quota-sales/analytics` endpoint and returns the data
 * @returns A promise that resolves to an object with the following properties:
 */
export const getQuotaAnalytics = async (): Promise<{ res: QuotaAnalyticsType, timestamp: string }> => {
  const url = `${API_URL}/quota-sales/analytics`
  return await { res: await fetcher(url), timestamp: new Date().toLocaleTimeString() }
}