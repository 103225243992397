import { Button } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ContextScopeEnum, ContextActionEnum } from '../../context/GlobalState'
import { IFilterItem, FILTER_TYPE_ENUM } from '../../common/FilterMenu'

/**
 * It returns a button that opens a modal to mark quotas as paid
 * @param props - {
 * @returns A button that will mark quotas as paid.
 */
const PayQuotaSalesAction = (props: {
  predefinedFilters: IFilterItem[]
  selectedIds: number[]
  setModal: Function
  context: ContextScopeEnum
}) => {
  const hasRequiredContext = props.context === ContextScopeEnum.QUOTAS
  const hasPredefinedFilters =
    !!props.predefinedFilters && !!props.predefinedFilters.length
  const isInvoiceSent = props?.predefinedFilters?.find(
    filter => filter?.type === FILTER_TYPE_ENUM.INVOICE_SENT
  )
  const show =
    hasPredefinedFilters &&
    !!props.selectedIds.length &&
    isInvoiceSent &&
    hasRequiredContext

  if (show) {
    return (
      <Button
        color='inherit'
        onClick={() =>
          props.setModal({ type: ContextActionEnum.MARK_PAID_OUT })
        }
      >
        <CheckCircleIcon />
        &nbsp;Mark Quotas as Paid
      </Button>
    )
  }
  return <></>
}

export default PayQuotaSalesAction
