import { stringify } from "query-string";
import { API_URL } from "../../../../constants/api";
import { canUseFeature } from "../../../../hooks/canUseFeature";
import { fetcher } from "../../../../utils/fetcher";
import { IPagination } from "../../context/GlobalState";

/**
 * Make an API request using the provided url.
 */
export const getInvoicesPromiseV1 = (pagination: IPagination): Promise<any> => {
  // Calculate the range.
  const range = [pagination.range[0], pagination.range[1]];
  // Add the required query params.
  const params = {
    range: JSON.stringify(range),
  };
  // check which feature is allowed
  const invoicerV1Feature = canUseFeature("InvoicerV1");
  /* Checking if the feature is allowed. If not, it will return. */
  if (!invoicerV1Feature) return

  // Stringify the query params.
  const stringParams = `${stringify(params)}`;
  // Build up the complete url
  const url = `${API_URL}/invoice/all?${stringParams}`

  return fetcher(url)
};

/**
 * Make an API request using the provided url.
 */
export const getInvoicesPromiseV2 = (pagination: IPagination): Promise<any> => {
  // Calculate the range.
  const range = [pagination.range[0], pagination.range[1]];
  // Add the required query params.
  const params = {
    range: JSON.stringify(range),
  };
  // check which feature is allowed
  const invoicerV2Feature = canUseFeature("InvoicerV2");
  /* Checking if the feature is allowed. If not, it will return. */
  if (!invoicerV2Feature) return

  // Stringify the query params.
  const stringParams = `${stringify(params)}`;
  // Build up the complete url
  const url = `${API_URL}/v2/invoice/all?${stringParams}`;

  return fetcher(url)
};
