import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { IVehicleRegistrationRow } from './getVehicles';

/**
 * Save the provided vehicle object.
 *
 * @param vehicle IVehicleRegistrationRow
 */
export const saveVehicle = (vehicle: IVehicleRegistrationRow): Promise<any> => {
  /**
   * Decide if we are dealing with a new or
   * existing vehicle. The request props changed
   * based on that.
   */
  const id = vehicle.id ? vehicle.id : '';
  /* It's creating an array of years that the vehicle should be marketed for. */
  const uba_years = []
  if (vehicle.uba2022) uba_years.push(2022)
  if (vehicle.uba2023) uba_years.push(2023)
  /* delete checkbox values. */
  delete vehicle.uba2022
  delete vehicle.uba2023
  // Add the sub params if it's a POST.
  const url = `${API_URL}/vehicle-registrations/${id}`;
  const method = vehicle.id ? 'PUT' : 'POST';
  /**
   * Send the request to the provided endpoint
   * and check if the response was successful
   * or not.
   */
    return fetcher(url, {
    method,
    body: JSON.stringify({ ...vehicle, uba_years }),
  });
};
