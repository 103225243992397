import { Button } from "@mui/material";
import { ContextScopeEnum } from "../../context/GlobalState";
import DownloadIcon from '@mui/icons-material/Download';
import { IFilterItem } from "../../common/FilterMenu";

/**
 * "If the context is quotas and there are no selected quotas, then show the download quotas CSV
 * button."
 * 
 * The function is a React component that returns a button. The button is only shown if the context is
 *  and there are no selected quotas
 * @param props - { downloadQuotasCSV: Function, context: ContextScopeEnum, selectedIds: number[] }
 * @returns A button that will download a CSV of all quotas.
 */
const DownloadQuotaSalesCSVAction = (props: { predefinedFilters: IFilterItem[], downloadQuotasCSV: Function, context: ContextScopeEnum, selectedIds: number[] }) => {
  const hasQuotaContext = props.context === ContextScopeEnum.QUOTAS;
  const hasNoPredefinedFilter = !props.predefinedFilters.length;
  const hasNoneSelected = !props.selectedIds.length;
  /* It's a boolean expression that returns true if the context is quotas and there are no selected
  quotas. */
  const show = hasQuotaContext && hasNoPredefinedFilter && hasNoneSelected

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.downloadQuotasCSV(false)}>
        <DownloadIcon />
        &nbsp;Quotas CSV
      </Button>
    );
  }
  return <></>;
}

export default DownloadQuotaSalesCSVAction;
