import * as React from 'react';
import { styled, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { SidebarMenu } from './SidebarMenu';
import Vehicles from './vehicles-admin/Vehicles';
import Quotas from './quotas-admin/Quotas'
import { ContextScopeEnum, GlobalContext } from './context/GlobalState';
import { useState, useContext, useRef } from 'react';
import SubmitQuotaSalesAction from './quotas-admin/actions/SubmitQuotaSalesAction'
import ShowTemplateAction from './users-admin/actions/ShowTemplateAction';
import Users from './users-admin/Users';
import ApproveQuotaSalesAction from './quotas-admin/actions/ApproveQuotaSalesAction';
import SellQuotaSalesAction from './quotas-admin/actions/SellQuotaSalesAction';
import AddVehicleAction from './vehicles-admin/actions/AddVehicleAction';
import GenerateInvoiceAction from './quotas-admin/actions/GenerateInvoiceAction';
import AddUserAction from './users-admin/actions/AddUserAction';
import DeleteUserAction from './users-admin/actions/DeleteUserAction';
import DownloadB2CCSVAction from './invoicing-admin/actions/DownloadB2CCSVAction';
import DownloadB2BCSVAction from './invoicing-admin/actions/DownloadB2BCSVAction';
import { getCSVExport } from './invoicing-admin/api/csvExport';
import { getDebtorCSV } from './users-admin/api/getDebtorCSV';
import SendInvoiceAction from './quotas-admin/actions/SendInvoiceAction'
import GetDebtorB2CCSVAction from './users-admin/actions/DownloadDebtorCSVAction';
import GetDebtorB2BCSVAction from './users-admin/actions/DownloadDebtorB2BCSVAction';
import Invoices from './invoicing-admin/Invoices';
import Templates from './templates-admin/Templates';
import EditTemplateAction from './templates-admin/actions/EditTemplateAction';
import DeleteTemplateAction from './templates-admin/actions/DeleteTemplateAction';
import Uploads from './pdf-uploads/Uploads';
import InvoiceTemplates from './invoice-templates-admin/InvoiceTemplates';
import { Toaster } from 'react-hot-toast'
import { getUsersCSV } from './users-admin/api/getUsersCSV';
import { getVehiclesCSV } from './vehicles-admin/api/getVehiclesCSV';
import DownloadUsersCSVAction from './users-admin/actions/DownloadUsersCSVAction';
import DownloadVehiclesCSVAction from './vehicles-admin/actions/DownloadVehiclesCSVAction';
import toast from 'react-hot-toast'
import { LicenseInfo } from '@mui/x-license-pro';
import DeleteVehiclesAction from './vehicles-admin/actions/DeleteVehiclesAction';
import DownloadBankTransferExportsAction from './invoicing-admin/actions/DownloadBankTransferExportsAction';
import ResetPasswordAction from './users-admin/actions/ResetPasswordAction';
import DownloadB2BtoXML from './invoicing-admin/actions/DownloadB2BtoXML';
import { getB2BSampleXML } from './invoicing-admin/api/getB2BSampleXML';
import PayQuotaSalesAction from './quotas-admin/actions/PayQuotaSalesAction';
import DeclineQuotaSalesAction from './quotas-admin/actions/DeclineQuotaSalesAction';
import EditInvoiceTemplateAction from './invoice-templates-admin/actions/EditInvoiceTemplateAction';
import RequestQuotaAction from './vehicles-admin/actions/ShowMarketAction';
import CreateUBASubmissionQuotaSalesAction from "./quotas-admin/actions/CreateUBASubmissionQuotaSalesAction";
import DownloadQuotaSalesCSVAction from './quotas-admin/actions/DownloadQuotaSalesCSVAction';
import { getQuotasCSV } from './quotas-admin/api/getQuotasCSV';
import Analytics from './analytics-admin/Analytics'
import { getUserArchivePreviewCSV } from './quotas-admin/api/getUserArchivePreviewCSV';
import GetUserArchivePreviewCSVAction from './users-admin/actions/DownloadUserArchivePreviewCSVAction';

LicenseInfo.setLicenseKey('66473ef64f09b208f5115b4c398821cbTz00NDczMixFPTE2ODU1MjA4Nzg5MjIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


const drawerWidth: number = 240;

const CSV_TRIGGERED_MESSAGE = 'CSV download triggered. An email will be sent to you with the download link.';
const CSV_COULD_NOT_FETCH = 'Could not fetch CSV data:';
const CSV_DOWNLOAD_FAILED = 'CSV download call failed: ';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

createTheme();

function DashboardContent() {
  // Use the global context to get the loading property.
  const {
    currentScope,
    setCurrentScope,
    selectedIds,
    selectedUsernames,
    predefinedFilters,
    setModal,
  } = useContext(GlobalContext);
  // Indicates if the app drawer is open or closed.
  const [open, setOpen] = React.useState(true);
  // Toggle the drawer open / close.
  const toggleDrawer = () => setOpen(!open);
  /**
   * Refs used for the CSVLink component that
   * we need to trigger automatically to generate
   * the CSV files for the user.
   */
  const csvLink = useRef<any>();
  const [csvData, setCSVData] = useState<string>('');
  const [xmlData, setXMLData] = useState<string>('');

  /**
   * Calls the required endpoint to download the
   * backend generated CSV file with invoices.
   */
  const downloadInvoicesCSV = async (isB2C?: boolean) => {
    // Empty the existing CSV data.
    setCSVData(null);
    toast('Downloading CSV with list of invoices. Please wait...')

    await getCSVExport(isB2C)
      .then(body => {
        const href = body.signedUrl;
        const a = csvLink.current;
        a.download = `InvoicesCSV_${new Date().toLocaleDateString()}.csv`;
        a.href = href;
        a.click();
        a.href = '';
      })
      .catch(error => console.error(CSV_COULD_NOT_FETCH, error));
  }

  /**
   * Calls the required endpoint to download the
   * backend generated XML file for the B2B sample
   * invoices.
   */
  const downloadInvoicesXML = async () => {
    // Empty the existing CSV data.
    setXMLData(null);
    toast('Downloading B2B Sample invoices XML. Please wait...');

    await getB2BSampleXML()
      .then(body => {
        const href = body?.links?.find((file: { fileName: string, fileURL: string }) => file?.fileName === 'Bank Transactions Export XML')?.fileURL;
        const a = csvLink.current;
        a.download = `B2BSampleXML_${new Date().toLocaleDateString()}.xml`;
        a.href = href;
        a.click();
        a.href = '';
      })
      .catch(error => console.error('Could not fetch XML data:', error));
  }

  /**
   * Calls the required endpoint to download the
   * backend generated Debtor CSV file.
   */
  const downloadDebtorCSV = async (isB2C: boolean = true) => {
    // Empty the existing CSV data.
    setCSVData(null);
    await getDebtorCSV(isB2C)
        .then(() => {
            toast.success(`Debtor ${CSV_TRIGGERED_MESSAGE}`)
        })
        .catch(error => {
            toast.error(`Debtor ${CSV_DOWNLOAD_FAILED}` + error)
            console.error(CSV_COULD_NOT_FETCH, error)
        });
  }

  /**
   * We're using the `getUsersCSV` function to fetch the CSV data from the server, then we're using the
   * `downloadUsersCSV` function to download the CSV data to the user's computer
   */
  const downloadUsersCSV = async () => {
    // Empty the existing CSV data.
    setCSVData(null);
    toast('Downloading Users.csv Please wait...')

    await getUsersCSV()
        .then(() => {
            toast.success(`Users ${CSV_TRIGGERED_MESSAGE}`)
        })
        .catch(error => {
            toast.error(`Users ${CSV_DOWNLOAD_FAILED}` + error)
            console.error(CSV_COULD_NOT_FETCH, error)
        });
  }

  /**
   * We're using the `getVehiclesCSV` function to get the CSV data from the API, then we're using the
   * `downloadVehiclesCSV` function to download the CSV data
   */
  const downloadVehiclesCSV = async () => {
    // Empty the existing CSV data.
    setCSVData(null);

    await getVehiclesCSV(predefinedFilters[0])
      .then(() => {
        toast.success(`Vehicle ${CSV_TRIGGERED_MESSAGE}`)
      })
      .catch(error => {
          toast.error(`Vehicle ${CSV_DOWNLOAD_FAILED}` + error)
          console.error(CSV_COULD_NOT_FETCH, error)
      });
  }

  /**
   * We're using the `getQuotasCSV` function to get the CSV data from the API, then we're using the
   * `downloadQuotasCSV` function to download the CSV data
   */
  const downloadQuotasCSV = async () => {
    // Empty the existing CSV data.
    setCSVData(null);

    await getQuotasCSV(predefinedFilters[0])
      .then(() => {
        toast.success(`Quotas ${CSV_TRIGGERED_MESSAGE}`)
      })
      .catch(error => {
          toast.error(`Quotas ${CSV_DOWNLOAD_FAILED}` + error)
          console.error(CSV_COULD_NOT_FETCH, error)
      });
  }

  /**
   * We're using the `getUserArchivePreviewCSV` function to fetch the CSV data from the server, then we're using the
   * `downloadUserArchivePreviewCSV` function to download the CSV data to the user's computer
   */
  const downloadUserArchivePreviewCSV = async () => {
    // Empty the existing CSV data.
    await getUserArchivePreviewCSV()
      .then(() => {
        toast.success(`User data archive preview ${CSV_TRIGGERED_MESSAGE}`)
      })
      .catch(error => {
          toast.error(`User data archive preview ${CSV_DOWNLOAD_FAILED}` + error)
          console.error(CSV_COULD_NOT_FETCH, error)
      });
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
            theme: {
              primary: '#13B365',
            },
          },
        }}
      />
      <CssBaseline />

      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {currentScope && currentScope.toString()}
          </Typography>
          {/* {loading && <CircularProgress />} */}

          {/*********************************************************/}
          {/***************** USER ACTIONS ***********************/}
          {/*********************************************************/}

          {/* Show the bulk email action on conditions. */}
          {<ShowTemplateAction
            selectedUsernames={selectedUsernames}
            setModal={setModal}
            context={currentScope} />}

          {<ResetPasswordAction
            selectedUsernames={selectedUsernames}
            setModal={setModal}
            context={currentScope} />}

          {/* Show the bulk email action on conditions. */}
          {<AddUserAction
            selectedUsernames={selectedUsernames}
            setModal={setModal}
            context={currentScope} />}

          {<DeleteUserAction
            selectedUsernames={selectedUsernames}
            setModal={setModal}
            context={currentScope} />}

          {currentScope === ContextScopeEnum.USERS &&
            <GetDebtorB2BCSVAction
              selectedUsernames={selectedUsernames}
              context={currentScope}
              downloadDebtorCSV={downloadDebtorCSV} />}

          {currentScope === ContextScopeEnum.USERS &&
            <GetDebtorB2CCSVAction
              selectedUsernames={selectedUsernames}
              context={currentScope}
              downloadDebtorCSV={downloadDebtorCSV} />}

          {<DownloadUsersCSVAction
            selectedUsernames={selectedUsernames}
            context={currentScope}
            downloadUsersCSV={downloadUsersCSV} />}

          {currentScope === ContextScopeEnum.USERS &&
            <GetUserArchivePreviewCSVAction
              selectedUsernames={selectedUsernames}
              context={currentScope}
              downloadUserArchivePreviewCSV={downloadUserArchivePreviewCSV} />}

          {/*********************************************************/}
          {/***************** TEMPLATE ACTIONS **********************/}
          {/*********************************************************/}

          {currentScope === ContextScopeEnum.TEMPLATES &&
            <EditTemplateAction
              selectedIds={selectedIds}
              setModal={setModal}
              context={currentScope} />
          }

          {currentScope === ContextScopeEnum.TEMPLATES &&
            <DeleteTemplateAction
              selectedIds={selectedIds}
              setModal={setModal}
              context={currentScope} />
          }

          {/*********************************************************/}
          {/***************** QUOTA ACTIONS ***********************/}
          {/*********************************************************/}

          {/* Generate invoice action for Users */}
          {<GenerateInvoiceAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            setModal={setModal}
            context={currentScope} />
          }

          {/* Generate invoice action for Users */}
          {<SendInvoiceAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            setModal={setModal}
            context={currentScope} />
          }

          {<SubmitQuotaSalesAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            setModal={setModal}
            context={currentScope} />}

          {/* Show the approve quota action on conditions. */}
          {<ApproveQuotaSalesAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            setModal={setModal}
            context={currentScope} />}

          {/* Show the decline quota action on conditions. */}
          {<DeclineQuotaSalesAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            setModal={setModal}
            context={currentScope} />}

          {/* Show the sell quota action on conditions. */}
          {<SellQuotaSalesAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            setModal={setModal}
            context={currentScope} />}

          {/* Show the pay quota action on conditions. */}
          {<PayQuotaSalesAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            setModal={setModal}
            context={currentScope} />}

          {/* Show create uba submission action on conditions. */}
          {<CreateUBASubmissionQuotaSalesAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            setModal={setModal}
            context={currentScope} />}


          {/*********************************************************/}
          {/***************** VEHICLE ACTIONS ***********************/}
          {/*********************************************************/}

          {/* Show the bulk email action on conditions. */}
          {<RequestQuotaAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            setModal={setModal}
            context={currentScope} />}

          {<AddVehicleAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            setModal={setModal}
            context={currentScope} />}

          {<DownloadVehiclesCSVAction
            selectedIds={selectedIds}
            context={currentScope}
            downloadVehiclesCSV={downloadVehiclesCSV} />}

          {<DownloadQuotaSalesCSVAction
            predefinedFilters={predefinedFilters}
            selectedIds={selectedIds}
            context={currentScope}
            downloadQuotasCSV={downloadQuotasCSV} />}

          {<DeleteVehiclesAction
            selectedIds={selectedIds}
            context={currentScope}
            setModal={setModal} />}



          {/*********************************************************/}
          {/***************** INVOICE ACTIONS ***********************/}
          {/*********************************************************/}

          {/* Show the bulk email action on conditions. */}
          {currentScope === ContextScopeEnum.INVOICING &&
            <DownloadB2CCSVAction
              selectedIds={selectedIds}
              context={currentScope}
              downloadCSV={() => downloadInvoicesCSV(true)} />}

          {currentScope === ContextScopeEnum.INVOICING &&
            <DownloadB2BCSVAction
              selectedIds={selectedIds}
              context={currentScope}
              downloadCSV={downloadInvoicesCSV} />}

          {currentScope === ContextScopeEnum.INVOICING &&
            <DownloadBankTransferExportsAction
              selectedIds={selectedIds}
              context={currentScope}
              setModal={setModal} />}

          {currentScope === ContextScopeEnum.INVOICING &&
            <DownloadB2BtoXML
              selectedIds={selectedIds}
              context={currentScope}
              downloadXML={downloadInvoicesXML} />}

          {/*********************************************************/}
          {/***************** INVOICE TEMPLATE ACTIONS ***********************/}
          {/*********************************************************/}

          {<EditInvoiceTemplateAction
            selectedIds={selectedIds}
            context={currentScope}
            setModal={setModal} />}



          {/* Following CSV links are used to download the CSV files. */}
          <a ref={csvLink} style={{ visibility: 'hidden' }}>Download CSV</a>

          {/* Show notifications bell button */}
          <IconButton color="inherit">
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>

        <Divider />

        <List component="nav">
          <SidebarMenu currentScope={currentScope} setCurrentScope={setCurrentScope} />
          <Divider sx={{ my: 1 }} />
        </List>
      </Drawer>

      {currentScope === ContextScopeEnum.USERS && <Users />}
      {currentScope === ContextScopeEnum.TEMPLATES && <Templates />}
      {currentScope === ContextScopeEnum.VEHICLES && <Vehicles />}
      {currentScope === ContextScopeEnum.QUOTAS && <Quotas />}
      {currentScope === ContextScopeEnum.INVOICING && <Invoices />}
      {currentScope === ContextScopeEnum.INVOICE_TEMPLATES && <InvoiceTemplates />}
      {currentScope === ContextScopeEnum.PDF_UPLOADS && <Uploads />}
      {currentScope === ContextScopeEnum.ANALYTICS && <Analytics />}

    </Box>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
