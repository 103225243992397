import { Button, SxProps } from "@mui/material";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import { IFilterItem } from "../../common/FilterMenu";

/**
 * It returns a button that opens a modal to request a quote for a vehicle
 * @param props - {
 * @returns A button that will open a modal to request a quote.
 */
const RequestQuotaAction = (props: {
  predefinedFilters: IFilterItem[]
  selectedIds: number[]
  modal?: ContextActionEnum;
  setModal: Function
  context: ContextScopeEnum;
  sx?: SxProps;
}) => {
  const hasSelection = props?.selectedIds?.length
  const hasVehicleContext = props.context === ContextScopeEnum.VEHICLES
  const show = hasSelection && hasVehicleContext

  if (show) {
    return (
      <Button
        color='inherit'
        onClick={() => props.setModal({ type: props.modal ?? ContextActionEnum.REQUEST_QUOTA })}
        sx={props.sx}
      >
        <RequestQuoteIcon />
        &nbsp;Request Quota
      </Button>
    )
  }
  return <></>
}

export default RequestQuotaAction;