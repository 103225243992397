import { Button } from "@mui/material";
import { ContextScopeEnum } from "../../context/GlobalState";
import DownloadIcon from '@mui/icons-material/Download';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedUsernames string
 * @returns boolean
 */
const GetDebtorB2CCSVAction = (props: { downloadDebtorCSV: Function, context: ContextScopeEnum, selectedUsernames: string[] }) => {
  const show = props.context === ContextScopeEnum.USERS;
  const hasNoneSelected = !props.selectedUsernames.length;

  if (show && hasNoneSelected) {
    return (
      <Button color="inherit" onClick={() => props.downloadDebtorCSV()}>
        <DownloadIcon />
        &nbsp;Debtor B2C CSV
      </Button>
    );
  }
  return <></>;
}

export default GetDebtorB2CCSVAction;