import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Save the provided user object.
 */
export const saveUser = async (user: any): Promise<any> => {
  /**
   * Decide if we are dealing with a new or
   * existing object. The request props changed
   * based on that.
   */
  const username = user.username
  const subUrl = username
    ? `users/${username}`
    : `users/new`;
  // Put together the entire URL for the following operation.
  const url = `${API_URL}/${subUrl}`;
  // The request method that will be used.
  const method = username ? 'PUT' : 'POST';
  /**
   * Send the request to the provided endpoint
   * and check if the response was successful
   * or not.
   */
  return fetcher(url, {
    method,
    body: JSON.stringify(user),
  });
};
