import Button from '@mui/material/Button';
import { DialogActions, DialogContent, Typography, CircularProgress } from '@mui/material';
import { Fragment, useState, useEffect } from 'react';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import FormGenerator from '../../../common/form-generator/FormGenerator';
import { FormConfigurationTypeEnum, IActiveTab, IButtonState, IFormField } from '../../../common/form-generator/interfaces';
import { manuallyMarkVehicleAudit } from '../../api/manuallyApproveAudit';
import toast from 'react-hot-toast';
import { hasCorrectStatus, hasIncompleteStatus, noValue, required } from '../../../common/form-generator/validators';
import { getVehicleById } from '../../api/getVehicleById'
import { updateVehicleStatus } from '../../api/updateVehicleStatus';
import { parseResponseJsonError } from '../../../../../utils/fetcher';

/**
 * Defines the properties required by the
 * current dialog component.
 */
export interface IEditVehicleDialogProps {
  setModal: Function
  vehicle: any
  setSelectedIds: Function
  nextVehicleId: number | null
}

/**
 * Manually approve the audit.
 */
const handleAuditMark = async (vehicleId: number, approve: boolean) => {
  try {
    const response = await manuallyMarkVehicleAudit(vehicleId, approve);
    
    if (!response.ok) {
      const error = await parseResponseJsonError(response);
      toast.error('Failed to change the vehicle status.' + (error ? ` Error: ${error}` : ''));
      return false;
    }

    toast.success('Vehicle status changed successfully');
    return true;
  } catch (error) {
    toast.error(`Failed to change the vehicle status. Error: ${error}`);
    return false;
  }
}

/**
 * Define the required button states for
 * the current dialog.
 */
const buttonStates: IButtonState[] = [
  {
    key: 'approved',
    label: 'Fahrzeug geprüft',
    active: true,
    conditions: [
      { key: 'errMsg', validator: noValue },
      { key: 'imageErrorStatus', validator: noValue },
      { key: 'status', validator: hasCorrectStatus },
    ],
    onClick: async (vehicleId: number, setShouldSave: Function, fields: IFormField[], setFields: Function) => {
      // Update status field
      setFields([...fields].map(field => field.key === 'status' ? {...field, value: 'VEHICLE_AUDITED'} : field))
      // Mark the vehicle for the new audit status.
      const success = await handleAuditMark(vehicleId, true);
      // Signal the FormGenerator that we want to save the vehicle.
      setShouldSave(success);
    }
  },
  {
    key: 'failed',
    label: 'Fehler - Email senden',
    active: true,
    conditions: [
      { key: 'errMsg', validator: required },
      { key: 'imageErrorStatus', validator: required },
      { key: 'status', validator: hasCorrectStatus },
    ],
    onClick: async (vehicleId: number, setShouldSave: Function, fields: IFormField[], setFields: Function) => {
      // Update status field
      setFields([...fields].map(field => field.key === 'status' ? {...field, value: 'INCOMPLETE'} : field))
      // Mark the vehicle for the new audit status.
      const success = await handleAuditMark(vehicleId, false);
      // Signal the FormGenerator that we want to save the vehicle.
      setShouldSave(success);
    }
  },
  {
    key: 'reupload',
    label: 'Bilder ausgetauscht',
    active: true,
    conditions: [
      { key: 'errMsg', validator: noValue },
      { key: 'imageErrorStatus', validator: noValue },
      { key: 'status', validator: hasIncompleteStatus },
    ],
    onClick: async (vehicleId: number, setShouldSave: Function, fields: IFormField[], setFields: Function) => {
      // Update status field
      setFields([...fields].map(field => field.key === 'status' ? {...field, value: 'INCOMPLETE'} : field))
      // Mark the vehicle for the new audit status.
      await updateVehicleStatus(vehicleId, 'reupload')
      // Signal the FormGenerator that we want to save the vehicle.
      setShouldSave(true);
    }
  },
]

export default function EditVehicleDialog(props: IEditVehicleDialogProps) {
  // Holds the vehicle data.
  const [activeTab, setActiveTab] = useState<IActiveTab>({ current: 1, total: 1 });
  // Indicates if the user wishes to save the data or not.
  const [shouldSave, setShouldSave] = useState<boolean>(false);
  // Indicates if the modal should go to next vehicle on save.
  const [shouldGoNext, setShouldGoNext] = useState<boolean>(false);
  // Indicates if the form has a validation error.
  const [isValid, setIsValid] = useState<boolean>(true);
  /**
   * Indicates which action buttons should be active
   * depending on the field states set inside the form
   * generator component.
   */
  const [activeButtons, setActiveButtons] = useState<IButtonState[]>(buttonStates);
  // Holds the list of fields.
  const [ fields, setFields ] = useState<IFormField[]>([]);
  // Loading vehicle.
  const [ loading, setLoading ] = useState(props.vehicle);
  // Holds current vehicle.
  const [ currentObject, setCurrentObject ] = useState(props.vehicle);
  /**
   * Handle the next wizard step when
   * the user wishes to advance based
   * on the current tab.
   */
  const handleStepAdvance = (goNext: boolean) => {
    /**
     * Check if we are on the last tab and
     * if not then switch to it.
     */
    setShouldGoNext(goNext)
    activeTab.current < 2 ? setActiveTab({ current: activeTab.current + 1, total: activeTab.total }) : setShouldSave(true);
  }

  const getVehicle = async() => {
    try {
      setLoading(true)
      const data = await getVehicleById(props.vehicle?.id)

      let frontImageFieldType: string | null = null;
      let backImageFieldType: string | null = null;

      if (data?.error) {
        toast.error(data?.error)
      } else {
        try {
          const [frontType, backType] = await Promise.all([
            getResourceContentType(data?.frontImage),
            getResourceContentType(data?.backImage)
          ]);
          frontImageFieldType = frontType;
          backImageFieldType = backType;
        } catch (error) {
          console.error(error);
        }
      }

      const restructuredVehicle = {
        ...data, 
        userFirstName: data?.owner?.firstName, 
        userLastName: data?.owner?.lastName, 
        userCompany: data?.owner?.company,
        frontImageFieldType,
        backImageFieldType,
      }
      setCurrentObject(restructuredVehicle)
    } catch (err) {
      toast.error('Error encountered while loading vehicle')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {    
    if (props.vehicle) {
      getVehicle()
    }
  }, [props.vehicle])

  useEffect(() => setShouldGoNext(false),[props.nextVehicleId])

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open={true}
        sx={{
          '& .MuiDialog-paper': {
            margin: 0,
            minHeight: loading ? 'auto' : '98vh'
          }
        }}
      >
        {loading && <CircularProgress />}
        {/* The title of the current dialog */}
        {!loading && <>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.setModal(null)}>
            {props.vehicle ? 'Edit vehicle' : 'Add new vehicle'}
          </BootstrapDialogTitle>
          <DialogContent dividers sx={{ height: 600 }}>

            <FormGenerator
              type={FormConfigurationTypeEnum.VEHICLE}
              currentObject={currentObject}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              shouldSave={shouldSave}
              setShouldSave={setShouldSave}
              isValid={isValid}
              setIsValid={setIsValid}
              buttonStates={activeButtons}
              setButtonStates={setActiveButtons}
              setFields={setFields}
              fields={fields}
              nextSelectedId={shouldGoNext && props.nextVehicleId}
            />

          </DialogContent>

          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>

            {/* Validation error message */}
            {!isValid &&
              <Typography component="div" sx={{ flex: 1, color: 'red', ml: 1 }}>
                Das Formular enthält Validierungsfehler
              </Typography>
            }

            {activeTab.current < activeTab.total  && <> {/* No buttons on the last tab (overview)  */}
              {/* Show the two action buttons for changing the vehicle status */}
              <div style={{ flex: 1, width: 50, display: 'flex', justifyContent: 'flex-end' }}>
                {/* Show only when status = AI_PARSED | UPLOADED */}
                <Fragment>
                  {!!props.vehicle && activeButtons && activeButtons.length &&
                    <>
                      {/* The configured action buttons */}
                      {activeButtons.map(buttonState =>

                        <Button
                          key={buttonState.key}
                          disabled={!buttonState.active}
                          onClick={() => {
                            if (buttonState.key === 'approved') {
                              setShouldGoNext(true)
                            }
                            buttonState.onClick(props.vehicle?.id, setShouldSave, fields, setFields)
                          }}
                        >{buttonState.label}</Button>

                      )}
                    </>
                  }
                </Fragment>
                {/* The SAVE button */}
                <Button onClick={() => handleStepAdvance(false)}>
                  {activeTab.current < (activeTab.total - 1) ? 'Nächste' : 'Speichern'}
                </Button>
                {/* The SAVE & GO TO NEXT VEHICLE button */}
                {props.nextVehicleId && activeTab.current === (activeTab.total - 1) && 
                  <Button onClick={() => handleStepAdvance(true)}>
                    Speichern - zum nächsten Fahrzeug
                  </Button>}
              </div>
            </>}
          </DialogActions>
        </>}
      </BootstrapDialog>
    </div>
  );
}

async function getResourceContentType(url: string): Promise<string | null> {
  if (!url) {
    return null;
  }
  const response = await fetch(url);
  const contentType = response?.headers?.get('content-type');
  return contentType ?? null;
}