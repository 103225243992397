import { useContext, useEffect, useState } from 'react'

import { GlobalContext } from '../context/GlobalState'
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro'
import { Columns } from './Columns'
import { Box, Grid, LinearProgress, Stack } from '@mui/material'
import JumpTo from '../common/JumpTo'
import BulkUpdateQuotaStatus from '../common/BulkUpdateQuotaStatus'

/**
 * Custom loading overlay to indicate to the user that
 * requested data is being loaded from the backend.
 */
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}

export default function QuotasList() {
  const {
    quotas,
    loading,
    predefinedFilters,
    pagination,
    setPagination,
    total,
    selectedIds,
    setSelectedIds,
  } = useContext(GlobalContext)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rows, setRows] = useState<any[]>([])

  const [action, setAction] = useState<string>(null)

  /**
   * It takes the first status from the list of predefined filters, and sets the bulk action to the
   * next step in the process
   */
  const updateBulkAction = () => {
    switch (predefinedFilters.map(f => f?.filter?.status).filter(s => !!s)[0]) {
      case 'USER_REQUESTED':
        return setAction('markSubmitted')
      case 'UBA_SUBMITTED':
        return setAction('markApproved')
      // case 'UBA_APPROVED':
      //   return setAction('markSold')
      case 'INVOICE_SENT':
        return setAction('payout')

      default:
        return setAction(null)
    }
  }

  useEffect(() => updateBulkAction(), [predefinedFilters])

  /**
   * Prepare the quota registrations table.
   */
  useEffect(() => {
    setRows(quotas && quotas.length ? quotas.map(v => v) : [])
  }, [quotas])

  const getItemsRange = (
    currentPageNumber: number,
    currentPageSize: number
  ): void => {
    var totalItemsCount = total
    var numberOfItemsPerPage = currentPageSize
    var page = currentPageNumber

    // page: 0 => 10 start: 0 end: 9
    // page: 1 => 10 start: 11 end: 20
    var start = page * numberOfItemsPerPage
    // start = start < 0 ? 0 : start;
    var end = Math.min(start + numberOfItemsPerPage - 1, totalItemsCount)
    /**
     * Set the changed pagination properties on the
     * global context.
     */
    setPagination({ range: [start, end], pageSize: currentPageSize })
    // Set the current page in the component state.
    setCurrentPage(page)
  }

  return (
    <Grid container>
      <Grid item md={12}>
        <div
          style={{
            height: pagination.pageSize > 10 ? '85vh' : 630,
            width: '100%',
          }}
        >
          <DataGridPro
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  No rows in DataGrid
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  There are no results to display
                </Stack>
              ),
            }}
            sx={{ backgroundColor: 'white' }}
            rows={rows}
            columns={Columns}
            loading={loading}
            paginationMode='server'
            page={currentPage}
            columnBuffer={Columns?.length + 1} // important for testing
            pageSize={pagination.pageSize}
            rowsPerPageOptions={[100, 500, 1000, 2000]}
            onPageChange={page =>
              !loading && getItemsRange(page, pagination.pageSize)
            }
            onPageSizeChange={newPageSize =>
              !loading && getItemsRange(currentPage, newPageSize)
            }
            pagination={true}
            rowCount={total}
            // isRowSelectable={() => true}
            // disableSelectionOnClick={true}

            onSelectionModelChange={(newSelection: any) =>
              setSelectedIds(newSelection)
            }
            selectionModel={selectedIds}
            checkboxSelection
          />
        </div>
        {/* A custom component that allows admins to jump to a specific page. */}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: action ? 'space-between' : 'end',
            mx: -2,
          }}
        >
          {action && (
            /* A component that allows admins to update the status of multiple quotas at once. */
            <BulkUpdateQuotaStatus action={action} />
          )}
          <JumpTo
            currentPage={currentPage}
            jump={(page: number) =>
              !loading && getItemsRange(page, pagination.pageSize)
            }
          />
        </Box>
      </Grid>
    </Grid>
  )
}
