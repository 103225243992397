import moment from 'moment'

export const Columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'vehicleId',
    headerName: 'Vehicle ID',
    type: 'number',
    width: 90,
  },
  {
    field: 'invoiceId',
    headerName: 'Invoice ID',
    type: 'number',
    width: 230,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
  },
  {
    field: 'uba_year',
    headerName: 'UBA Year',
    width: 130,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 160,
    valueGetter: (params: any) => {
      const date = params.row.updatedAt
      return date
        ? moment(date).format('DD/MM/YYYY  hh:mm:ss').toString()
        : null
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 120,
    valueGetter: (params: any) => {
      const date = params.row.createdAt
      return date ? moment(date).format('DD/MM/YYYY').toString() : null
    },
  },
  {
    field: 'submittedAt',
    headerName: 'Submitted At',
    width: 120,
    valueGetter: (params: any) => {
      const date = params.row.submittedAt
      return date ? moment(date).format('DD/MM/YYYY').toString() : null
    },
  },
  {
    field: 'approvalDate',
    headerName: 'Approval Date',
    width: 120,
    valueGetter: (params: any) => {
      const date = params.row.approvalDate
      return date ? moment(date).format('DD/MM/YYYY').toString() : null
    },
  },
  {
    field: 'declineDate',
    headerName: 'Decline Date',
    width: 120,
    valueGetter: (params: any) => {
      const date = params.row.declineDate
      return date ? moment(date).format('DD/MM/YYYY').toString() : null
    },
  },
  {
    field: 'soldAt',
    headerName: 'Sold At',
    width: 120,
    valueGetter: (params: any) => {
      const date = params.row.soldAt
      return date ? moment(date).format('DD/MM/YYYY').toString() : null
    },
  },
  {
    field: 'payoutDate',
    headerName: 'Payout Date',
    width: 120,
    valueGetter: (params: any) => {
      const date = params.row.payoutDate
      return date ? moment(date).format('DD/MM/YYYY').toString() : null
    },
  },
  {
    field: 'firstName',
    headerName: 'Vorname',
    width: 120,
  },
  {
    field: 'lastName',
    headerName: 'Nachname',
    width: 120,
  },
  {
    field: 'licensePlate',
    headerName: 'Kennzeichen',
    width: 120,
  },
  {
    field: 'carId',
    headerName: 'FIN',
    width: 220,
  },
  {
    field: 'utmSource',
    headerName: 'UTM Source',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmSource ?? '',
  },
  {
    field: 'utmMedium',
    headerName: 'UTM Medium',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmMedium ?? '',
  },
  {
    field: 'utmCampaign',
    headerName: 'UTM Campaign',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmCampaign ?? '',
  }
]
