import { Alert, Snackbar } from "@mui/material";

interface IFormAlertProps {
  onClose?: any;
  autoHide?: number;
  severity: 'success' | 'error';
  message: string;
}

/**
 * Display an alert message using the provided properties.
 */
const FormAlert: React.FC<IFormAlertProps> = ({ onClose, autoHide, severity, message }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={true}
      onClose={onClose}
      autoHideDuration={autoHide || 3000}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default FormAlert;