import { useContext, useEffect, useState } from 'react';
import { Box, Typography, SxProps } from '@mui/material';
import { deleteQuotaSale } from './deleteQuotaSale';
import toast from 'react-hot-toast';
import DeleteConfirmationDialog from '../../dialog/DeleteConfirmationDialog';
import QuotaCards from './QuotaCards/QuotaCards';
import DeleteVehiclesAction from '../../../vehicles-admin/actions/DeleteVehiclesAction';
import { ContextActionEnum, GlobalContext } from '../../../context/GlobalState';
import RequestQuotaAction from '../../../vehicles-admin/actions/ShowMarketAction';

export interface IQuotaSaleItem {
  approvalDate: string;
  createdAt: string;
  id: number;
  invoiceId: number;
  payoutDate: string;
  quotaSaleWaveId: number;
  soldAt: string;
  status: string;
  ubaErrorMessage: string;
  vehicleId: number;
  submittedAt: string;
  uba_year: number;
  declineDate: string;
}

export interface Props {
  vehicle: any;
}

const ACTION_STYLE: SxProps = {
  backgroundColor: '#1876d2',
  color: '#fff',
  my: 2,
  '&:hover': {
    backgroundColor: '#000',
  },
};

/**
 * Renders a list of quota sales for the currently
 * edited vehicle.
 */
const VehicleOverview = (props: Props) => {
  // Use the global context to get the loading property.
  const { currentScope, setModal } = useContext(GlobalContext)
  // Holds the list of all quotaSales for the current vehicle.
  const [quotaSales, setQuotaSales] = useState<IQuotaSaleItem[]>([]);
  // Indicates if the confirmation dialog should be shown or not.
  const [toDelete, setToDelete] = useState<IQuotaSaleItem>(null);

  useEffect(() => {
    setQuotaSales(props.vehicle.quotaSales);
  }, []);
  /**
   * Shows a modal where the user can confirm the
   * deletion of the pre-selected QuotaSale.
   */
  const showConfirmationModal = (quota: IQuotaSaleItem) => {
    setToDelete(quota);
  }
  /**
   * Handle the click even of the quota sale
   * delete button.
   * 
   * @param event any
   * @param id number
   * @returns void
   */
  const getHandleDeleteCallback = (id: number): () => void => {
    return () => {
      deleteQuotaSale(id)
        .then((res) => {
          /* Checking if the response has an error property and if it does, it will show a toast with
          the error message. */
          if (res?.error) {
            toast.error(res.error)
            return
          }
          // Show the confirmation messageof the deletion.
          toast.success('Quotenverkauf wurde erfolgreich gelöscht!');
          /**
           * Remove the quotaSale with the provided id from
           * the available list so we avoid re-fetching the
           * entire vehicle object and making the user re-open
           * the vehicles dialog.
           */
          setQuotaSales(quotaSales.filter((quota: IQuotaSaleItem) => quota.id !== id));
          // Close the confirmation modal.
          setToDelete(null);
        })
        .catch(() => {
          toast.error('Quotenverkauf konnte nicht gelöscht werden!');
          setToDelete(null);
        });
    }

  }

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2
        }}
      >
        <RequestQuotaAction
          predefinedFilters={[]}
          selectedIds={[props.vehicle.id]}
          context={currentScope}
          modal={ContextActionEnum.REQUEST_QUOTA_FROM_VEHICLE_FORM}
          setModal={setModal}
          sx={ACTION_STYLE}
        />
        <DeleteVehiclesAction
          selectedIds={[props.vehicle.id]}
          context={currentScope}
          setModal={setModal}
          overwriteShow={true}
          sx={ACTION_STYLE}
        />
      </Box>

      {/* Show the quota list. */}
      {/* {!!quotaSales && !!quotaSales.length && <QuotaDetailsList quotaSales={quotaSales} showConfirmationModal={showConfirmationModal}  />} */}

      {/* Show the quota cards. */}
      {!!quotaSales?.length && <QuotaCards quotaSales={quotaSales} showConfirmationModal={showConfirmationModal}  />}

      {!quotaSales?.length &&
        <Typography sx={{ fontWeight: 600 }}>Derzeit sind keine anzuzeigenden Quotenverkaufen vorhanden.</Typography>
      }

      {toDelete &&
        <DeleteConfirmationDialog
          title="Löschen bestätigung"
          content={`Sie sind dabei, das Quotenverkauf mit der Hergestellt datum ${toDelete.createdAt ? new Date(toDelete.createdAt).toLocaleDateString() : ''} und ${toDelete.approvalDate ? 'Genehmigungsdatum ' + new Date(toDelete.approvalDate).toLocaleDateString() : 'ohne Genehmigungsdatum'} zu löschen?`}
          confirmCallback={getHandleDeleteCallback(toDelete.id)}
          cancelCallback={() => {
            setToDelete(null);
          }}
        />
      }

    </Box>
  );
}

export default VehicleOverview
