import { Alert, Box, List, ListItem, Typography } from '@mui/material'
import { IDeleteUserPreview } from './DeleteUserDialog'

/**
 * Renders the delete user dialog content.
 */
const DeleteUserDialogPreview = (props: {
  data: IDeleteUserPreview
}) => {
  const user = (
    <>
      Benutzer <strong>{props.data.username}</strong>
    </>
  )
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const error = props.data.error
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const vehicleRegistrations: { id: number; status: string }[] = props.data.data?.vehicleRegistrations

  if (error) {
    // if we have any errors
    return (
      <Alert color='error'>
        {user}: {error}
      </Alert>
    )
  }

  // if everything ok
  return (
    <Box sx={{ my: 2 }}>
      {/* The user HAS vehicle registrations. */}
      {vehicleRegistrations?.length ? (
        <>
          <Typography component='div' sx={{ mt: 4 }}>
            Der {user} hat die folgenden Fahrzeuge
          </Typography>
          <List sx={{ color: '#f00' }}>
            {vehicleRegistrations.map(vr => (
              <ListItem
                key={`${props.data.username}-${vr.id}`}
              >{`Fahrzeug mit ID ${vr.id} und Status ${vr.status}`}</ListItem>
            ))}
          </List>
        </>
      ) : (
        <Typography component='div'>
          {/* The user HAS NO vehicle registrations. */}
          Dieser {user} hat 0 Fahrzeugregistrierungen
        </Typography>
      )}
    </Box>
  )
}

export default DeleteUserDialogPreview
