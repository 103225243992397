import { fetcher } from '../fetcher';
import { API_URL } from '../../constants/api';

export const refreshToken = (refreshToken: string) => {
  const url = `${API_URL}/auth/refresh-token`
  return fetcher(url, {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
  })
};
