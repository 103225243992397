import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'

/**
 * It sends a DELETE request to the /users endpoint with a list of users to delete
 * @param {{ username: string; token: string }[]} data - { username: string; token: string }[]
 * @returns A promise that resolves to the response from the server.
 */
export const deleteUsers = (
  data: { username: string; token: string }[]
): Promise<any> => {
  const url = `${API_URL}/users`
  const method = 'DELETE'

  return fetcher(url, {
    method,
    body: JSON.stringify({ data }),
  })
}
