import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, FormControlLabel, Typography } from "@mui/material"
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { API_URL } from "../../../../../constants/api";
import { fetcher } from "../../../../../utils/fetcher";
import { BootstrapDialogTitle } from "../BootstrapDialog";

interface Props {
  title: string;
  content: string;
  username: string;
  changeTo: boolean;
  cancelCallback: any;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    maxHeight: '40vh',
    maxWidth: '40vw'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BusinessChangeDialog: React.FC<Props> = ({ title, content, username, changeTo, cancelCallback }) => {
  // Holds the fetched preview data.
  const [previewData, setPreviewData] = useState<any>(null);
  // Indicates if the user checked the checkbox.
  const [isSure, setIsSure] = useState<boolean>(false);
  /**
   * Get the changes to be previewed by the user.
   */
  const getPreviewOfChanges = (username: string) => {
    // 
    const fetchedDataJson = fetcher(`${API_URL}/users/${username}/changeIsCompanyPreview`, {
      method: 'GET'
    });
    const fetchedData = fetchedDataJson
      .then((response: any) => response.data)
      .then((data: any) => setPreviewData(data));
  }
  /**
   * Apply the changes that previously have been previewed.
   */
  const executeChanges = (username: string) => {
    const fetchedData = fetcher(`${API_URL}/users/${username}/changeIsCompany`, {
      method: 'PUT',
      body: JSON.stringify({ isCompany: changeTo })
    });
    fetchedData
      .then((data: any) => toast.success('Angeforderte Änderungen wurden angewendet!'))
      .catch((error: any) => toast.error('Angeforderte Änderungen konnten nicht ausgeführt werden!'))
      .finally(() => cancelCallback());
  }
  /**
   * Get the changes preview from
   * the required endpoint.
   */
  useEffect(() => {
    getPreviewOfChanges(username);
  }, [])
  return (
    <div>
      <BootstrapDialog
        onClose={() => cancelCallback()}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open={true}
      >
        {/* The title of the current dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => cancelCallback()}>
          {title.toUpperCase()}
        </BootstrapDialogTitle>

        {/* The content of the confirmation modal. */}
        <DialogContent dividers sx={{ height: 600 }}>
          {content}

          {/* Old debtor number. */}
          {previewData &&
            <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ color: '#007acc' }}>Debtor number changes:</Typography>
              {/* Holds the line that presents the debtor number changes. */}
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ color: '#f75a52', mr: 1 }}>{previewData.debtorNumber.old}</Typography>
                <Typography sx={{ mr: 1 }}>{'->'}</Typography>
                <Typography sx={{ color: '#21cc3d' }}>{previewData.debtorNumber.new}</Typography>
              </Box>

              <Typography sx={{ color: '#007acc', mt: 2 }}>Business changes:</Typography>
              {/* Holds the line that presents the b2b / b2c changes. */}
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ color: '#f75a52', mr: 1 }}>{previewData.isCompany.old ? 'B2B' : 'B2C'}</Typography>
                <Typography sx={{ mr: 1 }}>{'->'}</Typography>
                <Typography sx={{ color: '#21cc3d' }}>{previewData.isCompany.new ? 'B2B' : 'B2C'}</Typography>
              </Box>
            </Box>
          }

          <Box sx={{ mt: 5 }}>
            <Typography>Bitte nehmen Sie zur Kenntnis, dass diese Änderungen den RECHNUNGSSTELLUNGS-Prozess unterbrechen könnten.</Typography>
            <Typography sx={{ color: '#f75a52' }}>Führen Sie diese Änderung nur durch, wenn Sie sich sicher sind, was Sie tun.</Typography>

            <FormControlLabel
              sx={{ mt: 2 }}
              control={<Checkbox
              checked={isSure}
              onChange={(event) => setIsSure(!isSure)}
              />} label="Möchten Sie die Änderungen wirklich übernehmen?" />
          </Box>
        </DialogContent>

        {/* The footer containing the actions available in this dialog. */}
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>

          {/* The cancel button. */}
          <Button onClick={() => cancelCallback()}>Abbrechen</Button>

          {/* The SAVE button */}
          <Button disabled={!isSure} onClick={() => executeChanges(username)}>Bestätigen</Button>

        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default BusinessChangeDialog