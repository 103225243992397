import { emptyUser } from "../../users-admin/dialogs/EditUserDialog/emptyUser";
import { emptyVehicle } from "../../vehicles-admin/dialogs/EditVehicleDialog/emptyVehicle";
import { vehicleFormConfig } from "../configs/vehicleFieldsConfig";
import { userFormConfig } from "../configs/userFieldsConfig";
import { TabsHelper } from "./tabsHelper";
import { FieldHelper } from "./fieldHelper";
import {
  FormConfigurationTypeEnum,
  IButtonState,
  IFormConfig,
  IFormField,
} from "./interfaces";
import { templateFormConfig } from "../configs/templateFieldsConfig";
import { invoiceTemplateFormConfig } from "../configs/invoiceTemplateFieldsConfig";
import { emptyTemplate } from "../../templates-admin/dialogs/EditTemplateDialog/emptyTemplate";
import { FieldValidationResult } from "./validationHelper";

export class FormHelper extends FieldHelper {
  /**
   * Holds the type of the object we are
   * currently working with (user or vehicle
   * for now).
   */
  private type: FormConfigurationTypeEnum;
  /**
   * Helps process the tabs and checks if there
   * are any tabs that should not be show in
   * the current mode.
   */
  private tabsHelper = new TabsHelper();

  constructor(type: FormConfigurationTypeEnum) {
    super();
    this.type = type;
  }

  /**
   * Get the list of required fields
   * for the current form.
   *
   * @param type FormConfigurationTypeEnum
   * @param mode string
   * @returns IFormConfig
   */
  public getFormConfiguration(type: FormConfigurationTypeEnum): IFormConfig {
    switch (type) {
      case FormConfigurationTypeEnum.USER:
        return this.tabsHelper.processConfig(userFormConfig);
      case FormConfigurationTypeEnum.VEHICLE:
        return this.tabsHelper.processConfig(vehicleFormConfig);
      case FormConfigurationTypeEnum.TEMPLATE:
        return this.tabsHelper.processConfig(templateFormConfig);
      case FormConfigurationTypeEnum.INVOICE_TEMPLATE:
        return this.tabsHelper.processConfig(invoiceTemplateFormConfig);
      default:
        return null;
    }
  }

  /**
   * Get an empty object of the type currently
   * set in the instance.
   *
   * @returns any
   */
  public getEmptyObject(): any {
    switch (this.type) {
      case FormConfigurationTypeEnum.USER:
        return emptyUser;
      case FormConfigurationTypeEnum.VEHICLE:
        return emptyVehicle;
      case FormConfigurationTypeEnum.TEMPLATE:
        return emptyTemplate;
      default:
        return null;
    }
  }

  /**
   * Check if the provided list of fields have
   * invalid fields between them.
   *
   * @param fields IFormField[]
   * @returns boolean
   */
  public isFormValid(fields: IFormField[]): boolean {
    return this.validationHelper.isFormValid(fields);
  }

  /**
   * Update the provided button state depending on
   * the depending field from the list of provided
   * fields.
   *
   * @param buttonState IButtonState
   * @param fields IFormField[]
   * @param setButtonState Function
   * @returns void
   */
  public updateButtonStates(
    buttonStates: IButtonState[],
    fields: IFormField[],
    setButtonStates: Function
  ): void {
    return this.validationHelper.updateButtonStates(
      buttonStates,
      fields,
      setButtonStates
    );
  }

  /**
   * Validates the provided list of fields and return an array with the validation errors.
   *
   * @param fields the fields to be validated
   * @returns an array containing all the validation errors; if there are no errors, then an empty
   * array is returned.
   */
  public validateForm(fields: IFormField[]): FieldValidationResult[] {
    return this.validationHelper.validateForm(fields);
  }
}
