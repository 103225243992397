import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Save the provided template object.
 *
 * @param template any
 */
export const saveTemplate = (template: any): Promise<any> => {
  /**
   * Decide if we are dealing with a new or
   * existing template. The request props changed
   * based on that.
   */
  const url = `${API_URL}/bulkmail/templates/${template.TemplateName}`;
  const method = template.id ? 'PUT' : 'POST';
  // Remove the template name from the body.
  const body = {
    SubjectPart: template.SubjectPart,
    TextPart: template.TextPart,
    HtmlPart: template.HtmlPart,
  };
  /**
   * Send the request to the provided endpoint
   * and check if the response was successful
   * or not.
   */
  return fetcher(url, {
    method,
    body: JSON.stringify(body),
  });
};
