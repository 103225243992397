import { IFormConfig } from "./interfaces";

export class TabsHelper {

  /**
   * Parse through the tabs configuration object
   * and filter out the tabs which don't belong
   * to the provided mode.
   * 
   * @param formConfig IFormConfig
   * @param mode string
   * @returns IFormConfig
   */
  public processConfig(formConfig: IFormConfig): IFormConfig {
    const newConfig = {
      ...formConfig,
      tabs: [ ...formConfig.tabs]
    };
    return newConfig;
  }
}