import { Button } from "@mui/material";
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import PersonIcon from '@mui/icons-material/Person';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param selectedIds number
 * @returns boolean
 */
const DeleteTemplateAction = (props: { selectedIds: number[], setModal: Function, context: ContextScopeEnum }) => {
  const hasOneSelection = props.selectedIds && props.selectedIds.length === 1;
  const hasRequiredContext = props.context === ContextScopeEnum.TEMPLATES;
  const isSelection = props.selectedIds && props.selectedIds.length;
  const show = hasOneSelection && hasRequiredContext;

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.setModal({ type: ContextActionEnum.DELETE_TEMPLATE })}>
        <PersonIcon />
        &nbsp;Vorlage löschen
      </Button>
    );
  }
  return <></>;
}

export default DeleteTemplateAction;