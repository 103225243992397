import React from "react";
import { IFieldConfig } from "../generator";

export interface IFormResult {
  name: string;
  value: string;
}

export class FormContainerHelper {
  
  /**
   * Parse through the list of fields and enhance
   * each of them with the required validators and
   * other stuff.
   * 
   * @param children
   * @param fields IFieldConfig[]
   * @returns any
   */
  public processChildren(children: any[], fields: IFieldConfig[]): any {
    // Process the list of children to enhance them with field config objects.
    return children.map((child: any) => this.processChild(child, fields));
  }

  /**
   * Parse through the provided fields and
   * check if it contains any invalid fields.
   * 
   * @param fields IFieldConfig[]
   * @returns boolean
   */
  public isValid(fields: IFieldConfig[]): boolean {
    const invalidFields = fields.filter(f => !f.valid);
    return !invalidFields.length;
  }

  /**
   * Get the list of fields in a key value pair form
   * from all the inputs contained inside the form.
   * 
   * @param fields IFieldConfig[]
   * @returns IFormResult[]
   */
  public getKeyValuePairs(fields: IFieldConfig[]): IFormResult[] {
    return fields.map(f => ({ name: f.name, value: f.value }));
  }

  public getFormData(fields: IFieldConfig[]): any {
    return fields.reduce((prevValue: any, curValue: any) => ({ ...prevValue, [curValue.name]: curValue.value}), {});
  }

  /**
   * Process the provided child element so it gets
   * the field config object attached to its body.
   */
  private processChild(child: any, fields: IFieldConfig[]): any {
    const generatedKeyName = Math.floor(Math.random() * 1000);
    return React.cloneElement(child, {
      key: generatedKeyName.toString(),
      field: child.props.name ? fields.find(f => f.name === child.props.name) : null,
      name: child.props.name
    });
  }
}