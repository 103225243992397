import { Card, CardContent, Divider, List, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import { Fragment, useContext, useEffect } from 'react';
import { GlobalContext } from '../context/GlobalState';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export enum FILTER_TYPE_ENUM {
  DIVIDER,
  UBA_2022,
  UBA_2023,
  USER_REQUESTED,
  UBA_SUBMITTED,
  UBA_APPROVED,
  UBA_FAILED,
  SOLD,
  INVOICE_GENERATED,
  INVOICE_SENT,
  PAID_OUT,
  REJECTED,
}

/**
 * Defines a filter object used to fetch different
 * types of data in relation to the list with which
 * it is being used together with.
 */
export interface IFilterItem {
  id: number;
  name: string;
  type?: FILTER_TYPE_ENUM,
  filter?: any;
  route?: string;
  checked?: boolean;
  active?: boolean;
  icon?: any;
  customMethod?: Function;
}

/**
 * Renders a filter menu usually placed on the
 * left side of a list.
 * 
 * @param props { filters: IFilterItem[] }
 * @returns 
 */
function FilterMenu(props: { filters: IFilterItem[] }) {
  // const [ filters, setFilters ] = useState<IFilterItem[]>(props.filters);
  // const [ reload, setReload ] = useState<boolean>(false);
  const { predefinedFilters, setPredefinedFilters, setChecksum, loading } = useContext(GlobalContext);

  /**
   * Automatically select the predefined filters
   * when they change.
   */
  useEffect(() => {
    if (predefinedFilters && predefinedFilters.length) {
      // Select the currently selected filter.
      props.filters.forEach(f => predefinedFilters.find(p => p.id === f.id) ? ({ ...f, checked: true }) : ({ ...f, checked: false }));
    } else if (predefinedFilters && !predefinedFilters.length) {
      // Clear the checkboxes on the list of vehicles.
      props.filters.forEach(f => f.checked = false);
      // Clear the checksum data received previously.
      setChecksum(null);
    }
  }, [predefinedFilters, loading]);

  /**
   * Handles the toggling of the filters.
   * 
   * @param filterId number
   * @returns void
   */
  const handleToggle = (filterId: number) => {
    // Check if there is already a checked filter.
    const foundChecked = props.filters.find(f => !!f.checked && f.id !== filterId);
    // De-select the checked filter.
    foundChecked && (foundChecked.checked = false);
    /**
     * Find the filter that was just clicked
     * by the user and select / unselect it
     * and also add it to the list of predefined
     * filters.
     */
    const foundFilter = props.filters.find(f => f.id === filterId);
    
    if (foundFilter) {
      /* Toggling the checkbox. */
      foundFilter.checked = !foundFilter.checked
      if (foundFilter.checked) {
        /* Checking if a same-category filter is already selected. */
        const previousFilter = predefinedFilters.find(
          item =>
            Object.keys(item.filter)?.[0] ===
            Object.keys(foundFilter.filter)?.[0]
        )
        /* Remove any same-category filter and add the new one. */
        setPredefinedFilters([
          ...predefinedFilters.filter(filter => filter !== previousFilter),
          foundFilter,
        ])
      } else {
        /* Removing the filter from the list of predefined filters. */
        setPredefinedFilters(
          predefinedFilters.filter(filter => filter !== foundFilter)
        )
      }
    }
  }

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <List
          sx={{ bgcolor: 'background.paper' }}
          component="nav"
        >
          {
            props.filters.map(filter => {
              if (filter.type === FILTER_TYPE_ENUM.DIVIDER) {
                return (
                  <Fragment key={filter.id}>
                    <li>
                      <Typography
                        sx={{ mt: 2, ml: 2 }}
                        color="text.secondary"
                        display="block"
                        variant="caption"
                      >
                        {filter.name}
                      </Typography>
                    </li>
                    <Divider component="li" />
                  </Fragment>
                )
              } else {
                return (
                  <MenuItem
                    sx={{ mt: 1 }}
                    key={filter.id}
                    disabled={!filter.active}
                    selected={filter.checked}
                    onClick={() => handleToggle(filter.id)}
                  >
                    <ListItemIcon sx={{ color: filter.checked ? '#1976d2' : '' }}>
                      {filter.icon ? filter.icon : <FormatListBulletedIcon />}
                    </ListItemIcon>
                    <ListItemText id={filter.name} primary={filter.name} />
                  </MenuItem>
                )
              }
            })
          }
        </List>
      </CardContent>
    </Card>
  )
}

export default FilterMenu
