import { Button } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ContextScopeEnum, ContextActionEnum } from "../../context/GlobalState";
import { IFilterItem, FILTER_TYPE_ENUM } from "../../common/FilterMenu";

/**
 * Trigger the Quota Sale approval process for
 * the selected vehicles.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds number
 * @returns boolean
 */
const ApproveQuotaSalesAction = (props: { predefinedFilters: IFilterItem[], selectedIds: number[], setModal: Function, context: ContextScopeEnum }) => {
  const hasRequiredContext = props.context === ContextScopeEnum.QUOTAS;
  const hasPredefinedFilters = !!props.predefinedFilters && !!props.predefinedFilters.length;
  const isUBASubmitted = props?.predefinedFilters?.find(
    filter => filter?.type === FILTER_TYPE_ENUM.UBA_SUBMITTED
  )
  const show = hasPredefinedFilters && !!props.selectedIds.length && isUBASubmitted && hasRequiredContext;

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.setModal({ type: ContextActionEnum.SHOW_DIALOG_APPROVE_QUOTA_SALES })}>
        <CheckCircleIcon />
        &nbsp;Approve quotas
      </Button>
    );
  }
  return <></>;
}

export default ApproveQuotaSalesAction;