import { useContext, useEffect, useRef, useState } from "react";

import { GlobalContext } from "../context/GlobalState";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { Grid, LinearProgress, Stack, Switch, Typography } from "@mui/material";
import { getColumns } from "./Columns";
import { canUseFeature } from "../../../hooks/canUseFeature";
import JumpTo from "../common/JumpTo";

/**
 * Custom loading overlay to indicate to the user that
 * requested data is being loaded from the backend.
 */
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const InvoiceList = () => {
  const {
    invoicesV1,
    invoicesV2,
    loading,
    pagination,
    setPagination,
    total,
    selectedIds,
    setSelectedIds,
    setTriggerAction,
    setRefreshList,
  } = useContext(GlobalContext)
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rows, setRows] = useState<any[]>([]);
  const [v2, setV2] = useState(canUseFeature('InvoicerV2'))
  /**
   * Refs used for the file download links
   * we need to trigger automatically for
   * saving the files.
   */
  const fileDownloadLink = useRef<any>(null);

  /**
   * Prepare the vehicle registrations table.
   */
  useEffect(() => {
    const inv = v2
      ? invoicesV2?.map((data: any) => ({
          ...data.userData,
          ...data,
        }))
      : invoicesV1?.map((data: any) => ({
          ...data.rawIncomingData,
          ...data.rawParsedData.userData,
          ...data,
        }))
    setRows(inv && inv.length ? inv.map((v: any) => v) : [])
  }, [v2, invoicesV1, invoicesV2])

  const getItemsRange = (
    currentPageNumber: number,
    currentPageSize: number
  ): void => {
    var totalItemsCount = total;
    var numberOfItemsPerPage = currentPageSize;
    var page = currentPageNumber;

    // page: 0 => 10 start: 0 end: 9
    // page: 1 => 10 start: 11 end: 20
    var start = page * numberOfItemsPerPage;
    // start = start < 0 ? 0 : start;
    var end = Math.min(start + numberOfItemsPerPage - 1, totalItemsCount);
    /**
     * Set the changed pagination properties on the
     * global context.
     */
    setPagination({ range: [start, end], pageSize: currentPageSize });
    // Set the current page in the component state.
    setCurrentPage(page);
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography>V1</Typography>
          <Switch
            checked={v2}
            onChange={(event) => setV2(event.target.checked)}
          />
          <Typography>V2</Typography>
        </Stack>
        <div style={{ height: pagination.pageSize > 10 ? '85vh' : 630 }}>
          <DataGridPro
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No rows in DataGrid
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  There are no results to display
                </Stack>
              ),
            }}
            sx={{ backgroundColor: "white" }}
            rows={rows}
            columns={getColumns(v2, setTriggerAction, setRefreshList, fileDownloadLink)}
            loading={loading}
            paginationMode="server"
            page={currentPage}
            columnBuffer={getColumns(v2, setTriggerAction, setRefreshList, fileDownloadLink)?.length + 1} // important for testing
            pageSize={pagination.pageSize}
            rowsPerPageOptions={[100, 500, 1000, 2000]}
            onPageChange={(page) =>
              !loading && getItemsRange(page, pagination.pageSize)
            }
            onPageSizeChange={(newPageSize) =>
              !loading && getItemsRange(currentPage, newPageSize)
            }
            pagination={true}
            rowCount={total}
            onSelectionModelChange={(newSelection: any) =>
              setSelectedIds(newSelection)
            }
            selectionModel={selectedIds}
            checkboxSelection
          />
        </div>
        {/* A custom component that allows admins to jump to a specific page. */}
        <JumpTo currentPage={currentPage} jump={(page: number) => !loading && getItemsRange(page, pagination.pageSize)} />
      </Grid>

      {/* Following CSV links are used to download the CSV files. */}
      <a ref={fileDownloadLink} style={{ visibility: 'hidden' }}>Download FILE</a>
    </Grid>
  );
};

export default InvoiceList;
