import { API_URL } from '../../../../constants/api';
import { stringify } from 'query-string';
import { IPagination } from '../../context/GlobalState';
import { fetcher } from '../../../../utils/fetcher';
import { IFilterItem } from '../../common/FilterMenu';

export interface IQuotaSale {
  id: number;
  status: string;
}

/**
 * Defines all the existing vehicle registration
 * specific properties.
 */
export interface IVehicleRegistrationRow {
  id: number;
  uniqueId: string;
  uniqueUserId: string;
  ownerId: number;
  ownerUsername: string;
  carId: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  zip: string;
  city: string;
  licensePlate: string;
  mainInspectionDate: string;
  manufacturerCode: string;
  modelCode: string;
  vehicleClass: string;
  frontImage?: string;
  backImage?: string;
  registeredAt: string;
  firstRegisteredAt: string;
  verificationStatus: number;
  createdAt: string;
  verifiedAt: string;
  ubaVerifiedAt: string;
  soldAt: string;
  bevChecked: boolean;
  errMsg: string;
  carbonifyAuditor: string;
  auditStatus: string;
  apiSessionId: string;
  quotaSales?: IQuotaSale[];
  uba2022?: boolean;
  uba2023?: boolean;
}

/**
 * Make an API request using the provided url.
 */
export const getVehiclesPromise1 = (
  filter: any,
  sort: string[],
  pagination: IPagination
): Promise<any> => {
  // Calculate the range.
  const range = [pagination.range[0], pagination.range[1]];
  // Add the required query params.
  const params = {
    filter: JSON.stringify(filter),
    range: JSON.stringify(range),
    sort: JSON.stringify(sort),
  };
  // Stringify the query params.
  const stringParams = `${stringify(params)}`;
  // Build up the complete url
  const url = `${API_URL}/vehicle-registrations?${stringParams}`;

  return fetcher(url)
    .then((data) => data)
    .catch((error) => {
      return error;
    });
  // Handle errors related to wrong status (401...).
  // error && handleStatusError(error);
};

/**
 * Make an API request using the provided url.
 */
export const getVehiclesPromise = (
  predefinedFilter?: IFilterItem,
  sort?: string[],
  pagination?: IPagination
): Promise<any> => {
  // Calculate the range.
  const range = [pagination?.range?.[0], pagination?.range?.[1]];
  // Add the required query params.
  const params = {
    filter: predefinedFilter && JSON.stringify(predefinedFilter?.filter || {}),
    range: pagination && JSON.stringify(range),
    sort: sort && JSON.stringify(sort),
  };
  // Stringify the query params.
  const stringParams = `${stringify(params)}`;
  // The custom route if it's the case.
  const route =
    (predefinedFilter && predefinedFilter.route) || 'vehicle-registrations';
  // Build up the complete url
  const url = `${API_URL}/${route}?${stringParams}`;

  return fetcher(url)
    .then((data) => data)
    .catch((error) => {
      return error;
    });
  // Handle errors related to wrong status (401...).
  // error && handleStatusError(error);
};
