import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It fetches the bank transfer export files from the API
 * @returns The data is being returned.
 */
export const bankTransferExports = () => {
  // Build up the complete url
  const url = `${API_URL}/v2/invoice/banktransfer/export/xml`;

  return fetcher(url, {
    method: 'GET',
  })
    .then((data) => data)
    .catch((error) => {
      return error;
    });
};
