import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It makes a GET request to the `/users/csv/all` endpoint, and returns the data
 * @returns A promise that resolves to the data returned from the API.
 */
export const getUsersCSV = (): Promise<any> => {
  // Build up the complete url
  const url = `${API_URL}/users/csv/all`;

  return fetcher(url, {
    method: 'GET',
  })
    .then((data) => data)
    .catch((error) => {
      return error;
    });
};
