import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { stringify } from 'query-string';
import { IFilterItem } from '../../common/FilterMenu';

/**
 * It makes a GET request to the `/quota-sales/csv` endpoint and returns the response
 * @returns A promise that resolves to the data returned from the API.
 */
export const getQuotasCSV = ( predefinedFilter: IFilterItem ): Promise<any> => {
  // Add the required query params.
  const params = {
    filter: JSON.stringify((predefinedFilter && predefinedFilter.filter) || {})
  };
  // Stringify the query params.
  const stringParams = `${stringify(params)}`;
  // Build up the complete url
  const url = `${API_URL}/quota-sales/csv?${stringParams}`;

  return fetcher(url, {
    method: 'GET',
  });
};
