import { API_URL } from "../../../../constants/api";
import { fetcher } from "../../../../utils/fetcher";
import { ContextActionEnum } from "../../context/GlobalState";

/**
 * Save the provided template object.
 *
 * @param template any
 */
 export const toggleMarkInvoicePromise = (
  invoiceId: number,
  mark: boolean,
  setTriggerAction: any,
  setRefreshList: any,
): Promise<any> => {
  /**
   * Decide which endpoint we need to call based
   * on the "mark" property state.
   * Note:
   * mark = true =>    will call /v2/invoice/sample/10000000123123123/markInvoiced
   * mark = false =>   will call /v2/invoice/sample/10000000123123123/unmarkInvoiced
   */
  const url = `${API_URL}/v2/invoice/sample/${invoiceId}/${mark ? 'markInvoiced' : 'unmarkInvoiced'}`;
  /**
   * Send the request to the provided endpoint
   * and check if the response was successful
   * or not.
   */
  return fetcher(url, {
    method: 'PUT',
  }).then((result) => {
    /**
     * Notify the user that the marking
     * was performed.
     */
    setTriggerAction({
      type: mark
        ? ContextActionEnum.INVOICE_MARKED
        : ContextActionEnum.INVOICE_UNMARKED,
      progress: 100,
    });
    // Refresh the list of uploads.
    setRefreshList(Math.floor(Math.random() * 10000));
  });
};
