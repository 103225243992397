import { Copyright } from '@mui/icons-material'
import { Alert, Box, Container, Grid, Snackbar, Toolbar } from '@mui/material'
import { useContext } from 'react';
import ProgressCard from '../common/cards/ProgressCard';
import { ContextActionEnum, GlobalContext } from '../context/GlobalState';
import EditVehicleDialog from './dialogs/EditVehicleDialog/EditVehicleDialog';
import RequestQuotaDialog from './dialogs/RequestQuotaDialog/RequestQuotaDialog';
import FilterMenu, { FILTER_TYPE_ENUM, IFilterItem } from '../common/FilterMenu'
import VehiclesList from './VehiclesList'
import UploadIcon from '@mui/icons-material/Upload'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import RuleIcon from '@mui/icons-material/Rule'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DeleteVehiclesDialog from './dialogs/DeleteVehiclesDialog/DeleteVehiclesDialog';


// Filters
const filters: IFilterItem[] = [
  {
    id: 0,
    name: 'Vehicle filters',
    type: FILTER_TYPE_ENUM.DIVIDER,
  },
  {
    id: 1,
    name: 'Uploaded',
    filter: { status: 'UPLOADED' },
    checked: false,
    active: true,
    icon: <UploadIcon />,
  },
  {
    id: 2,
    name: 'AI Parsed',
    filter: { status: 'AI_PARSED' },
    checked: false,
    active: true,
    icon: <FilterAltIcon />,
  },
  {
    id: 3,
    name: 'Incomplete',
    filter: { status: 'INCOMPLETE' },
    checked: false,
    active: true,
    icon: <RuleIcon />,
  },
  {
    id: 4,
    name: 'Incomplete Resubmit',
    filter: { status: 'INCOMPLETE_RESUBMIT' },
    checked: false,
    active: true,
    icon: <RestartAltIcon />,
  },
  {
    id: 5,
    name: 'Vehicle Audited',
    filter: { status: 'VEHICLE_AUDITED' },
    checked: false,
    active: true,
    icon: <CheckBoxIcon />,
  },
]

function Vehicles() {
  /**
   * Get all the required properties from the
   * global context.
   */
  const {
    modal,
    vehicles,
    triggerAction,
    setTriggerAction,
    setModal,
    total,
    selectedIds,
    setSelectedIds,
    predefinedFilters,
    setPredefinedFilters,
    marketVehicles,
    selectedItem
  } = useContext(GlobalContext);
  // Used to find out if the vehicle details modal should open.
  const hasOneSelected = selectedIds && selectedIds.length === 1;
  const hasNoneSelected = !!!selectedIds.length;
  const shouldOpenVehicleEditModal = (hasOneSelected || hasNoneSelected) &&
    (modal?.type === ContextActionEnum.ADD_VEHICLE || modal?.type === ContextActionEnum.REQUEST_QUOTA_FROM_VEHICLE_FORM);
  // Find the ID of the next vehicle
  const findNextVehicleId = () => {
    if (!hasOneSelected)
      return null
    
    const currentVehicleIndex = vehicles?.findIndex(v => v?.id === selectedIds[0])
    if (currentVehicleIndex < 0)
      return null
    
    const nextVehicle = vehicles[currentVehicleIndex + 1]
    return nextVehicle?.id ?? null
  }
  /**
   * Handle the action to market the selected
   * list of vehicles.
   */
  const handleMarketAction = () => {
    // Close the modal.
    setModal(null);
    // Call the action from the global state to market.
    marketVehicles(selectedIds);
  };

  /**
   * Handle the completed action of sending emails.
   * We need to empty the selected rows and remove
   * the ProgressCard
   */
  const sendComplete = () => {
    // Close the action progress card.
    setTriggerAction(null);
    // De-select the previously selected rows.
    setSelectedIds([]);
    // Refresh the currently shown list of vehicles.
    setPredefinedFilters([...predefinedFilters]);
  }

  const showRequestQuotaDialog = [
    ContextActionEnum.REQUEST_QUOTA,
    ContextActionEnum.REQUEST_QUOTA_FROM_VEHICLE_FORM
  ].includes(modal?.type);

  const onRequestQuotaDialogClose = modal?.type === ContextActionEnum.REQUEST_QUOTA_FROM_VEHICLE_FORM
    ? () => setModal({ type: ContextActionEnum.ADD_VEHICLE })
    : undefined;

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
      }}
    >
      <Toolbar />

      <Container sx={{ mt: 4, mb: 4 }} maxWidth={false}>
        <Grid container spacing={2}>

          {triggerAction &&
            <Grid item xs={12}>
              <ProgressCard />
            </Grid>
          }

          {/* The sidebar for the filters. */}
          <Grid item>
            <FilterMenu filters={filters} />
          </Grid>

          <Grid item xs>
            <VehiclesList />
          </Grid>

        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>

      {/* The Request Quota for Vehicles Dialog. */}
      {showRequestQuotaDialog &&
        <RequestQuotaDialog
          selectedIds={selectedIds}
          setModal={setModal}
          onClose={onRequestQuotaDialogClose}
        />
      }

      {modal && modal.type === ContextActionEnum.DELETE_VEHICLES &&
        <DeleteVehiclesDialog
          setModal={setModal}
          vehicles={selectedIds}
        />
      }

      {/* Add new vehicle dialog. */}
      { shouldOpenVehicleEditModal &&
        <EditVehicleDialog
          setModal={setModal}
          setSelectedIds={setSelectedIds}
          vehicle={selectedIds && selectedIds.length === 1 ? vehicles.find(v => v.id === selectedIds[0]): null}
          nextVehicleId={findNextVehicleId()}
        />
      }

      {triggerAction && triggerAction.progress > -1 &&
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={sendComplete}
        >
          <Alert onClose={sendComplete} severity="success" sx={{ width: '100%' }}>
            {`${triggerAction.type.toString()} completed successfully!`}
          </Alert>
        </Snackbar>
      }

    </Box>
  )
}

export default Vehicles

