import { useContext, useEffect, useState } from 'react'
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import QuotaAnalytics from '../tables/QuotaAnalytics'
import VehicleAnalytics from '../tables/VehicleAnalytics'
import UserAnalytics from '../tables/UserAnalytics'
import { toast } from 'react-hot-toast'
import { GlobalContext } from '../../context/GlobalState'
import { getVehicleAnalytics } from '../api/getVehicleAnalytics'
import { getQuotaAnalytics } from '../api/getQuotaAnalytics'
import { getUserAnalytics } from '../api/getUserAnalytics'

const categories = ['users', 'vehicles', 'quotas'] as const
type CategoryType = typeof categories[number]

const Dashboard = () => {
  const { analytics, setAnalytics } = useContext(GlobalContext)

  const [category, setCategory] = useState<CategoryType>('vehicles')
  const [reloading, setReloading] = useState(false)

  const reloadAnalytics = async () => {
    try {
      setReloading(true)

      let reloadedAnalytics
      switch (category) {
        case 'users':
          reloadedAnalytics = await getUserAnalytics()
          break

        case 'vehicles':
          reloadedAnalytics = await getVehicleAnalytics()
          break

        case 'quotas':
          reloadedAnalytics = await getQuotaAnalytics()
          break

        default:
          throw new Error()
      }

      setAnalytics({ ...analytics, [category]: reloadedAnalytics })
      toast.success(`Reloaded analytics for ${category}`)
    } catch (error) {
      toast.error(`Failed to reload ${category} analytics`)
    } finally {
      setReloading(false)
    }
  }

  const timestamp = analytics?.[category]?.timestamp

  useEffect(() => setReloading(false), [category])

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth={false}>
      <Grid
        container
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          {/* Creating a radio group with the analytics categories. */}
          <Typography mr={4}>Analytics for: </Typography>
          <RadioGroup
            row
            onChange={event =>
              setCategory(event.target.value as CategoryType)
            }
          >
            {categories.map(item => (
              <FormControlLabel
                key={item}
                value={item}
                control={<Radio />}
                label={item}
                checked={item === category}
              />
            ))}
          </RadioGroup>
        </Grid>

        <Grid item display='flex' flexDirection='column' alignItems='end'>
          <Button
            variant='outlined'
            disabled={reloading}
            onClick={reloadAnalytics}
          >
            {reloading
              ? `Reloading ${category} analytics ...`
              : `Reload Analytics for ${category}`}
          </Button>
          <Typography>
            Updated at: <strong>{timestamp}</strong>
          </Typography>
        </Grid>
      </Grid>

      {/* displaying selected analytics */}
      <Grid
        container
        rowSpacing={8}
        display='flex'
        flexDirection='column'
        alignItems='center'
        my={2}
      >
        <Grid item>
          <Typography fontWeight='bold' align='center'>
            {category} analytics
          </Typography>

          {/* Add charts here */}
          {category === 'users' && <UserAnalytics />}
          {category === 'vehicles' && <VehicleAnalytics />}
          {category === 'quotas' && <QuotaAnalytics />}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Dashboard
