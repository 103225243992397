import moment from 'moment';

export const Columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90
  },
  {
    field: 'ownerUsername',
    headerName: 'Owner',
    width: 300,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 160,
    valueGetter: (params: any) => {
      const date = params.row.updatedAt
      return date
        ? moment(date).format('DD/MM/YYYY  hh:mm:ss').toString()
        : null
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created On',
    width: 120,
    valueGetter: (params: any) => {
      const date = params.row.createdAt;
      return date ? moment(date).format('DD/MM/YYYY').toString() : null;
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
  },
  {
    field: 'quotaSales',
    headerName: 'Quota Status',
    width: 130,
    renderCell: (params: any) => params.row.quotaSales && params.row.quotaSales.length && params.row.quotaSales[0].status
  },
  {
    field: 'quotaSaleCreatedAt',
    headerName: 'UBA Submitted On',
    width: 160,
    valueGetter: (params: any) => {
      const date = params?.row?.quotaSales?.[0]?.createdAt
      return date ? moment(date).format('DD/MM/YYYY').toString() : null
    }
  },
  {
    field: 'carbonifyAuditor',
    headerName: 'Prüfer',
    width: 120,
  },
  {
    field: 'firstName',
    headerName: 'Vorname',
    width: 120,
  },
  {
    field: 'lastName',
    headerName: 'Nachname',
    width: 120,
  },
  {
    field: 'street',
    headerName: 'Strasse',
    width: 120,
  },
  {
    field: 'zip',
    headerName: 'PLZ',
    width: 80,
  },
  {
    field: 'city',
    headerName: 'Stadt',
    width: 120,
  },
  {
    field: 'registeredAt',
    headerName: 'Zugelassen (Feld I)',
    width: 120,
    valueGetter: (params: any) => {
      const date = params.row.registeredAt;
      return date ? moment(date).format('DD/MM/YYYY').toString() : null;
    }
  },
  {
    field: 'licensePlate',
    headerName: 'Kennzeichen',
    width: 120,
  },
  {
    field: 'carId',
    headerName: 'FIN',
    width: 120,
  },
  {
    field: 'mainInspectionDate',
    headerName: 'HU Datum',
    width: 120,
  },
  {
    field: 'firstRegisteredAt',
    headerName: 'Erstzulassung',
    width: 120,
    valueGetter: (params: any) => {
      const date = params.row.firstRegisteredAt;
      return date ? moment(date).format('DD/MM/YYYY').toString() : null;
    }
  },
  {
    field: 'manufacturer',
    headerName: 'Hersteller',
    width: 120,
  },
  {
    field: 'model',
    headerName: 'Modell',
    width: 120,
  },
  {
    field: 'vehicleClass',
    headerName: 'Fahrzeugklasse',
    width: 120,
  },
  {
    field: 'bevChecked',
    headerName: 'Elektro',
    width: 90,
  },
  {
    field: 'utmSource',
    headerName: 'UTM Source',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmSource ?? '',
  },
  {
    field: 'utmMedium',
    headerName: 'UTM Medium',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmMedium ?? '',
  },
  {
    field: 'utmCampaign',
    headerName: 'UTM Campaign',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmCampaign ?? '',
  }
];
