import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { Box, Step, StepLabel, Stepper, TextField as MUITextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { GlobalContext, IModalType } from '../../../context/GlobalState';
import { fetchLatestQuotaIds } from '../../api/fetchLatestQuotaIds';
import { markAsSold } from '../../api/markAsSold';
import { IDenseTableRow } from '../../../common/dialog/ResultsTable/ResultsTable';
import { BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import { IWizardStep } from '../../../common/wizard-steps';

const TextField = styled(MUITextField)`
  width: 100px;
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

/**
 * Indicate the type and the progress of
 * the current bulk action being performed.
 */
export interface ISellTriggerAction {
  type: IWizardStep;
  progress: number;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export interface ISellQuotaDialogProps {
  selectedIds: number[];
  modal: IModalType;
  setModal: Function;
  total: number;
}

export interface IField {
  error: { description: string };
  value: number;
}

/**
 * Defines the wizard states that
 * the user needs to follow.
 */
export const SellQuotasStates: IWizardStep[] = [
  { id: 0, name: 'Fill in fields' },
  { id: 1, name: 'Review fields' },
  { id: 2, name: 'Mark as sold' },
  { id: 3, name: 'Finished' },
];

export default function SellQuotasDialog(props: ISellQuotaDialogProps) {
  const { selectedIds } = React.useContext(GlobalContext);

  const [fixedPriceM1, setFixedPriceM1] = useState<any>({
    error: null,
    value: '',
  });
  const [fixedPriceM3, setFixedPriceM3] = useState<any>({
    error: null,
    value: '',
  });
  const [fixedPriceN1, setFixedPriceN1] = useState<any>({
    error: null,
    value: '',
  });
  const [fixedPriceN2, setFixedPriceN2] = useState<any>({
    error: null,
    value: '',
  });
  const [fixedPriceN3, setFixedPriceN3] = useState<any>({
    error: null,
    value: '',
  });
  // We use this to highlight the user selection.
  const [currentState, setCurrentState] = useState<IWizardStep | undefined>(SellQuotasStates.find((s) => s.id === 0));
  // Holds the list of all the rows that represents a vehicle.
  const [rows, setRows] = useState<IDenseTableRow[]>([]);
  // Define the local actions so we know when to show the ProgressCard.
  const [progressTrigger, setProgressTrigger] = useState<ISellTriggerAction>(null);

  /**
   * Create the table rows that we will
   * use to display the status of the
   * following actions.
   */
  useEffect(() => {
    if (props.selectedIds) {
      const all: IDenseTableRow[] = props.selectedIds.map((id: number, index: number) => ({
        order: index + 1,
        quotaId: id,
        fetchStatus: 1,
        sellStatus: 1,
      }));
      setRows(all);
    }
  }, []);

  /**
   * Monitor state changes and perform the
   * required action automatically.
   */
  useEffect(() => {
    if (currentState?.id === 2) {
      props.selectedIds && fetchLatestQuotaIds(rows, setProgressTrigger, setCurrentState);

      markAsSold(
        rows,
        { fixedPriceM1, fixedPriceM3, fixedPriceN1, fixedPriceN2, fixedPriceN3 },
        setProgressTrigger,
        setCurrentState,
        SellQuotasStates[3],
        selectedIds,
      );
    }
  }, [currentState]);

  /**
   * Prepare the field object for the currently
   * provided value.
   * It will prepare the error message as well
   * as the value format check.
   *
   * @param value number
   * @returns IField
   */
  const validator = (value: string): IField => {
    if (!value) {
      return {
        error: { description: 'Please fill in a value' },
        value: null,
      };
    }
    if (isNaN(parseFloat(value))) {
      return {
        error: { description: 'Please enter a valid number' },
        value: null,
      };
    }
    return { error: null, value: parseFloat(value) };
  };
  /**
   * Handle the value changes on all fields.
   *
   * @param field string
   * @param value string
   */
  const handleFieldChange = (fieldStateSetter: Function, value: string) => {
    // Check if the field has validation errors.
    const val = validator(value);
    // Set the state of the current field.
    fieldStateSetter(val);
  };

  /**
   * Handle the manual changes of the current
   * wizard state.
   */
  const handleStepAdvance = (step: number) => {
    setCurrentState(SellQuotasStates.find((s) => s.id === step));
  };

  const variablePrices =
    !fixedPriceM1.value && !fixedPriceM3.value && !fixedPriceN1.value && !fixedPriceN2.value && !fixedPriceN3.value;
  const fixedPrices =
    fixedPriceM1.value && fixedPriceM3.value && fixedPriceN1.value && fixedPriceN2.value && fixedPriceN3.value;
  const canSellQuotas = (variablePrices && !fixedPrices) || (!variablePrices && fixedPrices);

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        {/* The title of the dialog. */}
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => props.setModal(null)}
        >
          {props.modal.type.toString()}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stepper
            activeStep={currentState?.id}
            alternativeLabel
            sx={{ mb: 3 }}
          >
            {SellQuotasStates.map((state) => (
              <Step key={state.id}>
                <StepLabel>{state.name}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* General action description. */}
          <Typography sx={{ ml: 4, mb: 4, fontWeight: 'bold' }}>
            Please fill in either variable price fields or fixed price fields.
          </Typography>

          <Typography sx={{ ml: 4, mt: 3 }}>Fixed Prices.</Typography>
          <Box
            component="form"
            display="flex"
            flexWrap="wrap"
            justifyContent=""
            sx={{
              ml: 4,
              '& .MuiTextField-root': { m: 1, width: '25ch' },
              width: 500,
            }}
          >
            <TextField
              error={fixedPriceM1.error}
              type="number"
              id="fixedPriceM1"
              label="Fixed Price M1"
              disabled={!!currentState?.id && currentState.id > 1}
              defaultValue={fixedPriceM1.value}
              helperText={fixedPriceM1.error?.description}
              onChange={(event) => handleFieldChange(setFixedPriceM1, event.target.value)}
              variant="standard"
            />

            <TextField
              error={fixedPriceM3.error}
              type="number"
              id="fixedPriceM3"
              label="Fixed Price M3"
              disabled={!!currentState?.id && currentState.id > 1}
              defaultValue={fixedPriceM3.value}
              helperText={fixedPriceM3.error?.description}
              onChange={(event) => handleFieldChange(setFixedPriceM3, event.target.value)}
              variant="standard"
            />

            <TextField
              error={fixedPriceN1.error}
              type="number"
              id="fixedPriceN1"
              label="Fixed Price N1"
              disabled={!!currentState?.id && currentState.id > 1}
              defaultValue={fixedPriceN1.value}
              helperText={fixedPriceN1.error?.description}
              onChange={(event) => handleFieldChange(setFixedPriceN1, event.target.value)}
              variant="standard"
            />

            <TextField
              error={fixedPriceN2.error}
              type="number"
              id="fixedPriceN2"
              label="Fixed Price N2"
              disabled={!!currentState?.id && currentState.id > 1}
              defaultValue={fixedPriceN2.value}
              helperText={fixedPriceN2.error?.description}
              onChange={(event) => handleFieldChange(setFixedPriceN2, event.target.value)}
              variant="standard"
            />

            <TextField
              error={fixedPriceN3.error}
              type="number"
              id="fixedPriceN3"
              label="Fixed Price N3"
              disabled={!!currentState?.id && currentState.id > 1}
              defaultValue={fixedPriceN3.value}
              helperText={fixedPriceN3.error?.description}
              onChange={(event) => handleFieldChange(setFixedPriceN3, event.target.value)}
              variant="standard"
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent=""
            sx={{ mt: 3, '& .MuiTextField-root': { m: 1, width: '25ch' } }}
          ></Box>
        </DialogContent>
        <DialogActions>
          {currentState?.id === 0 && canSellQuotas && (
            <Button onClick={() => handleStepAdvance(1)}>Review fields</Button>
          )}
          {currentState?.id === 1 && <Button onClick={() => handleStepAdvance(2)}>Mark as sold</Button>}
          {currentState?.id === 3 && <Button onClick={() => props.setModal(null)}>Done</Button>}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
