import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'

/**
 * It generates invoices for all the quota sales that have not been invoiced yet
 * @param  - `limit` - the number of invoices to generate
 * @param  - `skip` - the number of invoices to skip
 */
export const generateInvoices = ({
  limit,
  skip,
}: {
  limit: number
  skip: number
}) => {
  const url = `${API_URL}/quota-sales/generateInvoice?take=${limit}&skip=${skip}`
  return fetcher(url, {
    method: 'POST',
  })
}
