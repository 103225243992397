import { FC } from 'react';
import { Typography } from '@mui/material';

export const DisabledUsernameColumn: FC = () => {
  return (
    <Typography
      variant="h6"
      color="error"
      sx={{
        width: '100%',
        textAlign: 'center',
        border: 3,
        borderColor: 'error.main',
        borderRadius: '8px',
        backgroundColor: '#ffffff'
      }}
    >
      Inactive
    </Typography>
  );;
}
