import { Button } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { ContextScopeEnum, ContextActionEnum } from '../../context/GlobalState'
import { IFilterItem, FILTER_TYPE_ENUM } from '../../common/FilterMenu'

/**
 * Trigger the Quota Sale decline process for
 * the selected vehicles.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds number
 * @returns boolean
 */
const DeclineQuotaSalesAction = (props: {
  predefinedFilters: IFilterItem[]
  selectedIds: number[]
  setModal: Function
  context: ContextScopeEnum
}) => {
  const hasRequiredContext = props.context === ContextScopeEnum.QUOTAS
  const hasPredefinedFilters = !!props?.predefinedFilters?.length
  const isUBASubmitted = props?.predefinedFilters?.find(
    filter => filter?.type === FILTER_TYPE_ENUM.UBA_SUBMITTED
  )
  const show =
    hasPredefinedFilters &&
    !!props.selectedIds.length &&
    isUBASubmitted &&
    hasRequiredContext

  if (show) {
    return (
      <Button
        color='inherit'
        onClick={() =>
          props.setModal({
            type: ContextActionEnum.SHOW_DIALOG_DECLINE_QUOTA_SALES,
          })
        }
      >
        <CancelIcon />
        &nbsp;Decline quotas
      </Button>
    )
  }
  return <></>
}

export default DeclineQuotaSalesAction
