import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useState, forwardRef } from 'react';

/**
 * Password text field input
 */
export const TextFieldPassword: React.FC<TextFieldProps> = forwardRef(
  function TextFieldPassword(props, ref) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
      setShowPassword((prev) => !prev);
    };

    return (
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                sx={{
                  opacity: 0.5,
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
        type={showPassword ? 'text' : 'password'}
        ref={ref}
      />
    );
  }
);
