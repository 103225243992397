import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import { BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import { Dialog, styled } from '@mui/material';
import { ContextActionEnum } from '../../../context/GlobalState';
import { RequestQuotaTable } from './RequestQuotaTable';

export interface IBulkMarketDialogProps {
  selectedIds: number[];
  setModal: Function;
  onClose?: () => any;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

/**
 * It renders a dialog with a table of vehicles and their current quota
 * @param {IBulkMarketDialogProps} props - IBulkMarketDialogProps
 * @returns A function that returns a div with a BootstrapDialog component.
 */
export default function RequestQuotaDialog(props: IBulkMarketDialogProps) {
  const onClose = props.onClose ?? (() => props.setModal(null));

  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby={ContextActionEnum.REQUEST_QUOTA}
        open={true}
      >
        <BootstrapDialogTitle
          id={ContextActionEnum.REQUEST_QUOTA}
          onClose={onClose}
        >
          {ContextActionEnum.REQUEST_QUOTA}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <RequestQuotaTable vehicleIds={props.selectedIds} />
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}
