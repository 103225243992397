import { Button } from '@mui/material'
import { ContextActionEnum, ContextScopeEnum } from '../../context/GlobalState'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'

/**
 * It renders a button that opens a modal to edit an invoice template
 * @param props - {
 * @returns A button that is only visible if the following conditions are met:
 * - there is exactly one invoice template selected
 * - the context is set to invoice templates
 */
const EditInvoiceTemplateAction = (props: {
  selectedIds: number[]
  setModal: Function
  context: ContextScopeEnum
}) => {
  const hasOneSelection = props?.selectedIds?.length === 1
  const hasRequiredContext =
    props.context === ContextScopeEnum.INVOICE_TEMPLATES
  const show = hasOneSelection && hasRequiredContext

  if (show) {
    return (
      <Button
        color='inherit'
        onClick={() =>
          props.setModal({ type: ContextActionEnum.EDIT_INVOICE_TEMPLATE })
        }
      >
        <AutoFixHighIcon />
        &nbsp;{'Vorlage bearbeiten'}
      </Button>
    )
  }
  return <></>
}

export default EditInvoiceTemplateAction
