import { useCallback } from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useDropzone } from 'react-dropzone';
import { IconUpload } from './icons/IconUpload';

type Props = {
  text: string;
  name: string;
  preview: string;
  setFile: (file: File) => void;
  previewSize?: number[];
};

/**
 * Reusable dropzone component used for file uploads
 */
export const FileDropzone: React.FC<Props> = ({
  text,
  name,
  preview = '',
  setFile,
  previewSize,
}) => {
  const theme = useTheme();

  // Callback invoked when drop/or upload happens
  const onDrop = useCallback((acceptedFiles: any) => {
    // acceptedFiles is always an array
    const [file] = acceptedFiles;

    setFile(file);
  }, []);

  // Actual dropzone setup.
  // ''
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/heif': [],
      'image/heic': [],
      'image/gif': [],
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'image/tiff': [],
      'application/pdf': [],
    },
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        flex: '1',
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow: 2,
        cursor: 'pointer',
        p: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transition: 'border .3s ease',
        border: isDragActive
          ? `2px solid ${theme.palette.primary.main}`
          : '2px solid transparent',
        '&:hover': {
          border: `2px solid ${theme.palette.primary.main}`,
        },
        '&:first-child': {
          marginRight: theme.spacing(4),
        },
        [theme.breakpoints.down('md')]: {
          '&:first-child': {
            marginRight: 0,
          },
          marginBottom: theme.spacing(4),
        },
      }}
    >
      <input {...getInputProps()} id={name} name={name} />
      {preview ? (
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={preview}
            alt={name}
            sx={{
              width: (previewSize && previewSize[0]) || 334,
              height: (previewSize && previewSize[1]) || 204,
              objectFit: "contain"
            }}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '50%',
              padding: theme.spacing(1),
              width: '60px',
              display: 'flex',
              height: '60px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconUpload />
          </Box>
          <Typography
            sx={{
              backgroundColor: '#fff',
              fontWeight: 'bold',
              fontSize: '20px',
              marginTop: theme.spacing(4),
            }}
          >
            {text}
          </Typography>
          <Typography
            sx={{
              backgroundColor: '#fff',
              fontWeight: 'bold',
              // fontSize: '16px',
              marginTop: theme.spacing(4),
              color: theme.palette.primary.main,
              textTransform: 'uppercase',
              paddingX: theme.spacing(3),
              paddingY: theme.spacing(1),
              border: `1px solid ${
                theme?.palette?.grey
                  ? (theme.palette.grey as any)[100]
                  : ''
              }`,
            }}
          >
            Hier Hochladen
          </Typography>
        </>
      )}
    </Box>
  );
};
