import { useLayoutEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material"
import { useLogout } from '../../../hooks/useLogout';

const useWindowDimentions = () => {
  const [dimentions, setDimentions] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    const updateDimentions = () => {
      setDimentions({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateDimentions);
    updateDimentions();
    return () => window.removeEventListener("resize", updateDimentions);
  }, []);
  return dimentions;
};

const NotAdmin = () => {
  const { width, height } = useWindowDimentions()
  const logout = useLogout()
  logout()

  return (
    <Box sx={{ width, height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Box>
  )
}

export default NotAdmin