import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'
import { getVehiclesByUsernames } from '../../vehicles-admin/api/getVehiclesByUsernames'

/**
 * It takes a string of usernames, and returns a promise that resolves to an array of user objects
 * @param {string} [usernames] - A comma separated list of usernames to search for.
 * @returns An array of users.
 */
export const searchUsersPromise = async (usernames?: string): Promise<any> => {
  // Add the required query params.
  const data = {
    usernames: usernames.split(',').map(username => username.trim()),
  }
  // Build up the complete url
  const url = `${API_URL}/users/bulkSearch`

  const users = await fetcher(url, {
    method: 'POST',
    body: JSON.stringify(data),
  })

  /**
   * Now get the list of vehicles for the
   * found usernames.
   */
  let vehiclesByUsernames: any = null;
  if (!!data?.usernames?.length) {
    vehiclesByUsernames = await getVehiclesByUsernames(data.usernames)
  }

  /* Mapping the users to their vehicles. */
  const result = users.data.map((user: any) => ({
    ...user, vehicleRegistrations: vehiclesByUsernames?.data?.find((vehicle: any) => vehicle.username === user.username)?.vehicleRegistrations || []
  }))

  /* Returning the original users object with the data property replaced with the new data. */
  return { ...users, data: result }
}