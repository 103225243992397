import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { Fragment, useContext } from 'react';
import { ContextScopeEnum, GlobalContext } from './context/GlobalState';
import PersonIcon from '@mui/icons-material/Person';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LogoutIcon from '@mui/icons-material/Logout';
import AnalyticsIcon from '@mui/icons-material/Analytics'
import { Typography, Divider } from '@mui/material';
import { useLogout } from '../../hooks/useLogout';
import { canUseFeature } from '../../hooks/canUseFeature';
import getRole from '../../utils/getRole';

export const SidebarMenu = (props: { currentScope: ContextScopeEnum, setCurrentScope: Function }) => {
  // Get a few props from the global scope.
  const { currentScope } = useContext(GlobalContext);

  const setNewContext = (context: ContextScopeEnum): void => {
    props.setCurrentScope(context);
  }

  const logout = useLogout();

  return (

    <React.Fragment>

      <Fragment>
        <li>
          <Typography
            sx={{ mt: 2, ml: 2 }}
            color="text.secondary"
            display="block"
            variant="caption"
          >Verwaltung</Typography>
        </li>
        <Divider component="li" />
      </Fragment>

      {getRole() === 'admin' && <ListItemButton
        onClick={() => setNewContext(ContextScopeEnum.USERS)}
        selected={currentScope === ContextScopeEnum.USERS}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.USERS ? '#1976d2' : '' }}>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Benutzer" />
      </ListItemButton>}

      {getRole() === 'admin' && <ListItemButton
        onClick={() => setNewContext(ContextScopeEnum.TEMPLATES)}
        selected={currentScope === ContextScopeEnum.TEMPLATES}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.TEMPLATES ? '#1976d2' : '' }}>
          <HistoryEduIcon />
        </ListItemIcon>
        <ListItemText primary="Vorlagen" />
      </ListItemButton>}

      {getRole() === 'admin' && <ListItemButton
        onClick={() => setNewContext(ContextScopeEnum.VEHICLES)}
        selected={currentScope === ContextScopeEnum.VEHICLES}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.VEHICLES ? '#1976d2' : '' }}>
          <DirectionsCarIcon />
        </ListItemIcon>
        <ListItemText primary="Fahrzeuge" />
      </ListItemButton>}

      {getRole() === 'admin' && <ListItemButton
        onClick={() => setNewContext(ContextScopeEnum.QUOTAS)}
        selected={currentScope === ContextScopeEnum.QUOTAS}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.QUOTAS ? '#1976d2' : '' }}>
          <RequestQuoteIcon />
        </ListItemIcon>
        <ListItemText primary="Quotas" />
      </ListItemButton>}

      {getRole() === 'admin' && <ListItemButton
        onClick={() => setNewContext(ContextScopeEnum.INVOICING)}
        selected={currentScope === ContextScopeEnum.INVOICING}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.INVOICING ? '#1976d2' : '' }}>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Rechnungen" />
      </ListItemButton>}

      {getRole() === 'admin' && <ListItemButton
        onClick={() => setNewContext(ContextScopeEnum.INVOICE_TEMPLATES)}
        selected={currentScope === ContextScopeEnum.INVOICE_TEMPLATES}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.INVOICE_TEMPLATES ? '#1976d2' : '' }}>
          <AutoFixHighIcon />
        </ListItemIcon>
        <ListItemText primary="Rechnungen Vorlagen" />
      </ListItemButton>}

      {getRole() === 'admin' && <ListItemButton
        onClick={() => setNewContext(ContextScopeEnum.PDF_UPLOADS)}
        selected={currentScope === ContextScopeEnum.PDF_UPLOADS}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.PDF_UPLOADS ? '#1976d2' : '' }}>
          <PictureAsPdfIcon />
        </ListItemIcon>
        <ListItemText primary="PDF Uploads" />
      </ListItemButton>}

      {['admin', 'analytics'].includes(getRole()) && canUseFeature('Analytics') && <ListItemButton
        onClick={() => setNewContext(ContextScopeEnum.ANALYTICS)}
        selected={currentScope === ContextScopeEnum.ANALYTICS}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.ANALYTICS ? '#1976d2' : '' }}>
          <AnalyticsIcon />
        </ListItemIcon>
        <ListItemText primary="Analytics" />
      </ListItemButton>}

      <Divider sx={{ my: 1 }} />

      <ListItemButton
        onClick={logout}
      >
        <ListItemIcon sx={{ color: '#F00' }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>

    </React.Fragment>
  )
}
