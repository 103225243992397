import { Box, Typography, useTheme } from '@mui/material'

/**
 * It's a function that returns a Box component with some styling and two Typography components
 * @param props - { name: string; value: string | number }
 * @returns A React component
 */
const SummaryItem = (props: { name: string; value: string | number }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[300],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '24px',
        m: 1,
        py: 2,
        px: 4,
        minWidth: '200px',
      }}
    >
      <Typography align='center'>{props.name}</Typography>
      <hr />
      <Typography align='center'>{props.value}</Typography>
    </Box>
  )
}

export default SummaryItem
