import { API_URL } from '../../../../constants/api';
import { stringify } from 'query-string';
import { fetcher } from '../../../../utils/fetcher';
import { ContextActionEnum } from '../../context/GlobalState';

/**
 * Market the vehicle with the provided id.
 *
 * @param id number
 * @returns Promise<any>
 */
const marketVehicle = (id: number): Promise<any> => {
  if (!id) return new Promise((resolve) => resolve(null));
  const url = `${API_URL}/vehicle-registrations/marketable/${id}/market`;
  return fetcher(`${url}`, {
    method: 'POST',
  }).then();
};

/**
 * Market all the vehicle available for marketing.
 *
 * @returns Promise<any>
 */
const marketAllVehicles = (
  count: number,
  lastRecordCreatedAt: string
): Promise<any> => {
  /**
   * Make sure we have all the params before
   * proceeding otherwise the operation will
   * fail.
   */
  if (!count || !lastRecordCreatedAt) {
    return new Promise((resolve) => resolve(null));
  }
  /**
   * Build the query params so we can construct the
   * url that we require.
   */
  const queryParams = {
    filter: JSON.stringify({}),
    ownerFilter: JSON.stringify({}),
    count: JSON.stringify(count),
    lastRecordCreatedAt: lastRecordCreatedAt,
  };
  const stringParams = stringify(queryParams);
  const url = `${API_URL}/vehicle-registrations/marketable/market?${stringParams}`;
  // console.log(url);
  return fetcher(`${url}`, {
    method: 'POST',
  }).then();
};

/**
 * Make an API request using the provided url.
 * This endpoint is for creating Quota Sales from the marketable Vehicle Registrations. It is a bulk action that supports the same filter and ownerFilter as GET request for marketable Vehicle Registrations.
 *
 * Additionally, to not create Quota Sales for unintended Vehicle Registrations and prevent race conditions it uses 2 checksum fileds:
 * count -> Total count of Vehicle Registrations expected to be processed
 * lastRecordCreatedAt -> The record that has the latest creation date inside all the Vehicle Registrations that are expected to be processed
 * Response codes for non-successful requests:
 *
 */
export const marketVehiclesPromise = (
  ids: number[],
  count: number = null,
  lastRecordCreatedAt: string = null,
  triggerActionCallback: Function
): Promise<any> | undefined => {
  /**
   * Decide which endpoint we have to
   * call.
   */
  if (ids && ids.length) {
    (async () => {
      let current = 1;
      const allPromises = ids.map((id) =>
        marketVehicle(id).then(() =>
          triggerActionCallback({
            type: ContextActionEnum.REQUEST_QUOTA,
            progress: (current++ * 100) / ids.length,
          })
        )
      );
      await Promise.all(allPromises);
    })();
  } else if (count && lastRecordCreatedAt) {
    return marketAllVehicles(count, lastRecordCreatedAt);
  }
  // Handle errors related to wrong status (401...).
  // error && handleStatusError(error);
};
