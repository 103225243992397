enum ValidatorTypes {
  REQUIRED,
  MIN_LENGTH,
  MAX_LENGTH,
  IS_EMAIL,
  HAS_SPECIAL_CHARS,
  MATCH_VALUE,
}

const validationMessages = [
  { type: ValidatorTypes.REQUIRED, message: 'Dies ist ein Pflichtfeld.' },
  { type: ValidatorTypes.MIN_LENGTH, message: 'Please provide the minimum length.' },
  { type: ValidatorTypes.MAX_LENGTH, message: 'Please provide the maximum length.' },
  { type: ValidatorTypes.IS_EMAIL, message: 'Bitte eine gültige E-Mail-Adresse angeben.' },
  { type: ValidatorTypes.HAS_SPECIAL_CHARS, message: 'mind. 12 Zeichen, Groß- und Kleinbuchstaben, 1 Zahl und 1 Sonderzeichen.' },
  { type: ValidatorTypes.MATCH_VALUE, message: '{this} should match the value of {that}' },
];

/**
 * Check if the field has any value at all.
 * 
 * @param value any
 * @returns string
 */
export const isRequired = (value: any) => {
  // Fetch the validation message in case we need it.
  const message = validationMessages.find(vm => vm.type === ValidatorTypes.REQUIRED).message;
  return !!value ? null : message;
}

/**
 * Check if the provided values length is more or
 * equal than 3 characters.
 * 
 * @param value string
 * @param min number
 * @returns string
 */
export const minLength = (min = 2) => {
  return (value: string) => {
    const message = validationMessages.find(vm => vm.type === ValidatorTypes.MIN_LENGTH).message;
    return value.length >= min ? null : message;
  }
}

/**
 * Check if the provided value length is within
 * the maximum of 20 characters.
 * 
 * @param value string
 * @param max number
 * @returns string
 */
export const maxLength = (max = 20) => {
  return (value: string) => {
    const message = validationMessages.find(vm => vm.type === ValidatorTypes.MAX_LENGTH).message;
    return value.length <= max ? null : message;
  }
}

/**
 * Check if the provided value is a
 * valid email address.
 * 
 * @param value string
 * @returns string
 */
export const isEmail = (value: string) => {
  const expression = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  const message = validationMessages.find(vm => vm.type === ValidatorTypes.IS_EMAIL).message;
  return expression.test(value) ? null : message;
}

/**
 * Check if the current value matches the value
 * of the initially provided field name.
 * 
 * @param fieldName string
 * @returns string
 */
export const matchField = (fieldName: string) => {
  const message = validationMessages.find(vm => vm.type === ValidatorTypes.IS_EMAIL).message;
  return (value: string) => {
    // TODO: get the field value of the provided field name.
    const otherFieldValue: any = null;
    // Replace the placeholders inside the message accordingly.
    
    // TODO: compare the values of both fields.
    return value === otherFieldValue ? null : message
  }
}

/**
 * Check if the provided value string contains at
 * least a number and a special character.
 * 
 * @param value string
 * @returns string
 */
export const hasNumberAndSpecialCharacter = (value: string) => {
  // Expression to check if the string has at least a number and a special character.
  const expression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  const message = validationMessages.find(vm => vm.type === ValidatorTypes.HAS_SPECIAL_CHARS).message;
  return expression.test(value) ? null : message;
}

export const validate = (value: any, validators: any[]): string => {
  /**
   * Parse through the validators attached to
   * the current field.
   */
  const validationResults = validators.map(v => v(value));
  /**
   * Filter out the validators that didn't return
   * any validation message.
   */
  const messages = validationResults.filter(vr => !!vr);
  /**
   * Return only the first validation error
   * message if found otherwise return null.
   */
  return !!messages.length ? messages[0] : null;
}