import { Copyright, Report } from '@mui/icons-material'
import { Alert, Box, Container, Grid, Snackbar, Toolbar } from '@mui/material'
import { useContext } from 'react'
import ProgressCard from '../common/cards/ProgressCard'
import { ContextActionEnum, GlobalContext } from '../context/GlobalState'
import FilterMenu, { FILTER_TYPE_ENUM, IFilterItem } from '../common/FilterMenu'
import QuotasList from './QuotasList'
import PersonIcon from '@mui/icons-material/Person'
import PublishIcon from '@mui/icons-material/Publish'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import SellIcon from '@mui/icons-material/Sell'
import ReceiptIcon from '@mui/icons-material/Receipt'
import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import PaidIcon from '@mui/icons-material/Paid'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import SubmitQuotaSalesDialog from './dialogs/SubmitQuotaSalesDialog/SubmitQuotaSalesDialog'
import ApproveQuotaSalesDialog from './dialogs/ApproveQuotaSalesDialog/ApproveQuotaSalesDialog'
import DeclineQuotaSalesDialog from './dialogs/DeclineQuotaSalesDialog/DeclineQuotaSalesDialog'
import SellQuotasDialog from './dialogs/SellQuotasDialog/SellQuotasDialog'
import PayQuotaSalesDialog from './dialogs/PayQuotaSalesDialog/PayQuotaSalesDialog'
import CreateUBASubmissionDialog from './dialogs/CreateUBASubmissionDialog'
import GenerateInvoicesDialog from './dialogs/GenerateInvoicesDialog/GenerateInvoicesDialog'
import SendInvoicesDialog from './dialogs/SendInvoicesDialog/SendInvoicesDialog'
import { ErrorIcon } from 'react-hot-toast'

// Filters
const yearFilters: IFilterItem[] = [
  {
    id: 0,
    name: 'Quota year filter',
    type: FILTER_TYPE_ENUM.DIVIDER,
  },
  {
    id: 1,
    name: '2022',
    filter: { uba_year: 2022 },
    type: FILTER_TYPE_ENUM.UBA_2022,
    checked: false,
    active: true,
    icon: <CalendarMonthIcon />,
  },
  {
    id: 2,
    name: '2023',
    filter: { uba_year: 2023 },
    type: FILTER_TYPE_ENUM.UBA_2023,
    checked: false,
    active: true,
    icon: <CalendarMonthIcon />,
  },
]

// Filters
const filters: IFilterItem[] = [
  {
    id: 0,
    name: 'Quota status filters',
    type: FILTER_TYPE_ENUM.DIVIDER,
  },
  {
    id: 1,
    name: 'User Requested',
    filter: { status: 'USER_REQUESTED' },
    type: FILTER_TYPE_ENUM.USER_REQUESTED,
    checked: false,
    active: true,
    icon: <PersonIcon />,
  },
  {
    id: 2,
    name: 'UBA Submitted',
    filter: { status: 'UBA_SUBMITTED' },
    type: FILTER_TYPE_ENUM.UBA_SUBMITTED,
    checked: false,
    active: true,
    icon: <PublishIcon />,
  },
  {
    id: 3,
    name: 'UBA Approved',
    filter: { status: 'UBA_APPROVED' },
    type: FILTER_TYPE_ENUM.UBA_APPROVED,
    checked: false,
    active: true,
    icon: <CheckCircleIcon />,
  },
  {
    id: 4,
    name: 'UBA Failed',
    filter: { status: 'UBA_FAILED' },
    type: FILTER_TYPE_ENUM.UBA_FAILED,
    checked: false,
    active: true,
    icon: <Report />,
  },
  {
    id: 5,
    name: 'Sold',
    filter: { status: 'SOLD' },
    type: FILTER_TYPE_ENUM.SOLD,
    checked: false,
    active: true,
    icon: <SellIcon />,
  },
  {
    id: 6,
    name: 'Invoice Generated',
    filter: { status: 'INVOICE_GENERATED' },
    type: FILTER_TYPE_ENUM.INVOICE_GENERATED,
    checked: false,
    active: true,
    icon: <ReceiptIcon />,
  },
  {
    id: 7,
    name: 'Invoice Sent',
    filter: { status: 'INVOICE_SENT' },
    type: FILTER_TYPE_ENUM.INVOICE_SENT,
    checked: false,
    active: true,
    icon: <AttachEmailIcon />,
  },
  {
    id: 8,
    name: 'Paid Out',
    filter: { status: 'PAID_OUT' },
    type: FILTER_TYPE_ENUM.PAID_OUT,
    checked: false,
    active: true,
    icon: <PaidIcon />,
  },
  {
    id: 9,
    name: 'Rejected',
    filter: { status: 'REJECTED' },
    type: FILTER_TYPE_ENUM.REJECTED,
    checked: false,
    active: true,
    icon: <CancelIcon />,
  },
]

function Quotas() {
  /**
   * Get all the required properties from the
   * global context.
   */
  const {
    modal,
    quotas,
    triggerAction,
    setTriggerAction,
    setModal,
    total,
    selectedIds,
    setSelectedIds,
    predefinedFilters,
    setPredefinedFilters,
    // marketQuotas,
    selectedItem,
  } = useContext(GlobalContext)
  // Used to find out if the vehicle details modal should open.
  const hasOneSelected = selectedIds && selectedIds.length === 1
  const hasNoneSelected = !!!selectedIds.length
  const shouldOpenQuotaEditModal =
    (hasOneSelected || hasNoneSelected) &&
    modal &&
    modal.type === ContextActionEnum.ADD_VEHICLE
  // Find the ID of the next vehicle
  const findNextQuotaId = () => {
    if (!hasOneSelected) return null

    const currentQuotaIndex = quotas?.findIndex(v => v?.id === selectedIds[0])
    if (currentQuotaIndex < 0) return null

    const nextQuota = quotas[currentQuotaIndex + 1]
    return nextQuota?.id ?? null
  }
  /**
   * Handle the action to market the selected
   * list of quotas.
   */
  // const handleMarketAction = () => {
  //   // Close the modal.
  //   setModal(null);
  //   // Call the action from the global state to market.
  //   marketQuotas(selectedIds);
  // };

  /**
   * Handle the completed action of sending emails.
   * We need to empty the selected rows and remove
   * the ProgressCard
   */
  const sendComplete = () => {
    // Close the action progress card.
    setTriggerAction(null)
    // De-select the previously selected rows.
    setSelectedIds([])
    // Refresh the currently shown list of quotas.
    setPredefinedFilters([...predefinedFilters])
  }

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: theme =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />

      <Container sx={{ mt: 4, mb: 4 }} maxWidth={false}>
        <Grid container spacing={2}>
          {triggerAction && (
            <Grid item xs={12}>
              <ProgressCard />
            </Grid>
          )}

          {/* The sidebar for the filters. */}
          <Grid item>
            <FilterMenu filters={yearFilters} />
            <FilterMenu filters={filters} />
          </Grid>

          <Grid item xs>
            <QuotasList />
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>

      {/* The Generate Invoices Dialog. */}
      {modal?.type === ContextActionEnum.GENERATE_INVOICE && (
        <GenerateInvoicesDialog selectedIds={selectedIds} setModal={setModal} />
      )}

      {/* The Send Invoices Dialog. */}
      {modal?.type === ContextActionEnum.SEND_INVOICE && (
        <SendInvoicesDialog selectedIds={selectedIds} setModal={setModal} />
      )}

      {/* The Submit Quotas Dialog. */}
      {modal && modal.type === ContextActionEnum.SUBMIT_QUOTAS && (
        <SubmitQuotaSalesDialog
          selectedIds={selectedIds}
          setModal={setModal}
          total={total}
        />
      )}

      {/* The Approve Quota Sales Dialog. */}
      {selectedIds &&
        selectedIds.length > 0 &&
        modal &&
        modal.type === ContextActionEnum.SHOW_DIALOG_APPROVE_QUOTA_SALES && (
          <ApproveQuotaSalesDialog
            selectedIds={selectedIds}
            setModal={setModal}
            total={total}
          />
        )}

      {/* The Approve Quota Sales Dialog. */}
      {selectedIds &&
        selectedIds.length > 0 &&
        modal &&
        modal.type === ContextActionEnum.SHOW_DIALOG_DECLINE_QUOTA_SALES && (
          <DeclineQuotaSalesDialog
            selectedIds={selectedIds}
            setModal={setModal}
            total={total}
          />
        )}

      {/* The Approve Quota Sales Dialog. */}
      {modal && modal.type === ContextActionEnum.BULK_SELL_QUOTAS && (
        <SellQuotasDialog
          selectedIds={selectedIds}
          modal={modal}
          setModal={setModal}
          total={total}
        />
      )}

      {/* The CREATE UBA SUBMISSION dialog. */}
      {modal && modal.type === ContextActionEnum.CREATE_UBA_SUBMISSION && (
        <CreateUBASubmissionDialog modal={modal} setModal={setModal} />
      )}

      {/* The Pay Quota Sales Dialog. */}
      {selectedIds &&
        selectedIds.length > 0 &&
        modal &&
        modal.type === ContextActionEnum.MARK_PAID_OUT && (
          <PayQuotaSalesDialog
            selectedIds={selectedIds}
            setModal={setModal}
            total={total}
          />
        )}

      {triggerAction && triggerAction.progress > -1 && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={sendComplete}
        >
          <Alert
            onClose={sendComplete}
            severity='success'
            sx={{ width: '100%' }}
          >
            {`${triggerAction.type.toString()} completed successfully!`}
          </Alert>
        </Snackbar>
      )}
    </Box>
  )
}

export default Quotas
