import { Button } from "@mui/material";

interface Props {
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  label: string;
  disabled?: boolean;
}

const FormButton: React.FC<Props> = ({ type, size, label, disabled }) => {
  return (
    <Button size={size || 'large'} variant="contained" type={type || 'submit'} disabled={disabled}>
      {`${label} `}
    </Button>
  )
}

export default FormButton;