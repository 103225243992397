type UserType = 'b2b' | 'b2c' | 'all'
type StatsType = Record<UserType, number>
export type UserAnalyticsType = {
  total: StatsType
  monthly: {
    month: string
    data: StatsType
  }[]
}

const UserAnalytics = () => <strong>No Anaytics for Users</strong>

export default UserAnalytics