import { WHITELABEL_TENANT } from '../constants/api'
import { featureConfig, IFeatures } from '../constants/featureConfig'

/**
 * exactly the same as useFeature() hook
 * but it can be used in non functional components
 */
export const canUseFeature = (key: IFeatures): boolean => {
  const tenantConfig = featureConfig[WHITELABEL_TENANT]
  return tenantConfig.includes(key)
}