import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It makes a GET request to the `/vehicle-registrations/:vehicleId` endpoint and returns the response
 * @returns A promise that resolves to the data returned from the API.
 */
export const getVehicleById = (vehicleId: number): Promise<any> => {
  // Build up the complete url
  const url = `${API_URL}/vehicle-registrations/${vehicleId}`;

  return fetcher(url, {
    method: 'GET',
  })
    .then((data) => data)
    .catch((error) => {
      return error;
    });
};