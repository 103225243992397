import { FC, ComponentProps, MutableRefObject, Dispatch, useRef, useState, useEffect } from 'react';
import { Document, Page, pdfjs, PDFPageProxy  } from 'react-pdf';
import { Box } from '@mui/material';
import { PdfPreviewPagination } from './pdf-preview-pagination';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { LoadingSpinner } from '../../Admin/common/loading-spinner';

// setup the React PDF JS worker (see https://github.com/wojtekmaj/react-pdf#standard-browserify-esbuild-and-others)
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export type PdfPreviewProps = ComponentProps<typeof Box> & {
  pagination?: 'auto' | 'fixed' | 'never';
  hideSinglePagePagination?: boolean;
  file?: string | File;
};

export const PdfPreview: FC<PdfPreviewProps> = ({ file, pagination = 'auto', hideSinglePagePagination, sx, ...props }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [isPaginationVisible, setPaginationVisible] = useState(false);
  const [page, setPage] = useState<PDFPageProxy>();
  const [pageHeight, setPageHeight] = useState<number>();
  
  const containerRef = useRef<HTMLElement | undefined>();
  const canDisplayPagination = useRef(false);
  
  useEffect(() => {
    setPage(undefined);
    setPageHeight(undefined);
  }, [file]);

  useEffect(() => {
    if (page) {
      fitPdfPageIntoContainer(page, containerRef, setPageHeight);
    }
  }, [page]);

  if (!file) {
    return null;
  }

  const setPageAndResetSize: Dispatch<React.SetStateAction<number>> = (newPageNumber) => {
    setPageNumber(newPageNumber);
    setPageHeight(undefined);
  }

  const showPagination = pagination === 'auto'
    ? () => {
      if (canDisplayPagination.current) {
        setPaginationVisible(true)
      }
     }
    : undefined;
  const hidePagination = pagination === 'auto' ? () => setPaginationVisible(false): undefined;

  return (
    <Box
      component="div"
      ref={containerRef}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        '& .react-pdf__Document': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        },
        '& .react-pdf__Page': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        },
        ...sx
      }}
      onMouseEnter={showPagination}
      onMouseLeave={hidePagination}
      {...props}
    >
      <Document
        file={file}
        loading={<LoadingSpinner />}
        onLoadSuccess={({ numPages }) => {
          setPageNumber(1);
          setNumPages(numPages);
          canDisplayPagination.current = pagination !== 'never' && (numPages > 1 || !hideSinglePagePagination);
          setPaginationVisible(pagination === 'fixed' && canDisplayPagination.current);
        }}
      >
        <Page
          pageNumber={pageNumber}
          loading={<LoadingSpinner />}
          height={pageHeight}
          onLoadSuccess={page => setPage(page)}
        />
      </Document>
      <PdfPreviewPagination
        pageNumber={pageNumber}
        numPages={numPages}
        visible={isPaginationVisible}
        setPageNumber={setPageAndResetSize}
      />
    </Box>
  )
}

const fitPdfPageIntoContainer = (page: PDFPageProxy, containerRef: MutableRefObject<HTMLElement | undefined>,
    setPageHeight: ((pageHeight: number) => void)) => {
  if (!containerRef.current) {
    return;
  }

  const { width, height } = containerRef.current.getBoundingClientRect();
  if (height <= 0) {
    return;
  }
  
  let newPageHeight = height;
  if (page.width > 0 && page.height > 0 && width > 0) {
    const heightScale = height / page.height;
    const widthScale = width / page.width;
    newPageHeight = Math.min(heightScale, widthScale) * page.height;
  }
  
  setPageHeight(newPageHeight);
}