import { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../../common/dialog/BootstrapDialog'
import DeleteUserDialogPreview from './DeleteUserDialogPreview'
import { deletePreviewUsers } from '../../api/deletePreviewUsers'
import { deleteUsers } from '../../api/deleteUsers'
import { toast } from 'react-hot-toast'
/**
 * Defines the properties required by the
 * current dialog component.
 */
export interface IDeleteUserDialogProps {
  setModal: Function
  users: string[]
}

export interface IDeleteUserPreview {
  username: string
  error?: string
  data?: {
    username: string
    vehicleRegistrations: {
      id: number
      status: string
    }[]
    token: string
  }
}

interface IDeleteUserResult {
  username: string
  success?: boolean
  error?: string
}

const DeleteUserDialog = (props: IDeleteUserDialogProps) => {
  const [preview, setPreview] = useState<IDeleteUserPreview[]>([])
  const [results, setResults] = useState<IDeleteUserResult[]>([])

  /**
   * It takes an array of usernames, and then it calls the deletePreviewUsers function, which is an API
   * call, and then it sets the preview state to the response data
   * @param {string[]} usernames - string[] - an array of usernames to delete
   */
  const handlePreview = async (usernames: string[]) => {
    try {
      const res = await deletePreviewUsers(usernames)
      setPreview(res.data)
    } catch (error) {
      toast.error('Failed to preview deletion of users')
      props.setModal(null)
    }
  }


  /**
   * It takes the preview array, maps over it, and returns an array of objects with the username and
   * token properties. It then passes that array to the deleteUsers function, which is imported from
   * the api.js file
   */
  const handleDeleteUsers = async () => {
    try {
      const req = preview.map(item => ({ username: item.username, token: item.data?.token }))
      const res = await deleteUsers(req)
      setResults(res.data)
    } catch (error) {
      toast.error('Failed to delete users')
      props.setModal(null)
    }
  }

  useEffect(() => void handlePreview(props.users), [props.users])

  return (
    <BootstrapDialog
      onClose={() => props.setModal(null)}
      aria-labelledby='customized-dialog-title'
      maxWidth='xl'
      open={true}
    >
      {/* The dialog title. */}
      <BootstrapDialogTitle
        id='delete-user-dialog-title'
        onClose={() => props.setModal(null)}
      >
        Delete Users
      </BootstrapDialogTitle>

      {/* The dialog content. */}
      <DialogContent dividers sx={{ minWidth: 200 }}>
        {results?.length ? (
          results.map(res => (
            <Typography key={res.username} component='div' variant='body1'>
              Benutzer {res.username}: <strong>{res.success ? 'success' : res.error}</strong>
            </Typography>
          ))
        ) : preview?.length ? (
          <>
            <Typography
              component='div'
              variant='h6'
              sx={{ textDecoration: 'underline' }}
            >
              Möchten Sie diesen Benutzer wirklich löschen?
            </Typography>

            {preview.map(data => (
              <DeleteUserDialogPreview key={data.username} data={data} />
            ))}
          </>
        ) : (
          <CircularProgress />
        )}
      </DialogContent>

      {/* The action buttons. */}
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* The DELETE button */}
        <div
          style={{
            flex: 1,
            width: 50,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            disabled={!!results?.length}
            onClick={() => handleDeleteUsers()}
          >
            Delete
          </Button>
        </div>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default DeleteUserDialog
