import { FieldTitle, fieldTitles } from "../configs/fieldTitles";

/**
 * Helps find a title for the
 * provided field key.
 */
export class FieldTitleHelper {
  /**
   * Holds the configured field
   * key translations.
   */
  private translations: FieldTitle[] = fieldTitles;

  /**
   * Get the field title based on the provided
   * field key.
   * It will try to find a translation key and
   * if none found than it will convert the key
   * itself to a title.
   * 
   * @param key string
   * @returns string
   */
  public getFieldTitle(key: string): string {
    // Check if a translation key exists for the current field key.
    const foundTranslation = this.translations.find(f => f.key === key);
    // Return the found translation or the converted key to title.
    return foundTranslation ? foundTranslation.translation : this.convertFieldKeyToTitle(key);
  }

  /**
   * Convert the field key to a title.
   * It separates words where upper case
   * letters are found.
   * firstName => First Name
   * 
   * @param key string
   * @returns string
   */
  private convertFieldKeyToTitle(key: string): string {
    // Split current key into a list.
    const letters = key.split('');
    // Find the upper case letter positions.
    let indexes = letters.map((letter: string, index: number) => letter === letter.toUpperCase() ? index : null);
    // Clean up the nulls from the array cause we don't need them.
    indexes = indexes.filter(i => !!i);
    // Separate into words.
    indexes.map((i: number, index: number) => {
      i && letters.splice(i + index, 0, ' ');
    });
    // Make first letter uppercase.
    letters[0] = letters[0].toUpperCase();
    // Join back the letters and return the words as string.
    return letters.join('');
  }
}