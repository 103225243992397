import { useContext, useState, useEffect } from 'react';
import { BootstrapDialog, BootstrapDialogTitle } from "../../../common/dialog/BootstrapDialog"
import { GlobalContext } from "../../../context/GlobalState";
import { DialogContent, Button, Typography, List, ListItem, ListItemText, Box } from '@mui/material';
import { bankTransferExports } from '../../api/bankTransferExports';
import { bankTransferExportsOld } from '../../api/bankTransferExportsOld';

interface IDownloadBankTransferExportsDialogProps {
  setModal: Function
}

interface ILink {
    fileName: string,
    fileURL: string
}

const MarkAsPaidOut = (props: IDownloadBankTransferExportsDialogProps) => {
    // Get some required properties from the global state.
    const { modal } = useContext(GlobalContext);
    // Download links
    const [links, setLinks] = useState<ILink[]>([])
    const [oldLinks, setOldLinks] = useState<ILink[]>([])
    const [loading, setLoading] = useState(true)
    const [showOldLinks, setShowOldLinks] = useState(false)


    const getDownloadOldLinks = async () => {
        try {
            setLoading(true)
            const resp = await bankTransferExportsOld()
            const body = await resp?.data?.json()
            setOldLinks(body?.links ?? [])
            setLoading(false)
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false)
        }
    }

    const downloadFile = async (url: string) => {
        try {
            window.open(url, '_blank')
        } catch (err) {
            console.error('Could not download file:', err)
        }
    }

    useEffect(() => void getDownloadOldLinks(), [showOldLinks])

    return (
        <BootstrapDialog
            onClose={() => props.setModal(null)}
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.setModal(null)}>
                {modal?.type.toString()}
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ minWidth: '500px'}}>
            {/* New export */}
            {loading && !showOldLinks
                ? <Typography textAlign='center' sx={{ mt:4 }}>Loading</Typography> 
                : links.length 
                    ? links.map(link => 
                        <List sx={{ mt:2 }}>
                            <ListItem secondaryAction={
                                <Button variant='outlined' color='primary' onClick={() => downloadFile(link.fileURL)}>Download</Button> } >
                                    <ListItemText primary={link.fileName} />
                            </ListItem>
                        </List>
                    ) 
                    : <Typography textAlign='center' sx={{ mt:4 }}>New export generation failed</Typography>
            }
            {/* Button to load old exports */}
            {!showOldLinks && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='outlined' color='primary' onClick={() => setShowOldLinks(true)} sx={{ mt:2 }}>Load Old Exports</Button>
            </Box>}
            {/* Old exports */}
            {showOldLinks && (loading 
                ? <Typography textAlign='center' sx={{ mt:4 }}>Loading</Typography> 
                : oldLinks.length 
                    ? oldLinks.map(link => 
                        <List sx={{ mt:2 }}>
                            <ListItem secondaryAction={
                                <Button variant='outlined' color='primary' onClick={() => downloadFile(link.fileURL)}>Download</Button> } >
                                    <ListItemText primary={link.fileName} />
                            </ListItem>
                        </List>
                    ) 
                    : <Typography textAlign='center' sx={{ mt:4 }}>No old export found</Typography>
            )}
            </DialogContent>
        </BootstrapDialog>
    )
}

export default MarkAsPaidOut