import { createContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast'
import { useLogout } from '../../../hooks/useLogout';
import { getUsersPromise } from '../users-admin/api/getUsers';
import { getVehiclesPromise } from '../vehicles-admin/api/getVehicles';
import { getQuotasPromise } from '../quotas-admin/api/getQuotas'
import { sendAllEmails } from '../users-admin/api/sendEmail';
import { IChecksum } from '../vehicles-admin/api/getMarketable';
import { marketVehiclesPromise } from '../vehicles-admin/api/marketVehicles';
import { IFilterItem } from '../common/FilterMenu';
import { getTemplatesPromise } from '../templates-admin/api/getTemplates';
import { getUploadsPromise } from '../pdf-uploads/api/getUploads';
import {
  getInvoicesPromiseV1,
  getInvoicesPromiseV2,
} from '../invoicing-admin/api/getInvoices'
import { canUseFeature } from '../../../hooks/canUseFeature';
import { ResponseProcessorHelper } from './ResponseProcessorHelper';
import { getInvoiceTemplatesPromise } from '../invoice-templates-admin/api/getInvoiceTemplatesPromise';
import { getVehicleAnalytics } from '../analytics-admin/api/getVehicleAnalytics'
import { getQuotaAnalytics } from '../analytics-admin/api/getQuotaAnalytics';
import getRole from '../../../utils/getRole';
import { getUserAnalytics } from '../analytics-admin/api/getUserAnalytics';

export enum ModalTypesEnum {
  BULK_EMAIL_TEMPLATE,
}

export interface IModalType {
  type: ContextActionEnum;
  ids?: string[];
}

export interface IPagination {
  range: number[];
  pageSize: number;
}

/**
 * Enumerates the types of context scopes
 * that we can use.
 */
export enum ContextScopeEnum {
  USERS = 'Liste mit allen Benutzern',
  VEHICLES = 'Liste mit allen Fahrzeugen',
  QUOTAS = 'Liste mit allen Quotas',
  INVOICING = 'Rechnungen',
  TEMPLATES = 'E-Mail-Vorlagen',
  INVOICE_TEMPLATES = 'Rechnungen-Vorlagen',
  PDF_UPLOADS = 'Alle pdf-Datei-Uploads',
  ANALYTICS = 'Analytics',
}

/**
 * Enumerates the action types that can
 * be triggered as bulk for users and vehicles.
 */
export enum ContextActionEnum {
  BULK_EMAIL = 'Sending bulk emails',
  REQUEST_QUOTA = 'Requesting Quota Sales for Vehicles',
  REQUEST_QUOTA_FROM_VEHICLE_FORM = 'Requesting Quota Sales from Vehicle Form',
  SHOW_DIALOG_APPROVE_QUOTA_SALES = 'Approve Quota Sale wizard',
  SHOW_DIALOG_DECLINE_QUOTA_SALES = 'Decline Quota Sale wizard',
  SUBMIT_QUOTAS = 'Mark selected Quota Sales as Submitted',
  BULK_FETCH_LATEST_QUOTAS = 'Fetching Quota IDs',
  BULK_APPROVE_QUOTA_SALES = 'Approving Quota Sales for selected vehicles',
  BULK_DECLINE_QUOTA_SALES = 'Declining Quota Sales for selected vehicles',
  BULK_SELL_QUOTAS = 'Sell Approved Quotas',
  BULK_PAY_QUOTA_SALES = 'Paying Quota Sales for selected vehicles',
  ADD_VEHICLE = 'Add new vehicle',
  DELETE_VEHICLES = 'Delete vehicles',
  ADD_USER = 'Add new user',
  DELETE_USER = 'Delete user',
  GENERATE_INVOICE = 'Generate invoices',
  SEND_INVOICE = 'Sending invoices',
  ADD_TEMPLATE = 'Neue Vorlage hinzufügen',
  DELETE_TEMPLATE = 'Vorlage löschen',
  EDIT_INVOICE_TEMPLATE = 'Rechnungsvorlage bearbeiten',
  UPLOAD_PROCESSED = 'Upload als bearbeitet markiert',
  UPLOAD_NOT_PROCESSED = 'Upload als NICHT verarbeitet markiert',
  BANK_TRANSFER_EXPORTS = 'Download bank transfer exports',
  INVOICE_MARKED = 'Invoice marked',
  INVOICE_UNMARKED = 'Invoice unmarked',
  MARK_PAID_OUT = 'Mark invoices as paid out',
  CREATE_UBA_SUBMISSION = 'Create UBA submission',
}

/**
 * Indicate the type and the progress of
 * the current bulk action being performed.
 */
export interface ITriggerAction {
  type: ContextActionEnum;
  progress: number;
}

/**
 * Defines the apps global state
 * signature.
 */
export interface IGlobalState {
  currentScope: ContextScopeEnum;
  setCurrentScope: Function;
  // The list of all tasks.
  vehicles: any[];
  quotas: any[];
  users: any[];
  analytics: any;
  setUsers: Function;
  setAnalytics: Function;
  invoicesV1: any[];
  invoicesV2: any[];
  templates: any[];
  invoiceTemplates: any[]
  uploads: any[];
  filters: any;
  setFilters: Function;
  predefinedFilters: IFilterItem[];
  setPredefinedFilters: Function;
  pagination: IPagination;
  setPagination: Function;
  sort: string[];
  setSort: Function;
  // Shows the number of total items.
  total: number;
  // The number of items that were selected.
  selectedIds: number[];
  setSelectedIds: Function;
  selectedUsernames: string[];
  setSelectedUsernames: Function;
  // The modal that should be opened.
  modal: IModalType;
  setModal: Function;
  // The loading indicator.
  loading: boolean;
  triggerAction: ITriggerAction;
  setTriggerAction: Function;
  /**
   * Actions made available to
   * other components.
   */
  sendBulkEmails: Function;
  // Market the selected vehicles.
  marketVehicles: Function;
  // Holds the checksum information used by the /market endpoint.
  checksum: any;
  setChecksum: Function;
  // Holds the selected item (click on row).
  selectedItem: any;
  setSelectedItem: Function;
  // Instruct the refresh of the current list.
  refreshList: number;
  setRefreshList: Function;
  // Update the edited vehicle.
  updateVehicleInList: Function;
}

const initialState: IGlobalState = {
  currentScope: ContextScopeEnum.USERS,
  setCurrentScope: () => {},
  vehicles: [],
  quotas: [],
  users: [],
  analytics: {},
  setUsers: () => {},
  setAnalytics: () => {},
  invoicesV1: [],
  invoicesV2: [],
  templates: [],
  invoiceTemplates: [],
  uploads: [],
  filters: null,
  setFilters: () => {},
  predefinedFilters: [],
  setPredefinedFilters: () => {},
  pagination: {
    range: [],
    pageSize: 0
  },
  setPagination: () => {},
  sort: [],
  setSort: () => {},
  total: 0,
  selectedIds: [],
  setSelectedIds: () => {},
  selectedUsernames: [],
  setSelectedUsernames: () => {},
  modal: null,
  setModal: () => {},
  loading: false,
  triggerAction: null,
  setTriggerAction: () => {},
  sendBulkEmails: () => {},
  marketVehicles: () => {},
  checksum: null,
  setChecksum: () => {},
  selectedItem: null,
  setSelectedItem: () => {},
  refreshList: null,
  setRefreshList: () => {},
  updateVehicleInList: () => {},
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = (props: { children: any }): any => {
  // Instantiate the response helper.
  const responseProcessorHelper = new ResponseProcessorHelper();
  /**
   * Set the current context scope (USERS or VEHICLES) so we know what
   * type of data to fetch.
   */
  const [currentScope, setCurrentScope] = useState<ContextScopeEnum>(
    getRole() === 'analytics'
      ? ContextScopeEnum.ANALYTICS
      : getRole() === 'admin'
      ? ContextScopeEnum.USERS
      : null
  )
  // Store the list of vehicles fetched from remote.
  const [vehicles, setVehicles] = useState<any[]>([]);
  // Store the list of vehicles fetched from remote.
  const [quotas, setQuotas] = useState<any[]>([]);
  // Store the list of invoices V1 fetched from remote.
  const [invoicesV1, setInvoicesV1] = useState<any[]>([]);
  // Store the list of invoices V1 fetched from remote.
  const [invoicesV2, setInvoicesV2] = useState<any[]>([]);
  // Stores the list of users fetched from remote.
  const [users, setUsers] = useState<any[]>([]);
  // Stores the list of analytics fetched from remote.
  const [analytics, setAnalytics] = useState<any>({});
  // Stores the list of templates fetched from remote.
  const [templates, setTemplates] = useState<any[]>([]);
  // Stores the list of pdf uploads fetched from remote.
  const [invoiceTemplates, setInvoiceTemplates] = useState<any[]>([])
  // Stores the list of pdf uploads fetched from remote.
  const [uploads, setUploads] = useState<any[]>([]);
  // Indicates the normal filters applied.
  const [filters, setFilters] = useState<any>({});
  // Stores the predefined filters selected (left side menu).
  const [predefinedFilters, setPredefinedFilters] = useState<IFilterItem[]>([]);
  // Indicates the currently set range of items to be queried.
  const [pagination, setPagination] = useState<IPagination>({
    range: [0, 99],
    pageSize: 100,
  });
  // Indicates the currently set sorting.
  const [sort, setSort] = useState<string[]>(['id', 'ASC']);
  // Indicates the total number of items on remote.
  const [total, setTotal] = useState<number>(0);
  // Indicates how many items were selected.
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  // Indicates how many items were selected.
  const [selectedUsernames, setSelectedUsernames] = useState<string[]>([]);
  // Indicates the type of bulk action that should be triggered.
  const [triggerAction, setTriggerAction] = useState<ITriggerAction>(null);
  /**
   * Holds the checksum info returned by the /marketable endpoint
   * and required by the /marketable/market endpoint.
   * After the market action, this state should be emptied.
   */
  const [checksum, setChecksum] = useState<IChecksum>(null);
  /**
   * Set the state of the loading property.
   * We use this to show loading indicators throughout
   * the UI.
   */
  const [loading, setLoading] = useState<boolean>(false);
  /**
   * Used to set the modal that should
   * be opened from anywhere inside the
   * application.
   */
  const [modal, setModal] = useState<IModalType>(null);
  // Holds the currently selected item (click on the row).
  const [selectedItem, setSelectedItem] = useState<any>(null);
  // Indicates if the currently loaded list should be refreshed.
  const [refreshList, setRefreshList] = useState<number>(0);
  /**
   * The logout hook that we use to log the current user
   * out in case we get a 401 at any given moment.
   */
  const logout = useLogout();

  /**
   * Fetch the list of vehicles using the
   * available params.
   */
  const getVehicles = () => {
    // Check if we have a predefined filter set.
    const hasPredefinedFilter = predefinedFilters && predefinedFilters.length;
    // Check if active filter has a custom method.
    const hasCustomMethod =
      !!predefinedFilters[0] && !!predefinedFilters[0].customMethod;
    /**
     * Check if we have predefined filter
     * and if it's using a custom method.
     */
    if (hasPredefinedFilter && hasCustomMethod) {
      predefinedFilters[0]
        .customMethod(predefinedFilters[0], sort, pagination)
        // Extract the checksum property from the response data.
        .then((response: any) => responseProcessorHelper.processResponse(response, setChecksum, setTotal))
        // Set the list of vehicles globally.
        .then((response: any) => setVehicles([...response]))
        .catch((error: any) => console.error('Error getting list of vehicles:', error))
        .finally(() => setLoading(false));
    } else {
      // Fetch the list of vehicles.
      getVehiclesPromise(predefinedFilters[0], sort, pagination)
        // Extract the checksum and totalCount properties.
        .then((response) => responseProcessorHelper.processResponse(response, setChecksum, setTotal))
        // Set the list of vehicles globally.
        .then((response) => setVehicles([...response]))
        .catch((error: any) => console.error('Error getting list of vehicles:', error))
        .finally(() => setLoading(false));
    }
    // Signal that there is data loading.
    setLoading(true);
  };

  /**
   * Fetch the list of quotas using the
   * available params.
   */
  const getQuotas = () => {
    // Check if we have a predefined filter set.
    const hasPredefinedFilter = predefinedFilters && predefinedFilters.length
    // Check if active filter has a custom method.
    const hasCustomMethod =
      !!predefinedFilters[0] && !!predefinedFilters[0].customMethod
    /**
     * Check if we have predefined filter
     * and if it's using a custom method.
     */
    if (hasPredefinedFilter && hasCustomMethod) {
      predefinedFilters[0]
        .customMethod(predefinedFilters[0], sort, pagination)
        // Extract the checksum property from the response data.
        .then((response: any) =>
          responseProcessorHelper.processResponse(
            response,
            setChecksum,
            setTotal
          )
        )
        // Set the list of quotas globally.
        .then((response: any) => {
          const restructureQuotas = response?.map((qs: any) => ({
            ...qs,
            firstName: qs?.vehicle?.firstName,
            lastName: qs?.vehicle?.lastName,
            licensePlate: qs?.vehicle?.licensePlate,
            carId: qs?.vehicle?.carId,
          }))
          setQuotas(restructureQuotas)
        })
        .catch((error: any) =>
          console.error('Error getting list of quotas:', error)
        )
        .finally(() => setLoading(false))
    } else {
      // Fetch the list of quotas.
      getQuotasPromise(predefinedFilters, sort, pagination)
        // Extract the checksum and totalCount properties.
        .then(response =>
          responseProcessorHelper.processResponse(
            response,
            setChecksum,
            setTotal
          )
        )
        // Set the list of quotas globally.
        .then(response => {
          const restructureQuotas = response?.map((qs: any) => ({
            ...qs,
            firstName: qs?.vehicle?.firstName,
            lastName: qs?.vehicle?.lastName,
            licensePlate: qs?.vehicle?.licensePlate,
            carId: qs?.vehicle?.carId,
          }))
          setQuotas(restructureQuotas)
        })
        .catch((error: any) =>
          console.error('Error getting list of quotas:', error)
        )
        .finally(() => setLoading(false))
    }
    // Signal that there is data loading.
    setLoading(true)
  }

  /**
   * Fetch the list of invoices using the
   * available params.
   */
  const getInvoices = () => {
    // Signal that there is data loading.
    setLoading(true)
    // Check if we have a predefined filter set.
    const hasPredefinedFilter = predefinedFilters && predefinedFilters.length
    // Check if active filter has a custom method.
    const hasCustomMethod =
      !!predefinedFilters[0] && !!predefinedFilters[0].customMethod
    /**
     * Check if we have predefined filter
     * and if it's using a custom method.
     */
    if (hasPredefinedFilter && hasCustomMethod) {
      predefinedFilters[0]
        .customMethod(predefinedFilters[0], sort, pagination)
        // Extract the checksum property from the response data.
        .then((response: any) =>
          responseProcessorHelper.processResponse(
            response,
            setChecksum,
            setTotal
          )
        )
        .then((response: any) => {
          if (canUseFeature('InvoicerV2')) {
            const normalised = response.data?.map((obj: any) => ({
              id: obj.id,
              invoiced: obj.invoiced,
              ts: obj.ts,
              issued: obj.issued,
              ...obj.invoice,
            }))
            setInvoicesV2([...normalised])
          } else {
            setInvoicesV1([...response])
          }
          setLoading(false)
        })
        .finally(() => setLoading(false))
    } else {
      if (canUseFeature('InvoicerV1')) {
        // Fetch the list of invoices.
        getInvoicesPromiseV1(pagination)
          // Extract the checksum property from the response data.
          .then((response: any) =>
            responseProcessorHelper.processResponse(
              response,
              setChecksum,
              setTotal
            )
          )
          .then(response => {
            setInvoicesV1(response)
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      }

      if (canUseFeature('InvoicerV2')) {
        // Fetch the list of invoices.
        getInvoicesPromiseV2(pagination)
          // Extract the checksum property from the response data.
          .then((response: any) =>
            responseProcessorHelper.processResponse(
              response,
              setChecksum,
              setTotal
            )
          )
          .then(response => {
            // console.log(invoices)
            setInvoicesV2(response?.map((obj: any) => ({
              id: obj.id,
              invoiced: obj.invoiced,
              ts: obj.ts,
              issued: obj.issued,
              ...obj.invoice,
            })))
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      }
    }
  }

  /**
   * Fetch the list of users using the
   * available params.
   */
  const getUsers = () => {
    // Signal that there is data loading.
    setLoading(true);
    // Fetch the list of vehicles.
    getUsersPromise({})
      // Extract the checksum and totalCount properties.
      .then((response) => responseProcessorHelper.processResponse(response, setChecksum, setTotal))
      .then((response) => setUsers([...response]))
      .catch((error: any) => console.error('Error getting list of users:', error))
      .finally(() => setLoading(false));
  };

  /**
   * It checks if the analytics object is empty, and if it is, it fetches the vehicle and quota
   * analytics and sets the analytics object
   */
  const getAnalytics = async () => {
    if (Object.keys(analytics).length) return
  
    const [users, vehicles, quotas] = await Promise.all([
      getUserAnalytics(),
      getVehicleAnalytics(),
      getQuotaAnalytics(),
    ])
    setAnalytics({ users, vehicles, quotas })
  }

  /**
   * Fetch the list of invoice templates using the
   * available params.
   */
  const getInvoiceTemplates = () => {
    // Signal that there is data loading.
    setLoading(true)
    // Fetch the list of vehicles.
    getInvoiceTemplatesPromise(filters, sort, pagination, predefinedFilters)
      // // Extract the checksum and totalCount properties.
      // .then(response =>{
      //   responseProcessorHelper.processResponse(response, setChecksum, setTotal)
      // })
      .then(response => setInvoiceTemplates(response?.data?.map((doc: any) => ({...doc, id: doc.name}))) )
      .catch((error: any) =>
        console.error('Error getting list of users:', error)
      )
      .finally(() => setLoading(false))
  }

  /**
   * Fetch the list of templates using
   * the available params.
   */
  const getTemplates = () => {
    // Signal that there is data loading.
    setLoading(true);
    // Fetch the list of vehicles.
    getTemplatesPromise(filters, sort, pagination, predefinedFilters)
      // Extract the checksum and totalCount properties.
      .then((response) => responseProcessorHelper.processResponse(response, setChecksum, setTotal))
      // Add random IDs to the list.
      .then((response) => responseProcessorHelper.addIDs(response))
      .then((response) => setTemplates([...response]))
      .catch((error: any) => console.error('Error getting list of templates:', error))
      .finally(() => setLoading(false));
  };

  /**
   * Fetch the list of templates using
   * the available params.
   */
  const getPdfUploads = () => {
    // Signal that there is data loading.
    setLoading(true);
    // Fetch the list of vehicles.
    getUploadsPromise(filters, sort, pagination, predefinedFilters)
      .then((response) => responseProcessorHelper.processResponse(response, setChecksum, setTotal))
      .then((response) => setUploads([...response]))
      .catch((error: any) => console.error('Error getting list of pdf uploads:', error))
      .finally(() => setLoading(false));
  };

  /**
   * Update the provided vehicle object in
   * the existing list of vehicles.
   */
  const updateVehicleInList = (vehicle: any) => {
    // Indicate to the user that an operation is ongoing.
    setLoading(true);
    // Create a new list of vehicles.
    let newVehiclesList = [...vehicles];
    /**
     * TODO: find the vehicle we need to update.
     */
    let foundVehicle = newVehiclesList.find(v => v.id === vehicle.id);
    /**
     * TODO: update the vehicle in a new list copy.
     */
    const newVehicle = { ...foundVehicle, ...vehicle };
    // Replace the new vehicle object in the list.
    newVehiclesList = newVehiclesList.map(v => v.id === newVehicle.id ? newVehicle : v)
    /**
     * TODO: refresh the list of vehicles.
     */
    // Fetch the list of vehicles.
    setVehicles(newVehiclesList);
    setLoading(false);
  }

  /**
   * Monitor the scope changes so we can perform
   * pagination and predefinedFilters reset.
   */
  useEffect(() => {
    /**
     * Reset some properties since we switch scope.
     * These changes will trigger the reload of the
     * required lists automatically since we listen
     * to changes on sort, predefinedFilters, pagination.
     */
    setPagination({ range: [0, 99], pageSize: 100 });
    setPredefinedFilters([]);
  }, [currentScope]);

  /**
   * Fetch the list of tasks from the
   * remote MongoDB - Atlas database.
   */
  useEffect(() => {
    if (loading) {
      return;
    }
    /**
     * Decide which type of data do we need
     * to fetch based on the set global scope
     * (currentScope).
     */
    switch (currentScope) {
      case ContextScopeEnum.USERS:
        getUsers();
        break;
      case ContextScopeEnum.VEHICLES:
        getVehicles();
        break;
      case ContextScopeEnum.QUOTAS:
        getQuotas();
        break;
      case ContextScopeEnum.TEMPLATES:
        getTemplates();
        break;
      case ContextScopeEnum.PDF_UPLOADS:
        getPdfUploads();
        break;
      case ContextScopeEnum.INVOICING:
        getInvoices();
        break;
      case ContextScopeEnum.INVOICE_TEMPLATES:
        getInvoiceTemplates()
        break
      case ContextScopeEnum.ANALYTICS:
        getAnalytics();
        break;
      default:
        break;
    }
  }, [sort, predefinedFilters, pagination, refreshList]);

  /**
   * Send out emails to all the provided userIds using
   * the provided template name.
   *
   * @param ids number[]
   * @param template string
   */
  const sendBulkEmails = async (usernames: string[], template: string) => {
    /**
     * Build the IIFE so we execute all generated
     * promises and update the general progress of
     * the current triggerAction.
     */
    sendAllEmails(usernames, template)
    toast.success('Bulk Email Triggered', { duration: 5000 })
  };

  /**
   * Market the provided list of vehicles.
   *
   * @param ids number[]
   * @returns void
   */
  const marketVehicles = async (ids: number[]) => {
    marketVehiclesPromise(
      ids,
      checksum?.count,
      checksum?.lastRecordCreatedAt,
      setTriggerAction
    );
  };

  return (
    <GlobalContext.Provider
      value={{
        currentScope,
        setCurrentScope,
        vehicles,
        quotas,
        users,
        analytics,
        setAnalytics,
        setUsers,
        invoicesV1,
        invoicesV2,
        templates,
        invoiceTemplates,
        uploads,
        filters,
        setFilters,
        predefinedFilters,
        setPredefinedFilters,
        pagination,
        setPagination,
        sort,
        setSort,
        total,
        selectedIds,
        setSelectedIds,
        selectedUsernames,
        setSelectedUsernames,
        modal,
        setModal,
        loading,
        triggerAction,
        setTriggerAction,
        sendBulkEmails,
        marketVehicles,
        checksum,
        setChecksum,
        selectedItem,
        setSelectedItem,
        refreshList,
        setRefreshList,
        updateVehicleInList,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
