import { Button, Link, Typography } from "@mui/material";
import moment from "moment";
import { flagUploadPromise } from "./api/flagUpload";
import toast from 'react-hot-toast';
import GetEmailButton from "../common/GetEmailButton";

/**
 * Flag the provided upload id.
 * 
 * @param uploadId number
 * @returns void
 */
const flagUpload = (uploadId: number, flag: boolean, setTriggerAction: Function, setRefreshList: Function) => {
  flagUploadPromise(uploadId, flag, setTriggerAction, setRefreshList)
    /**
     * Notify the user the upload that
     * the upload has been flagged.
     */
    .then(result => toast.success('Upload als bearbeitet markiert".'))
    .catch(error => toast.error('Upload als NICHT bearbeitet markiert.'))
}

export const getColumns = (setTriggerAction: Function, setRefreshList: Function) => [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
  },
  {
    field: 'ownerUsername',
    headerName: 'Owner',
    width: 300,
    cellClassName: 'center--cell',
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    renderCell: (params: any) => <GetEmailButton username={params?.row?.ownerUsername} />
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 160,
    valueGetter: (params: any) => {
      const date = params.row.updatedAt
      return date
        ? moment(date).format('DD/MM/YYYY  hh:mm:ss').toString()
        : null
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 150,
    valueGetter: (params: any) =>
      moment(params.row.createdAt).format('DD/MM/YYYY HH:mm:ss').toString(),
  },
  {
    field: 'docFile',
    headerName: 'File',
    width: 250,
    cellClassName: 'open--cell',
    disableClickEventBubbling: true,
    renderCell: (params: any) => (
      <Link href={params.row.docFile} target='_blank'>
        Öffnen
      </Link>
    ),
  },
  {
    field: 'isProcessed',
    headerName: 'Status',
    width: 110,
    cellClassName: 'center--cell',
    renderCell: (params: any) => {
      if (params.row.isProcessed) {
        return <Typography sx={{ color: '#2e7d32' }}>Erledigt</Typography>
      }
      return <Typography sx={{ color: '#d32f2f' }}>Nicht erledigt</Typography>
    },
  },
  {
    field: 'actionButton',
    headerName: 'Markieren als',
    width: 150,
    cellClassName: 'center--cell',
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      if (params.row.isProcessed) {
        return (
          <Button
            variant='outlined'
            color='error'
            onClick={() =>
              flagUpload(params.row.id, false, setTriggerAction, setRefreshList)
            }
          >
            NICHT ERLEDIGT
          </Button>
        )
      }
      return (
        <Button
          variant='contained'
          color='success'
          onClick={() =>
            flagUpload(params.row.id, true, setTriggerAction, setRefreshList)
          }
        >
          ERLEDIGT
        </Button>
      )
    },
  },
  {
    field: 'uba_years',
    headerName: 'UBA Years',
    width: 120,
    valueGetter: (params: any) => params.row.uba_years.join(', '),
  },
]