export const emptyUser = {
  acceptedTAndC: true,
  acceptedTAndC2023: false,
  accountOwner: '',
  accountType: '',
  city: '',
  company: '',
  debtorNumber: '',
  email: '',
  firstName: '',
  houseNumber: '',
  iban: '',
  isCompany: false,
  lastName: '',
  marketingOptIn: false,
  membershipCode: '',
  isNoRightOfRevocation: false,
  discountOption: null as number,
  priceUpdateOptIn: false,
  raffleCheckbox: false,
  regFlowComplete: false,
  remarketing2023: false,
  street: '',
  taxNumber: '',
  uniqueId: '',
  vatId: '',
  vatReclaimEntitled: false,
  zip: '',
  'delete-bank-data': null as any,
  maxVehicleCount: '',
}
