export const getColumns = (
  setTriggerAction: Function,
  setRefreshList: Function,
  fileDownloadLink: any
) => [
  {
    field: 'name',
    headerName: 'Document',
    width: 200
  },
]
