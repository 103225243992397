import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It takes an array of usernames and returns a promise that resolves to an array of vehicle
 * registrations
 * @param {string[]} usernames - string[]
 * @returns An array of objects.
 */
export const getVehiclesByUsernames = (usernames: string[]): Promise<any> => {
  const url = `${API_URL}/vehicle-registrations/users`;

  return fetcher(url, {
    method: 'POST',
    body: JSON.stringify({ usernames }),
  })
}
