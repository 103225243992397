import { IFormConfig } from "../form-generator/interfaces";
import { availableVehicleClasses } from "./vehicleClasses";

export const vehicleFormConfig: IFormConfig = {
  /**
   * Here you can configure the layout of
   * the current form into tabs.
   */
  tabs: [
    {
      index: 1,
      name: 'Fahrzeughalter',
      groups: [
        // First row.
        {
          index: 1,
          fields: ['remoteEmail', 'uba2022', 'uba2023'],
        },
        // Second row.
        {
          index: 2,
          fields: ['firstName', 'lastName', 'ownerUsername'],    
        },
        // Third row.
        {
          index: 3,
          fields: ['street', 'zip', 'city']
        },
        {
          index: 4,
          fields: ['userFirstName', 'userLastName', 'userCompany']
        }
      ]
    },
    {
      index: 2,
      name: 'Fahrzeug',
      groups: [
        {
          index: 1,
          fields: ['frontImage', 'backImage']
        },
        {
          index: 2,
          fields: ['status', 'imageErrorStatus', 'errMsg']
        },
        {
          index: 3,
          fields: [
            'verificationStatus',
            'registeredAt',
            'licensePlate',
            'carId',
            'mainInspectionDate',
            'firstRegisteredAt',
            'manufacturer',
            'model',
            'manufacturerCode',
            'modelCode',
            'vehicleClass',
            'bevChecked'
          ]
        }
      ]
    },
    {
      index: 3,
      name: 'Overview',
      groups: [
        {
          index: 1,
          fields: ['quotaSales']
        },
      ]
    },
  ],
  /**
   * Here you can configure the fields individually
   * so the field types and options can be handled
   * correctly by the form.
   */
  fieldsConfig: [
    { id: 1, fieldName: 'frontImage', type: 'link', hidden: true, saveOnlyIfChanged: true },
    { id: 2, fieldName: 'backImage', type: 'link', hidden: true, saveOnlyIfChanged: true },
    { id: 3, fieldName: 'imageErrorStatus', type: 'select', options: [
      { name: 'No errors', value: 0 },
      { name: 'Front image wrong', value: 1 },
      { name: 'Back image wrong', value: 2 },
      { name: 'Both images wrong', value: 3 },
    ]},
    { id: 4, fieldName: 'errMsg', type: 'string' },
    { id: 6, fieldName: 'carbonifyAuditor', type: 'string', },
    { id: 7, fieldName: 'firstName', type: 'string',  },
    { id: 8, fieldName: 'lastName', type: 'string', },
    { id: 9, fieldName: 'street', type: 'string', },
    { id: 10, fieldName: 'zip', type: 'string', },
    { id: 11, fieldName: 'city', type: 'string', },
    { id: 12, fieldName: 'registeredAt', type: 'date', },
    { id: 13, fieldName: 'licensePlate', type: 'string', },
    { id: 14, fieldName: 'carId', type: 'vin', },
    { id: 15, fieldName: 'firstRegisteredAt', type: 'date', },
    { id: 16, fieldName: 'mainInspectionDate', type: 'string', },
    { id: 17, fieldName: 'manufacturerCode', type: 'string', },
    { id: 18, fieldName: 'manufacturer', type: 'string', },
    { id: 19, fieldName: 'modelCode', type: 'string', },
    { id: 20, fieldName: 'model', type: 'string', },
    { id: 21, fieldName: 'vehicleClass', type: 'select', options: availableVehicleClasses },
    { id: 22, fieldName: 'ubaVerifiedAt', type: 'date', },
    { id: 23, fieldName: 'ownerUsername', type: 'string', disabled: true },
    { id: 24, fieldName: 'id', type: 'number', disabled: true },
    { id: 25, fieldName: 'bevChecked', type: 'boolean'},
    { id: 26, fieldName: 'remoteEmail', mode: 'create', type: 'remote-email', url: 'sample-url', filterOn: 'create' },
    { id: 27, fieldName: 'status', type: 'string', disabled: true },
    { id: 28, fieldName: 'quotaSales', type: 'overview', mode: 'edit' },
    { id: 29, fieldName: 'userFirstName', type: 'string', disabled: true, saveOnlyIfChanged: true },
    { id: 30, fieldName: 'userLastName', type: 'string', disabled: true, saveOnlyIfChanged: true },
    { id: 31, fieldName: 'userCompany', type: 'string', disabled: true, saveOnlyIfChanged: true },
    { id: 32, fieldName: 'uba2022', mode: 'create', type: 'boolean' },
    { id: 33, fieldName: 'uba2023', mode: 'create', type: 'boolean' },
  ]
};
