import { API_URL } from '../../../../constants/api'
import { stringify } from 'query-string'
import { IPagination } from '../../context/GlobalState'
import { fetcher } from '../../../../utils/fetcher'
import { IFilterItem } from '../../common/FilterMenu'

/**
 * Make an API request using the provided url.
 */
export const getQuotasPromise = (
  predefinedFilters: IFilterItem[],
  sort: string[],
  pagination: IPagination
): Promise<any> => {
  // Calculate the range.
  const range = [pagination.range[0], pagination.range[1]]
  // get the filters
  const filters = predefinedFilters?.length ? predefinedFilters?.map(item => item.filter) : []
  // Add the required query params.
  const params = {
    filter: JSON.stringify(filters?.reduce((curr, acc) => ({ ...acc, ...curr }), {}) || {}),
    range: JSON.stringify(range),
    sort: JSON.stringify(sort),
  }
  // Stringify the query params.
  const stringParams = `${stringify(params)}`
  // The custom route if it's the case.
  const route = (predefinedFilters?.[0]?.route) || 'quota-sales'
  // Build up the complete url
  const url = `${API_URL}/${route}?${stringParams}`

  return fetcher(url)
    .then(data => data)
    .catch(error => {
      return error
    })
  // Handle errors related to wrong status (401...).
  // error && handleStatusError(error);
}
