import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { createQuotaData } from '../dialogs/RequestQuotaDialog/RequestQuotaTable';

/**
 * It creates a quota for a vehicle registration
 * @param {createQuotaData}  - vrId - The vehicle registration ID.
 * @returns A promise.
 */
export const createQuota = ({ vrId, uba_year }: createQuotaData) => {
  /* Creating a url with the API_URL, vrId, and uba_year. */
  const url = `${API_URL}/vehicle-registrations/${vrId}/quota-sale/${uba_year}`;
  const method = 'POST';

  return fetcher(url, {
    method,
  });
};
