import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormContainerHelper } from "./FormContainerHelper";
import { IFieldConfig } from "../generator";
import { If } from "yup/lib/util/types";

interface IFormContainerProps {
  /**
   * The list of all the field configurations
   * that should be processed by the form.
   */
  fields: IFieldConfig[];
  /**
   * Contains all the children components
   * of the current form.
   */
  children: any;
  /**
   * The callback method that should be
   * called once the form is submitted.
   */
  onSubmit?: any;
  /**
   * The object containing the styling
   * dedicated for this component.
   */
  style?: any;
  /**
   * Display the message on the form
   * container.
   */
  message?: string;
}

/**
 * Contains the form and creates a context around it
 * so the user can access and control the value inside
 * much easier.
 */
const FormContainer: React.FC<IFormContainerProps> = ({ fields, children, onSubmit, style, message }) => {
  // Holds the processed children components.
  const [ processedChildren, setProcessedChildren ] = useState<any[]>([]);
  // Indicates the validation state of the form.
  const [ valid, setValid ] = useState<boolean>(true);
  // The helper class that handles the field o0-= perations.
  const helper = new FormContainerHelper();
  /**
   * Parse the children and
   * prepare them for render.
   */
  useEffect(() => {
    setProcessedChildren(helper.processChildren(children, fields));
  }, []);
  /**
   * The submit method that is triggered once a
   * submit button has been pressed.
   */
  const handleOnSubmit = (event: any) => {
    /**
     * Parse through the list of field names
     * and get their value.
     */
    const formData = fields.reduce((acc: any, field: IFieldConfig) => ({...acc, [field.name]: event.target.elements[field.name].value}), {});
    /**
     * Parse the event.targe elements and find
     * the ones that have a value
     */
    // Skip the default behavior of refreshing the page.
    event.preventDefault();
    /**
     * Call the submit callback provided
     * by the parent component.
     */
    onSubmit(formData);
  }

  return (
    <Box component="form" sx={style} onSubmit={(event: any) => handleOnSubmit(event)}>
      {/* Error message displayed when the message prop is being provided. */}
      {!!message && <Box sx={{ display: 'flex', justifyContent: 'center', color: '#D00' }}><span>{message}</span></Box>}
       {/* The rest of the children components that have to be rendered. */}
      {processedChildren}
    </Box>
  );
}

export default FormContainer;