import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { IFormField } from '../../form-generator/interfaces';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { getCognitoUser } from '../../../../../utils/api/getCognitoUser';

/**
 * Defines the list of properties required
 * by the EmailSearch component.
 */
export interface IEmailSearchProps {
  emailField: IFormField;
  ownerUsernameField: IFormField;
  onChange: (field: IFormField, event: any) => void;
}

/**
 * Provides the option to the user to search for
 * user email addresses and returns an asynchronously
 * loading list of results.
 */
export default function EmailSearch(props: IEmailSearchProps) {
  // Indicates if a NOT FOUND error should be shown or not.
  const [error, setError] = useState<boolean>(false);
  // Holds the typed in email address.
  const [email, setEmail] = useState<string>('');
  // Indicates if the list of results is being loaded or not.
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Set the value of the current field from
   * the received field prop value.
   */
  useEffect(() => {
    setEmail(props.emailField.value as string);
  })

  /**
   * Store the provided email address so
   * we can trigger the backend search.
   * 
   * @param value string
   */
  const onChangeEvent = (email: string) => {
    const theEmail = email.toLowerCase();
    setEmail(theEmail);
    props.onChange(props.emailField, theEmail);
  }

  /**
   * Find the user by using the email local state.
   */
  const findUserByEmail = () => {
    if (props.emailField.validationErrorText) {
      return;
    }
    // Start the loading indicator.
    setLoading(true);
    // Trigger the search.
    getCognitoUser({ email })
      .then((user: any) => {
        /**
         * If results are returned then
         * stop the loading indicator.
         */
        setLoading(false)
        setError(!user?.email)
        /**
         * Store the found user in the provided
         * ownerUsername prop field.
         */
        props.onChange(props.ownerUsernameField, user)
      })
      .catch();
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        type="text"
        error={!!props.emailField.validationErrorText}
        helperText={props.emailField.validationErrorText}
        label={props.emailField.name}
        defaultValue={props.emailField.value}
        onChange={(event: any) => onChangeEvent(event.target.value)}
        variant="standard"
        sx={{mb: 1}}
      />

      <Button
        variant="contained"
        onClick={findUserByEmail}
        disabled={!!props.emailField.validationErrorText}>Finden</Button>

      {/* Show the loading indicator. */}
      {loading && <LinearProgress sx={{ mt: 2 }} />}

      {/* Show the NOT FOUND error. */}
      {error && <Typography sx={{ mt: 2 }} color="error">Benutzer nicht gefunden</Typography>}
    </Box>
  );
}
