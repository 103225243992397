import { Button } from "@mui/material";
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import DownloadIcon from '@mui/icons-material/Download';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds number
 * @returns boolean
 */
const DownloadB2CCSVAction = (props: { downloadCSV: Function, context: ContextScopeEnum, selectedIds: number[] }) => {
  const show = props.context === ContextScopeEnum.INVOICING;
  const hasNoneSelected = !props.selectedIds.length;

  if (show && hasNoneSelected) {
    return (
      <Button color="inherit" onClick={() => props.downloadCSV()}>
        <DownloadIcon />
        &nbsp;Download B2C CSV
      </Button>
    );
  }
  return <></>;
}

export default DownloadB2CCSVAction;