export const carbonifyLabels = [
  /********************************************************************
   ************************ REGISTRATION. *****************************
   ********************************************************************/
  { tenant: 'adac', page: 'register', key: 'PAGE_TITLE', label: 'Jetzt registrieren und Geld für Dein Elektrofahrzeug erhalten.' },
  { tenant: 'adac', page: 'register', key: 'PAGE_SUBTITLE1', label: `` },
  { tenant: 'adac', page: 'register', key: 'PAGE_SUBTITLE2', label: `` },
  { tenant: 'adac', page: 'register', key: 'TITLE', label: '' },
  { tenant: 'adac', page: 'register', key: 'PRIVATE_PERSON', label: 'Privatperson' },
  { tenant: 'adac', page: 'register', key: 'COMPANY', label: 'Unternehmen' },
  { tenant: 'adac', page: 'register', key: 'FIRSTNAME', label: 'Vorname' },
  { tenant: 'adac', page: 'register', key: 'LASTNAME', label: 'Nachname' },
  { tenant: 'adac', page: 'register', key: 'EMAIL', label: 'E-Mail' },
  { tenant: 'adac', page: 'register', key: 'PASSWORD', label: 'Passwort vergeben' },
  { tenant: 'adac', page: 'register', key: 'PASSWORD_DESC', label: 'mind. 12 zeichen, sonderzeichen' },
  { tenant: 'adac', page: 'register', key: 'REGISTER', label: 'E-mail-Verifizierung starten' },
  /********************************************************************
   ******************* REGISTRATION CONFIRMATION. *********************
   ********************************************************************/
  // { tenant: 'adac', page: 'email_confirmation', key: 'TITLE', label: 'Bitte bestätigen Sie jetzt Ihre E-Mail-Adresse.' },
  // { tenant: 'adac', page: 'email_confirmation', key: 'DESCRIPTION', label: `
  //   Eine E-Mail mit einem Link zur Verifizierung Ihrer E-Mail-Adresse sollte sich bereits in Ihrem Posteingang befinden. Wenn Sie den Link anklicken, verifizieren Sie Ihre E-Mail-Adresse und Ihre eingegebenen Daten werden zeitlich befristet gespeichert. Sollten Sie Ihre E-Mail-Adresse nicht innerhalb von 24 Stunden bestätigen und sich auch nicht im THG-Portal einloggen, wird der ADAC Ihre Angaben aus Datenschutzgründen löschen. 
  //   Bitte kontrollieren Sie auch Ihren Spam-Ordner.` },
  /********************************************************************
   ************************ LOGIN PAGE. *******************************
   ********************************************************************/
  { tenant: 'carbonify', page: 'login', key: 'TITLE', label: 'Willkommen zurück.' },
  { tenant: 'carbonify', page: 'login', key: 'EMAIL', label: 'E-Mail' },
  { tenant: 'carbonify', page: 'login', key: 'PASSWORD', label: 'Passwort' },
  { tenant: 'carbonify', page: 'login', key: 'SUBMIT', label: 'Einloggen' },
  { tenant: 'carbonify', page: 'login', key: 'LOGIN', label: 'Einloggen' },
  { tenant: 'carbonify', page: 'login', key: 'LOGOUT', label: 'Ausloggen' },
  { tenant: 'carbonify', page: 'login', key: 'FORGOT_PASSWORD', label: 'Passwort vergessen' },
  { tenant: 'carbonify', page: 'login', key: 'I_AM_NEW', label: 'Ich bin neu hier' },
  { tenant: 'carbonify', page: 'login', key: 'REGISTER', label: 'Registrieren' },
  /********************************************************************
   ************* REGISTRATION FLOW (Registration steps). **************
   ********************************************************************/
  // Registration step 1
  { tenant: 'carbonify', page: 'registration_step_0', key: 'TITLE', label: 'Registrieren' },
  { tenant: 'carbonify', page: 'registration_step_0', key: 'IMAGE_FRONT', label: 'Vorderseite' },
  { tenant: 'carbonify', page: 'registration_step_0', key: 'IMAGE_BACK', label: 'Rückseite' },
  { tenant: 'carbonify', page: 'registration_step_0', key: 'UPLOAD', label: 'Hier Hochladen' },
  { tenant: 'carbonify', page: 'registration_step_0', key: 'REGISTER_MORE_THAN_5', label: 'Mehr als fünf Fahrzeuge anmelden? Zum Sammelupload' },
  { tenant: 'carbonify', page: 'registration_step_0', key: 'REGISTER_ALL_AT_ONCE', label: 'Fahrzeuge einzeln hochladen' },
  { tenant: 'carbonify', page: 'registration_step_0', key: 'NEXT', label: 'Weiter' },
  { tenant: 'carbonify', page: 'registration_step_0', key: 'SKIP', label: 'Überspringen' },
  { tenant: 'carbonify', page: 'registration_step_0', key: 'EXPLANATION', label: 'Bitte stellen Sie sicher, dass die hochgeladenen Fotos ausschließlich den Fahrzeugschein in hoher Auflösung zeigen und der Text gut lesbar ist.' },
  // Registration step 2
  { tenant: 'carbonify', page: 'registration_step_1', key: 'BUTTON_SAVE', label: 'Speichern' },

  // Registration step 3

  // Registration step 4

  /********************************************************************
   ****************** PROFILE AREA / PERSONAL AREA. *******************
   ********************************************************************/
  { tenant: 'carbonify', page: 'profile_area', key: 'HEADER', label: 'Hier kannst du deine Fahrzeuge einsehen und Änderungen vornehmen:' },
  { tenant: 'carbonify', page: 'profile_area', key: 'NEXT_QUOTA_HEADER', label: 'Danke für Dein Vertrauen in 2022!' },
  { tenant: 'carbonify', page: 'profile_area', key: 'NEXT_QUOTA_DESCRIPTION', label: 'Wir würden uns freuen, auch in 2023 den THG-Quotenantrag für dich abwickeln zu dürfen.' },
  { tenant: 'carbonify', page: 'profile_area', key: 'NEXT_QUOTA_CHECKBOX', label: 
    <>
      Ich beauftrage carbonify mit der Vermarktung meiner THG-Quote für 2023 und habe die&nbsp;
      <a href="/agb" target='_blank' rel='noreferrer'>AGB</a>
      &nbsp;sowie die&nbsp;
      <a href="/datenschutz"  target='_blank' rel='noreferrer'>Datenschutz</a>
      &nbsp;zur Kenntnis genommen.
      Ich erkläre mich mit den AGB einverstanden.
    </>
  },
  { tenant: 'carbonify', page: 'profile_area', key: 'NEXT_QUOTA_BUTTON', label: 'Jetzt für 2023 registrieren' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VEHICLES', label: 'Fahrzeuge' },
  { tenant: 'carbonify', page: 'profile_area', key: 'PAYOUT', label: 'Auszahlungen' },
  { tenant: 'carbonify', page: 'profile_area', key: 'PROFILE', label: 'Profil' },
  { tenant: 'carbonify', page: 'profile_area', key: 'PERSONAL_DATA', label: 'Persönliche Daten' },
  { tenant: 'carbonify', page: 'profile_area', key: 'PASSWORD_CHANGE', label: 'Passwort ändern' },
  { tenant: 'carbonify', page: 'profile_area', key: 'BANK_DETAILS', label: 'Bankverbindung' },
  { tenant: 'carbonify', page: 'profile_area', key: 'ADD', label: 'Hinzufügen' },
  { tenant: 'carbonify', page: 'profile_area', key: 'MODIFY', label: 'Ändern' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VIEW', label: 'Einsehen' },
  { tenant: 'carbonify', page: 'profile_area', key: 'FIRSTNAME', label: 'Vorname' },
  { tenant: 'carbonify', page: 'profile_area', key: 'LASTNAME', label: 'Nachname' },
  { tenant: 'carbonify', page: 'profile_area', key: 'EMAIL', label: 'E-mail' },
  { tenant: 'carbonify', page: 'profile_area', key: 'STREET', label: 'Straße' },
  { tenant: 'carbonify', page: 'profile_area', key: 'HOUSE_NUMBER', label: 'Hausnummer' },
  { tenant: 'carbonify', page: 'profile_area', key: 'ZIP', label: 'PLZ' },
  { tenant: 'carbonify', page: 'profile_area', key: 'CITY', label: 'Stadt' },
  { tenant: 'carbonify', page: 'profile_area', key: 'TAX_NUMBER', label: 'Steuernummer' },
  { tenant: 'carbonify', page: 'profile_area', key: 'TAX_ID', label: 'USt-ID' },
  { tenant: 'carbonify', page: 'profile_area', key: 'BUTTON_BACK', label: 'Zurück' },
  { tenant: 'carbonify', page: 'profile_area', key: 'BUTTON_SAVE', label: 'Speichern' },
  { tenant: 'carbonify', page: 'profile_area', key: 'PRIVATE_PERSON', label: 'Privatperson' },
  { tenant: 'carbonify', page: 'profile_area', key: 'COMPANY', label: 'Unternehmen' },
  { tenant: 'carbonify', page: 'profile_area', key: 'TAX_DEDUCTIBLE', label: 'Vorsteuerabzugberechtigt' },
  { tenant: 'carbonify', page: 'profile_area', key: 'TAX_DEDUCTIBLE_YES', label: 'Ja' },
  { tenant: 'carbonify', page: 'profile_area', key: 'TAX_DEDUCTIBLE_NO', label: 'Nein' },
  { tenant: 'carbonify', page: 'profile_area', key: 'PASSWORD_CURRENT', label: 'Aktuelles Passwort' },
  { tenant: 'carbonify', page: 'profile_area', key: 'PASSWORD_NEW', label: 'Neues Passwort' },
  { tenant: 'carbonify', page: 'profile_area', key: 'PASSWORD_CONFIRM', label: 'Passwort wiederholen' },
  { tenant: 'carbonify', page: 'profile_area', key: 'YOUR_ACCOUNT_DETAILS', label: 'Ihre Kontodaten' },
  { tenant: 'carbonify', page: 'profile_area', key: 'ACCOUNT_HOLDER', label: 'Name des Kontoinhabers' },
  { tenant: 'carbonify', page: 'profile_area', key: 'ACCOUNT_NUMBER', label: 'IBAN' },
  { tenant: 'carbonify', page: 'profile_area', key: 'MY_VEHICLES', label: 'Meine Fahrzeuge' },
  { tenant: 'carbonify', page: 'profile_area', key: 'MANUFACTURER_MODEL', label: 'Marke, Modell (in Prüfung)' },
  { tenant: 'carbonify', page: 'profile_area', key: 'PLEASE_CORRECT', label: 'Bitte korrigieren' },
  { tenant: 'carbonify', page: 'profile_area', key: 'LICENSEPLATE', label: 'Kennzeichen:' },
  { tenant: 'carbonify', page: 'profile_area', key: 'UPLOADED_AT', label: 'Hochgeladen am:' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VEHICLE_STATUS_UPLOADED', label: 'Hochgeladen' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VEHICLE_STATUS_FAULTY', label: 'Fehlerhaft' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VEHICLE_STATUS_VERIFIED', label: 'Vorgeprüft' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VEHICLE_STATUS_SUBMITTED', label: 'UBA - eingereicht' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VEHICLE_STATUS_CERTIFIED', label: 'UBA - zertifiziert' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VEHICLE_STATUS_MARKETED', label: 'Vermarktet' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VEHICLE_ERROR', label: 'Fehler:' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VEHICLE_ERROR_', label: 'Leider abgelehnt - Führerschein hochgeladen. Bitte ZLB! (Fahrzeugschein) hochladen.' },
  { tenant: 'carbonify', page: 'profile_area', key: 'VEHICLE_MODIFY', label: 'Bearbeiten' },
];