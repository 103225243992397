export const adacLabels = [
  /********************************************************************
   ************************ REGISTRATION. *****************************
   ********************************************************************/
  { tenant: 'adac', page: 'register', key: 'PAGE_TITLE', label: 'Herzlich Willkommen im THG-Portal des ADAC!' },
  { tenant: 'adac', page: 'register', key: 'PAGE_SUBTITLE1', label: `Wenn Sie Halter eines reinen Elektrofahrzeugs sind oder vom Halter nachweisbar berechtigt wurden, können Sie die THG-Quote im THG-Portal des ADAC e.V. (ADAC) geltend machen. Auch für mehrere Fahrzeuge! Der ADAC bietet für jedes reine Elektrofahrzeug einen Geldbetrag – den „ADAC THG-Bonus“.` },
  { tenant: 'adac', page: 'register', key: 'PAGE_SUBTITLE2', label: `ADAC-Mitglieder erhalten zusätzlich einen Mitgliedervorteil.` },
  { tenant: 'adac', page: 'register', key: 'TITLE', label: 'Registrieren im THG-Portal' },
  { tenant: 'adac', page: 'register', key: 'PRIVATE_PERSON', label: 'Privatperson' },
  { tenant: 'adac', page: 'register', key: 'COMPANY', label: 'Unternehmen' },
  { tenant: 'adac', page: 'register', key: 'FIRSTNAME', label: 'Vorname' },
  { tenant: 'adac', page: 'register', key: 'LASTNAME', label: 'Nachname' },
  { tenant: 'adac', page: 'register', key: 'EMAIL', label: 'E-Mail' },
  { tenant: 'adac', page: 'register', key: 'PASSWORD', label: 'Passwort vergeben' },
  { tenant: 'adac', page: 'register', key: 'PASSWORD_DESC', label: 'mind. 12 zeichen, sonderzeichen' },
  { tenant: 'adac', page: 'register', key: 'REGISTER', label: 'E-mail-Verifizierung starten' },
  /********************************************************************
   ******************* REGISTRATION CONFIRMATION. *********************
   ********************************************************************/
  { tenant: 'adac', page: 'email_confirmation', key: 'TITLE', label: 'Bitte bestätigen Sie jetzt Ihre E-Mail-Adresse.' },
  { tenant: 'adac', page: 'email_confirmation', key: 'DESCRIPTION', label: `
    Eine E-Mail mit einem Link zur Verifizierung Ihrer E-Mail-Adresse sollte sich bereits in Ihrem Posteingang befinden. Wenn Sie den Link anklicken, verifizieren Sie Ihre E-Mail-Adresse und Ihre eingegebenen Daten werden zeitlich befristet gespeichert. Sollten Sie Ihre E-Mail-Adresse nicht innerhalb von 24 Stunden bestätigen und sich auch nicht im THG-Portal einloggen, wird der ADAC Ihre Angaben aus Datenschutzgründen löschen. 
    Bitte kontrollieren Sie auch Ihren Spam-Ordner.` },
  /********************************************************************
   ************************ LOGIN PAGE. *******************************
   ********************************************************************/
  { tenant: 'adac', page: 'login', key: 'TITLE', label: 'Login' },
  { tenant: 'adac', page: 'login', key: 'EMAIL', label: 'E-Mail' },
  { tenant: 'adac', page: 'login', key: 'PASSWORD', label: 'Passwort' },
  { tenant: 'adac', page: 'login', key: 'SUBMIT', label: 'Einloggen' },
  { tenant: 'adac', page: 'login', key: 'LOGIN', label: 'Einloggen' },
  { tenant: 'adac', page: 'login', key: 'LOGOUT', label: 'Ausloggen' },
  { tenant: 'adac', page: 'login', key: 'FORGOT_PASSWORD', label: 'Passwort vergessen' },
  { tenant: 'adac', page: 'login', key: 'I_AM_NEW', label: 'Ich bin neu hier' },
  { tenant: 'adac', page: 'login', key: 'REGISTER', label: 'Registrieren' },
  /********************************************************************
   ************* REGISTRATION FLOW (Registration steps). **************
   ********************************************************************/
  { tenant: 'adac', page: 'registration', key: 'STEP_0_TITLE', label: 'Fahrzeugschein hochladen' },
  { tenant: 'adac', page: 'registration', key: 'STEP_1_TITLE', label: 'Mitgliedervorteil auswählen'},
  { tenant: 'adac', page: 'registration', key: 'STEP_2_TITLE', label: 'Bankdaten hinterlegen' },
  { tenant: 'adac', page: 'registration', key: 'STEP_3_TITLE', label: 'Vertragsangebot' },
  // Registration step 1.
  { tenant: 'adac', page: 'registration_step_0', key: 'TITLE', label: 'Registrieren' },
  { tenant: 'adac', page: 'registration_step_0', key: 'IMAGE_FRONT', label: 'Vorderseite' },
  { tenant: 'adac', page: 'registration_step_0', key: 'IMAGE_BACK', label: 'Rückseite' },
  { tenant: 'adac', page: 'registration_step_0', key: 'UPLOAD', label: 'Hier Hochladen' },
  { tenant: 'adac', page: 'registration_step_0', key: 'REGISTER_MORE_THAN_5', label: 'Mehr als fünf Fahrzeuge anmelden? Zum Sammelupload' },
  { tenant: 'adac', page: 'registration_step_0', key: 'REGISTER_ALL_AT_ONCE', label: 'Fahrzeuge einzeln hochladen' },
  { tenant: 'adac', page: 'registration_step_0', key: 'NEXT', label: 'Weiter' },
  { tenant: 'adac', page: 'registration_step_0', key: 'SKIP', label: 'Überspringen' },
  { tenant: 'adac', page: 'registration_step_0', key: 'EXPLANATION', label: 'Bitte stellen Sie sicher, dass die hochgeladenen Fotos ausschließlich den Fahrzeugschein in hoher Auflösung zeigen und der Text gut lesbar ist.' },
  // Registration step 2.
  { tenant: 'adac', page: 'registration_step_1', key: 'QUESTION', label: `Sind Sie ADAC Mitglied?` },
  { tenant: 'adac', page: 'registration_step_1', key: 'NOTICE', label: `Hinweis: Für die Inanspruchnahme besonderer <a href="https://www.adac.de/services/e-angebote/thg-bonus/">Mitgliedervorteile</a> ist die Eingabe Ihrer gültigen Mitgliedsnummer erforderlich.` },
  { tenant: 'adac', page: 'registration_step_1', key: 'YES', label: `JA` },
  { tenant: 'adac', page: 'registration_step_1', key: 'NO', label: `NEIN` },
  { tenant: 'adac', page: 'registration_step_1', key: 'MEMBER_NUMBER', label: 'Mitgliedsnummer' },
  { tenant: 'adac', page: 'registration_step_1', key: 'MEMBER_NUMBER_CHECK_BUTTON', label: 'Prüfen' },
  { tenant: 'adac', page: 'registration_step_1', key: 'MEMBER_NUMBER_WRONG', label: 'Bitte überprüfen Sie Ihre Eingabe. Diese ADAC-Migleidsnummer ist nicht gültig' },
  { tenant: 'adac', page: 'registration_step_1', key: 'MEMBER_OFFER_DESCRIPTION', label: 'Welchen ADAC-Mitgliedervorteil wünschen Sie sich zusätzlich zu Ihrem ADAC THG Bonus?' },
  { tenant: 'adac', page: 'registration_step_1', key: 'MEMBER_OFFER_1', label: '20 Euro Gutschrift für mein ADAC e-Charge Konto' },
  { tenant: 'adac', page: 'registration_step_1', key: 'MEMBER_OFFER_2', label: `20 Euro zusätzliche Auszahlung zum ADAC THG-Bonus`},
  { tenant: 'adac', page: 'registration_step_1', key: 'BUTTON_SAVE', label: 'Speichern' },
  // Registration step 3.
  { tenant: 'adac', page: 'registration_step_2', key: 'DISCLAIMER', label: `Aus Compliance-Gründen können Sie die IBAN im Nachhinein nicht hier im THG-Portal ändern, sondern Sie müssten ggf. über die ADAC Hotline Kontakt aufnehmen und sich identifizieren. Daher kann es für Sie sinnvoll sein, wenn Sie die IBAN erst eingeben, sobald der ADAC Sie über die Bescheinigung des Umweltbundesamtes und die Auszahlung informiert.` },
  { tenant: 'adac', page: 'registration_step_2', key: 'TITLE', label: 'Wie können wir Sie auszahlen?' },
  { tenant: 'adac', page: 'registration_step_2', key: 'ACCOUNT_HOLDER', label: 'Name des Kontoinhabers' },
  { tenant: 'adac', page: 'registration_step_2', key: 'ACCOUNT_NUMBER', label: 'IBAN' },
  { tenant: 'adac', page: 'registration_step_2', key: 'ACCOUNT_NUMBER_DESCRIPTION', label: 'Die Kontodaten werden ausschließlich für die Auszahlung genutzt' },
  { tenant: 'adac', page: 'registration_step_2', key: 'BUTTON_BACK', label: 'Zurück' },
  { tenant: 'adac', page: 'registration_step_2', key: 'BUTTON_NEXT', label: 'Weiter' },
  { tenant: 'adac', page: 'registration_step_2', key: 'BUTTON_SKIP', label: 'Überspringen' },
  // Registration step 4.
  { tenant: 'adac', page: 'registration_step_3', key: 'TITLE', label: 'Stimmt alles?' },
  { tenant: 'adac', page: 'registration_step_3', key: 'FIRSTNAME', label: 'Vorname' },
  { tenant: 'adac', page: 'registration_step_3', key: 'LASTNAME', label: 'Nachname' },
  { tenant: 'adac', page: 'registration_step_3', key: 'EMAIL', label: 'E-Mail' },
  { tenant: 'adac', page: 'registration_step_3', key: 'FILE_UPLOAD', label: 'Datei-Upload' },
  { tenant: 'adac', page: 'registration_step_3', key: 'VEHICLE_IMAGES', label: 'Fahrzeugschein(e)' },
  { tenant: 'adac', page: 'registration_step_3', key: 'CHECKBOX', label: 'Ich bin der Halter des im Fahrzeugschein genannten reinen Elektrofahrzeugs oder nachweisbar vom Halter berechtigt. Als Halter habe ich keine andere Person als Dritten bestimmt und berechtigt, an meiner Stelle am THG-Quotenhandel teilzunehmen.' },
  { tenant: 'adac', page: 'registration_step_3', key: 'HEADING', label: 'Wie erfolgt der Vertragsschluss mit dem ADAC?\n' },
  { tenant: 'adac', page: 'registration_step_3', key: 'PARAGRAPH_1', label: 'Mit Anklicken des Buttons „Jetzt Vertragsangebot abgeben“ geben Sie ein verbindliches Angebot auf Abschluss eines Vertrages mit dem ADAC über die THG-Quote gemäß den AGB des ADAC ab. Der Vertragsschluss erfolgt, wenn Sie eine E-Mail vom ADAC erhalten, mit der der ADAC Ihr Vertragsangebot annimmt.' },
  { tenant: 'adac', page: 'registration_step_3', key: 'PARAGRAPH_2', label: 'Der damit vereinbarte Betrag für jedes betreffende reine Elektrofahrzeug, das durch das Umweltbundesamt bescheinigt wird, beträgt Euro 350 sowie speziell für ein reines Elektrofahrzeug der Fahrzeugklasse N1 Euro 550 und der Fahrzeugklasse M3 Euro 14.000, ggf. zuzüglich Mitgliedervorteil (Gutschrift für ein ADAC e-Charge Konto oder zusätzliche Auszahlung).\n' },
  { tenant: 'adac', page: 'registration_step_3', key: 'CHECKBOX_DISCLAIMER', label: 'Ich habe die AGB des ADAC zur Kenntnis genommen und bin mit ihrer Geltung einverstanden.' },
  { tenant: 'adac', page: 'registration_step_2', key: 'BUTTON_BACK', label: 'Zurück' },
  { tenant: 'adac', page: 'registration_step_3', key: 'BUTTON_FINISH', label: 'Jetzt Vertragsangebot abgeben' },
  /********************************************************************
   ****************** PROFILE AREA / PERSONAL AREA. *******************
   ********************************************************************/
  { tenant: 'adac', page: 'profile_area', key: 'HEADER', label: 'Hier können Sie ihre Fahrzeuge einsehen und Änderungen vornehmen:' },
  { tenant: 'adac', page: 'profile_area', key: 'NEXT_QUOTA_HEADER', label: 'Danke für Ihr Vertrauen!' },
  { tenant: 'adac', page: 'profile_area', key: 'NEXT_QUOTA_DESCRIPTION', label: 'Möchten Sie auch nächstes Jahr Ihren THG-Bonus über den ADAC vermarkten? Wir kontaktieren Sie rechtzeitig und informieren Sie über unser Angebot für das Jahr 2023.' },
  { tenant: 'adac', page: 'profile_area', key: 'NEXT_QUOTA_CHECKBOX', label: <>Ich möchte ein Angebot zur Vermarktung des THG-Bonus für das Jahr 2023 erhalten</> },
  { tenant: 'adac', page: 'profile_area', key: 'NEXT_QUOTA_BUTTON', label: 'Absenden' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLES', label: 'Fahrzeuge' },
  { tenant: 'adac', page: 'profile_area', key: 'PAYOUT', label: 'Auszahlungen' },
  { tenant: 'adac', page: 'profile_area', key: 'PROFILE', label: 'Profil' },
  { tenant: 'adac', page: 'profile_area', key: 'PERSONAL_DATA', label: 'Persönliche Daten' },
  { tenant: 'adac', page: 'profile_area', key: 'PASSWORD_CHANGE', label: 'Passwort ändern' },
  { tenant: 'adac', page: 'profile_area', key: 'BANK_DETAILS', label: 'Bankverbindung' },
  { tenant: 'adac', page: 'profile_area', key: 'ADD', label: 'Hinzufügen' },
  { tenant: 'adac', page: 'profile_area', key: 'MODIFY', label: 'Ändern' },
  { tenant: 'adac', page: 'profile_area', key: 'VIEW', label: 'Einsehen' },
  { tenant: 'adac', page: 'profile_area', key: 'FIRSTNAME', label: 'Vorname' },
  { tenant: 'adac', page: 'profile_area', key: 'LASTNAME', label: 'Nachname' },
  { tenant: 'adac', page: 'profile_area', key: 'EMAIL', label: 'E-Mail' },
  { tenant: 'adac', page: 'profile_area', key: 'STREET', label: 'Straße' },
  { tenant: 'adac', page: 'profile_area', key: 'HOUSE_NUMBER', label: 'Hausnummer' },
  { tenant: 'adac', page: 'profile_area', key: 'ZIP', label: 'PLZ' },
  { tenant: 'adac', page: 'profile_area', key: 'CITY', label: 'Stadt' },
  { tenant: 'adac', page: 'profile_area', key: 'TAX_NUMBER', label: 'Steuernummer' },
  { tenant: 'adac', page: 'profile_area', key: 'TAX_ID', label: 'USt-ID' },
  { tenant: 'adac', page: 'profile_area', key: 'BUTTON_BACK', label: 'Zurück' },
  { tenant: 'adac', page: 'profile_area', key: 'BUTTON_SAVE', label: 'Speichern' },
  { tenant: 'adac', page: 'profile_area', key: 'PRIVATE_PERSON', label: 'Privatperson' },
  { tenant: 'adac', page: 'profile_area', key: 'COMPANY', label: 'Unternehmen' },
  { tenant: 'adac', page: 'profile_area', key: 'TAX_DEDUCTIBLE', label: 'Vorsteuerabzugberechtigt' },
  { tenant: 'adac', page: 'profile_area', key: 'TAX_DEDUCTIBLE_YES', label: 'Ja' },
  { tenant: 'adac', page: 'profile_area', key: 'TAX_DEDUCTIBLE_NO', label: 'Nein' },
  { tenant: 'adac', page: 'profile_area', key: 'PASSWORD_CURRENT', label: 'Aktuelles Passwort' },
  { tenant: 'adac', page: 'profile_area', key: 'PASSWORD_NEW', label: 'Neues Passwort' },
  { tenant: 'adac', page: 'profile_area', key: 'PASSWORD_CONFIRM', label: 'Passwort wiederholen' },
  { tenant: 'adac', page: 'profile_area', key: 'YOUR_ACCOUNT_DETAILS', label: 'Ihre Kontodaten' },
  { tenant: 'adac', page: 'profile_area', key: 'ACCOUNT_HOLDER', label: 'Name des Kontoinhabers' },
  { tenant: 'adac', page: 'profile_area', key: 'ACCOUNT_NUMBER', label: 'IBAN' },
  { tenant: 'adac', page: 'profile_area', key: 'MY_VEHICLES', label: 'Meine Fahrzeuge' },
  { tenant: 'adac', page: 'profile_area', key: 'MANUFACTURER_MODEL', label: 'Marke, Modell (in Prüfung)' },
  { tenant: 'adac', page: 'profile_area', key: 'PLEASE_CORRECT', label: 'Bitte korrigieren' },
  { tenant: 'adac', page: 'profile_area', key: 'LICENSEPLATE', label: 'Kennzeichen:' },
  { tenant: 'adac', page: 'profile_area', key: 'UPLOADED_AT', label: 'Hochgeladen am:' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_STATUS_UPLOADED', label: 'Hochgeladen' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_STATUS_FAULTY', label: 'Fehlerhaft' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_STATUS_VERIFIED', label: 'Vorgeprüft' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_STATUS_SUBMITTED', label: 'UBA - eingereicht' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_STATUS_CERTIFIED', label: 'UBA - bescheinigt' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_STATUS_MARKETED', label: 'Ausgezahlt' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_ERROR', label: 'Fehler:' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_ERROR_', label: 'Leider abgelehnt - Führerschein hochgeladen. Bitte ZLB! (Fahrzeugschein) hochladen.' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_MANUFACTURER_MODEL', label: 'Fahrzeug hinzufügen' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_ADD', label: 'Fahrzeug hinzufügen' },
  { tenant: 'adac', page: 'profile_area', key: 'VEHICLE_MODIFY', label: 'Bearbeiten' },
  { tenant: 'adac', page: 'profile_area', key: 'MEMBERSHIP', label: 'Mitgliedervorteil' },
  // CONFIRMATION LABELS.
  { tenant: 'adac', page: 'profile_area', key: 'CONFIRM_IS_OWNER_CHECKBOX_LABEL', label: 'Ich sichere zu, dass ich Halter des im Fahrzeugschein genannten Elektrofahrzeugs oder nachweisbar berechtigt bin.' },
  { tenant: 'adac', page: 'profile_area', key: 'CONFIRM_AGB_CHECKBOX_LABEL', label: <>Ich habe die <a href='https://www.adac.de/services/e-angebote/thg-bonus/agb/' target='_blank' rel='noreferrer'>AGB</a> des ADAC zur Kenntnis genommen und bin mit Ihrer Geltung einverstanden.</> },
];