import { Button } from '@mui/material'
import { useContext, useState } from 'react'
import { ContextActionEnum, GlobalContext } from '../../../context/GlobalState'
import { getVehicleById } from '../../../vehicles-admin/api/getVehicleById'
import DeleteVehiclesDialog from '../../../vehicles-admin/dialogs/DeleteVehiclesDialog/DeleteVehiclesDialog'
import EditVehicleDialog from '../../../vehicles-admin/dialogs/EditVehicleDialog/EditVehicleDialog'
import CustomTooltip from './CustomTooltip'
import PreviewVehicleIds from './PreviewVehicleIds'

const VehicleIdsColumn = ({ vehicleIds }: { vehicleIds: number[] }) => {
  const { modal, setModal } = useContext(GlobalContext)

  const limit = 3
  const [editVehicle, setEditVehicle] = useState(null)

  const handleClick = (id: number | null) => handleEditVehicleRegistrationModal(id);

  const handleEditVehicleRegistrationModal = async (id: number | null) => {
    if (!id) return setEditVehicle(null)

    try {
      const vehicle = await getVehicleById(id)
      setEditVehicle(vehicle)
    } catch (err) {
      console.error(err)
    }
  }

  if (!vehicleIds?.length) return <></>

  return (
    <>
      <PreviewVehicleIds
        vehicleIds={vehicleIds}
        limit={limit}
        handleClick={handleClick}
        customElement={
          <CustomTooltip
            title={
              <PreviewVehicleIds
                vehicleIds={vehicleIds}
                handleClick={handleClick}
                variant='contained'
              />
            }
            placement='top'
          >
            {vehicleIds?.length > limit ? <Button>More</Button> : <></>}
          </CustomTooltip>
        }
      />
      {/* Open edit vehicle dialog. */}
      {editVehicle && (
        <EditVehicleDialog
          setModal={handleEditVehicleRegistrationModal}
          setSelectedIds={() => {}}
          vehicle={editVehicle}
          nextVehicleId={null}
        />
      )}

      {/* Open delete vehicle dialog. */}
      {editVehicle && modal?.type === ContextActionEnum.DELETE_VEHICLES && (
        <DeleteVehiclesDialog
          setModal={setModal}
          vehicles={[editVehicle?.id]}
        />
      )}
    </>
  )
}

export default VehicleIdsColumn
