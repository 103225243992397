import { Button } from "@mui/material";
import { ContextActionEnum, ContextScopeEnum } from "../../context/GlobalState";
import DownloadIcon from '@mui/icons-material/Download';
import { useFeature } from "../../../../hooks/useFeature";

/**
 * "If the context is invoicing and no invoices are selected, show a button to download bank transfer
 * exports."
 * 
 * The function is a React component, which is a function that returns a React element. The React
 * element is a button that calls the setModal function when clicked
 * @param props - { setModal: Function, context: ContextScopeEnum, selectedIds:
 * number[] }
 * @returns A button that will download bank transfer exports.
 */
const DownloadBankTransferExportsAction = (props: { setModal: Function, context: ContextScopeEnum, selectedIds: number[] }) => {
  const tab = props.context === ContextScopeEnum.INVOICING;
  const hasNoneSelected = !props.selectedIds.length;
  const allowedFeature = useFeature('DownloadBankTransferExports')
  const show = tab && hasNoneSelected && allowedFeature

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.setModal({ type: ContextActionEnum.BANK_TRANSFER_EXPORTS })}>
        <DownloadIcon />
        &nbsp;Bank Transfer Exports
      </Button>
    );
  }
  return <></>;
}

export default DownloadBankTransferExportsAction;