import { Tenant } from "../models/tenant";

// list of featuresn
export type IFeatures = 'DownloadBankTransferExports' | 'InvoicerV2' | 'InvoicerV1' | 'MembershipOptionsInEditUser' | 'Analytics'

// featureConfig object type
type IFeatureConfig = { [key in Tenant]: IFeatures[] }

// feature configuration
export const featureConfig: IFeatureConfig = {
    adac: ['DownloadBankTransferExports', 'InvoicerV2', 'MembershipOptionsInEditUser'],
    allianz: ['DownloadBankTransferExports', 'InvoicerV2', 'MembershipOptionsInEditUser'],
}
