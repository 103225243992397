import { API_URL } from '../../../../constants/api';
// import { stringify } from 'query-string';
import { IPagination } from '../../context/GlobalState';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Make an API request using the provided url.
 */
export const getInvoiceTemplatesPromise = (
  filter: any,
  sort: string[],
  pagination: IPagination,
  predefinedFilters: any[]
): Promise<any> => {
  // // Calculate the range.
  // const range = [pagination.range[0], pagination.range[1]];
  // // Add the required query params.
  // const params = {
  //   filter: JSON.stringify(
  //     predefinedFilters?.length ? predefinedFilters[0].filter : filter
  //   ),
  //   range: JSON.stringify(range),
  //   sort: JSON.stringify(sort),
  // };
  // // Stringify the query params.
  // const stringParams = `${stringify(params)}`;
  // Build up the complete url
  const url = `${API_URL}/v2/invoice/template?`;

  return fetcher(url)
    .then((data) => data)
    .catch((error) => {
      return error;
    });
  // Handle errors related to wrong status (401...).
  // error && handleStatusError(error);
};
