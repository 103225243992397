import { Button } from "@mui/material";
import { canUseFeature } from "../../../hooks/canUseFeature";
import { toggleMarkInvoicePromise } from "./api/markInvoice";
import toast from 'react-hot-toast';
import { downloadFile } from "./api/downloadFile";

/**
 * Flag the provided upload id.
 * 
 * @param uploadId number
 * @returns void
 */
 const markInvoiceSample = (invoiceId: number, mark: boolean, setTriggerAction: Function, setRefreshList: Function) => {
  toggleMarkInvoicePromise(invoiceId, mark, setTriggerAction, setRefreshList)
    /**
     * Notify the user the upload that
     * the upload has been flagged.
     */
    .then(result => toast.success('Upload als bearbeitet markiert".'))
    .catch(error => toast.error('Upload als NICHT bearbeitet markiert.'))
}

const getColumnsV1 = (setTriggerAction: Function, setRefreshList: Function, fileDownloadLink: any) => [
  {
    field: "debtorNumber",
    headerName: "Debtor",
    width: 200,
  },
  {
    field: "invoiceId",
    headerName: "Invoice",
    width: 200,
  },
  {
    field: "id",
    headerName: "InvoiceID",
    width: 200,
  },
  {
    field: "isCompany",
    headerName: "Is company",
    width: 120,
  },
  {
    field: "vatReclaimEntitled",
    headerName: "Vorsteuerabzugsberechtigt",
    width: 200,
  },
  {
    field: "name",
    headerName: "Name",
    width: 300,
  },
  {
    field: "company",
    headerName: "Company",
    width: 100,
    valueGetter: (params: any) =>
      !params.row.company ? "none" : params.row.company,
  },
  {
    field: "email",
    headerName: "Email",
    width: 300,
  },
  {
    field: "accountOwner",
    headerName: "Kontoinhaber",
    width: 200,
  },
  {
    field: "iban",
    headerName: "IBAN",
    width: 200,
  },
  // Add download links
  {
    field: "1invoiceLink",
    headerName: "Carbonify Invoice",
    width: 160,
    renderCell: (params: any) => {
      return <Button variant="outlined" color="error" onClick={() => downloadFile('Carbonify Invoice', `/invoice/${params.id}/download/invoice`, `carbonify_invoice_${params.invoiceId}_${new Date().toLocaleDateString()}`, fileDownloadLink)}>DOWNLOAD</Button>;
    }
  },
  {
    field: "vehicleListLink",
    headerName: "Vehicle List",
    width: 160,
    renderCell: (params: any) => {
      return <Button variant="outlined" color="error" onClick={() => downloadFile('Vehicle List', `/invoice/${params.id}/download/vehicleList`, `vehicle_list_${params.invoiceId}_${new Date().toLocaleDateString()}`, fileDownloadLink)}>DOWNLOAD</Button>;
    }
  },
  {
    field: "creditNoteLink",
    headerName: "Credit Note",
    width: 160,
    renderCell: (params: any) => {
      return <Button variant="outlined" color="error" onClick={() => downloadFile('Credit Note', `/invoice/${params.id}/download/creditNote`, `credit_note_${params.invoiceId}_${new Date().toLocaleDateString()}`, fileDownloadLink)}>DOWNLOAD</Button>;
    }
  },
  {
    field: "sampleInvoiceLink",
    headerName: "Sample Invoice",
    width: 160,
    renderCell: (params: any) => {
      return <Button variant="outlined" color="error" onClick={() => downloadFile('Sample Invoice', `/invoice/${params.id}/download/creditNote`, `sample_invoice_${params.invoiceId}_${new Date().toLocaleDateString()}`, fileDownloadLink)}>DOWNLOAD</Button>;
    }
  }
];

const getColumnsV2 = (setTriggerAction: Function, setRefreshList: Function, fileDownloadLink: any) => [
  {
    field: "debtorNumber",
    headerName: "Debtor",
    width: 200,
  },
  {
    field: "id",
    headerName: "Invoice",
    width: 200,
  },
  {
    field: "isCompany",
    headerName: "Is company",
    width: 120,
  },
  {
    field: "vatReclaimEntitled",
    headerName: "Vorsteuerabzugsberechtigt",
    width: 200,
  },
  {
    field: "name",
    headerName: "Name",
    width: 300,
  },
  {
    field: "company",
    headerName: "Company",
    width: 100,
    valueGetter: (params: any) =>
      !params.row.company ? "none" : params.row.company,
  },
  {
    field: "email",
    headerName: "Email",
    width: 300,
  },
  {
    field: "accountOwner",
    headerName: "Kontoinhaber",
    width: 200,
  },
  {
    field: "bankAccountIBAN",
    headerName: "IBAN",
    width: 200,
  },
  // Add download links
  {
    field: "vehicleListLink",
    headerName: "Vehicle List",
    width: 160,
    renderCell: (params: any) => {
      return <Button variant="outlined" color="error" onClick={() => downloadFile('Vehicle List', `/v2/invoice/${params.id}/download/vehiclesList`, `vehicle_list_${params.invoiceId}_${new Date().toLocaleDateString()}`, fileDownloadLink, true)}>DOWNLOAD</Button>;
    }
  },
  {
    field: "creditNoteLink",
    headerName: "Credit Note",
    width: 160,
    renderCell: (params: any) => {
      return <Button variant="outlined" color="error" onClick={() => downloadFile('Credit Note', `/v2/invoice/${params.id}/download/creditNote`, `credit_note_${params.invoiceId}_${new Date().toLocaleDateString()}`, fileDownloadLink, true)}>DOWNLOAD</Button>;
    }
  },
  {
    field: "sampleInvoiceLink",
    headerName: "Sample Invoice",
    width: 160,
    renderCell: (params: any) => {
      return <Button variant="outlined" color="error" onClick={() => downloadFile('Sample Invoice', `/v2/invoice/${params.id}/download/sampleInvoice`, `sample_invoice_${params.invoiceId}_${new Date().toLocaleDateString()}`, fileDownloadLink, true)}>DOWNLOAD</Button>;
    }
  },
  {
    field: 'actionButton',
    headerName: 'Markieren als',
    width: 150,
    cellClassName: 'center--cell',
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      return params.row.sampleInvoiceLink ?
        // The UNMARK button.
        <Button disabled={!params.row.isB2B || !params.row.vatReclaimEntitled || !params.row.invoiced || params.row.issued} variant="outlined" color="error" onClick={() => markInvoiceSample(params.row.id, false, setTriggerAction, setRefreshList)}>UNMARK INVOICED</Button> :
        // The MARK button.
        <Button disabled={!params.row.isB2B || !params.row.vatReclaimEntitled || params.row.invoiced || params.row.issued} variant="contained" color="success" onClick={() => markInvoiceSample(params.row.id, true, setTriggerAction, setRefreshList)}>MARK INVOICED</Button>;
    }
  },
];

export const getColumns = (
  v2: boolean,
  setTriggerAction: Function,
  setRefreshList: Function,
  fileDownloadLink: any
) =>
  v2
    ? getColumnsV2(setTriggerAction, setRefreshList, fileDownloadLink)
    : getColumnsV1(setTriggerAction, setRefreshList, fileDownloadLink)
