import { FieldTitleHelper } from "./fieldTitleHelper";
import { IFormConfig, IFormField } from "./interfaces";
import { ValidationHelper } from "./validationHelper";
import { ValueHelper } from "./valueHelper";

/**
 * Helps parse through the fields and update
 * the values.
 */
export class FieldHelper {
  /**
   * Helps process the field titles and finds
   * translations if there are any.
   */
  private fieldTitleHelper = new FieldTitleHelper();
  /**
   * Helps process the provided values and
   * converts them to the type required by
   * the configuration file.
   */
  private valueHelper = new ValueHelper();
  /**
   * Helps with the validation of each
   * validation rule set on the fields.
   */
  public validationHelper = new ValidationHelper();

  /**
   * Convert the current vehicle object into a
   * list of fields.
   * 
   * @param vehicle IVehicleRegistrationRow
   * @returns IFormField[]
   */
  public getFieldList(object: any, formConfig: IFormConfig, mode: string): IFormField[] {
    let fields: IFormField[] = [];
    /**
     * Collect all the vehicle fields in
     * currentVehicleFields property.
     */
    for (const [key, value] of Object.entries(object)) {
      const foundConfigField = formConfig.fieldsConfig.find(f => f.fieldName === key);
      // Check if the current field has the requested mode configured.
      const fieldHasRequestedMode = foundConfigField && (foundConfigField.mode === mode || !('mode' in foundConfigField));
      /**
       * Don't include current field if we are
       * not in edit mode.
       */
      if (fieldHasRequestedMode) {
        fields.push({
          id: foundConfigField.id,
          key,
          name: `${this.fieldTitleHelper.getFieldTitle(key)}${foundConfigField.required ? ' *' : ''}`,
          value: this.valueHelper.getEmptyValue(value),
          initialValue: this.valueHelper.getEmptyValue(value),
          saveOnlyIfChanged: foundConfigField.saveOnlyIfChanged || false,
          required: !!foundConfigField.required,
          hidden: (!!foundConfigField.hidden || !fieldHasRequestedMode),
          hiddenWhen: foundConfigField.hiddenWhen || [],
          columns: foundConfigField.columns || 1,
          filterOn: foundConfigField.filterOn || undefined,
          type: foundConfigField.type,
          disabled: foundConfigField.disabled || foundConfigField.disableIfMode === mode,
          options: foundConfigField.options || undefined,
          message: foundConfigField.message || undefined,
          label: foundConfigField.label || undefined,
          // custom color for carId(FIN) field
          color: foundConfigField.fieldName === 'carId' 
            ? this.valueHelper.getEmptyValue(value)?.length === 17 
              ? 'success' 
              : 'error' 
            : null
        });
      }
    }
    // console.log('fields:', fields);
    /**
     * Check if the current field should be displayed
     * judging from the hiddenWhen property.
     */
    fields = this.validationHelper.filterFieldsByHiddenWhenConditions(fields);
    /**
     * Enhance each field in the list with the
     * corresponding validators.
     */
    const allFields = this.validationHelper.attachValidators(fields);
    return allFields;
  }

  /**
   * Apply the change to the provided field and update
   * also the list with the new field object and return
   * it.
   * 
   * @param fields IFormField[]
   * @param field IFormField
   * @param event any
   * @param setIsValid Function
   */
  public applyChange(fields: IFormField[], field: IFormField, event: any, setIsValid: Function): IFormField[] {
    let newFieldsList = [...fields];
    /**
     * Find the original field and save the
     * new value on it.
     */
    // Convert the value to the required type.
    const newValue = this.valueHelper.convertEventValueToValue(field, event);
    return this.updateFields(newFieldsList, field, newValue, setIsValid);
  }

  /**
   * Update the value for the provided field inside
   * the provided fields list and return the new list.
   * 
   * @param fields IFormField[]
   * @param currentField IFormField
   * @param event any
   * @param setIsValid Function
   * @returns IFormField[]
   */
  public updateFields(fields: IFormField[], currentField: IFormField, newValue: any, setIsValid: Function): IFormField[] {
    // Apply the new value to the current field.
    const newFieldsList = fields.map(field => field.key !== currentField.key ?
      field : 
      this.updateField(field, newValue, setIsValid)
    );
    return newFieldsList;
  }

  /**
   * Update the value for the provided field and also update
   * the form validity status.
   * 
   * @param field IFormField
   * @param newValue any
   * @param setIsValid Function
   * @returns IFormField
   */
  public updateField(field: IFormField, newValue: any, setIsValid: Function): IFormField {
    return {
      ...field,
      /**
       * Set the new value of the
       * current field.
       */
      value: newValue,
      /**
       * Validate the field value against the
       * validators currently attached to the
       * field.
       */
      validationErrorText: this.validationHelper.validate(field, newValue, setIsValid),
      /** 
       * Set custom style 
       * for carId(FIN) field
       */
      color: field.key === 'carId' 
        ? newValue?.length === 17 
          ? 'success' 
          : 'error' 
        : null
    }
  }

  /**
   * Filter out the fields based on the field configuration
   * data and return the currated list.
   *
   * @param fields IFormField[]
   * @param mode string
   * @returns IFormField[]
   */
  public filterFieldsByMode(fields: IFormField[], mode: string): IFormField[] {
    return fields.filter(f => f.filterOn !== mode);
  }

  /**
   * Remove all the fields which should not be saved if there
   * are no changes made to them.
   * 
   * @param fields IFormField[]
   * @returns IFormField[]
   */
  public filterFieldsByChange(fields: IFormField[]): IFormField[] {
    return fields.filter(f => !f.saveOnlyIfChanged || (!!f.saveOnlyIfChanged && f.value !== f.initialValue));
  }

  /**
   * Remove the empty fields from the provided list
   * if they are of type string and have no value.
   * 
   * @param fields IFormField[]
   * @returns IFormField[]
   */
  public removeEmptyFields(fields: IFormField[], mode: string): IFormField[] {
    return mode === 'create' ?
      // Remove empty fields if we are in CREATE mode.
      fields.filter(f => !!f.value) :
      // Set empty fields to NULL if in EDIT mode
      fields.map(f => typeof f.value === 'string' && f.value === '' ? { ...f, value: null } : f);
  }
}