import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'

/**
 * It takes an array of usernames and deletes them from the database
 * @param {string[]} usernames - string[]
 * @returns A promise that resolves to an object with the following properties:
 */
export const deletePreviewUsers = (usernames: string[]): Promise<any> => {
  const url = `${API_URL}/users/preview`
  const method = 'DELETE'

  return fetcher(url, {
    method,
    body: JSON.stringify({ usernames }),
  })
}
