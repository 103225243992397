import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IModalType } from '../../context/GlobalState';
import { BootstrapDialogTitle } from '../../common/dialog/BootstrapDialog';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { createUBASubmission } from '../api/createUBASubmission';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

/**
 * Defines the SellQuotasDialog fields
 * that are required.
 */
interface IProps {
  modal: IModalType;
  setModal: any;
}

/**
 * The list of radio buttons to
 * be rendered.
 */
const ubaYears = [
  { id: 1, label: 'UBA year 2022', urlParam: 2022 },
  { id: 2, label: 'UBA year 2023', urlParam: 2023 },
];

const CreateUBASubmissionDialog: React.FC<IProps> = ({ modal, setModal }) => {
  // Indicates if the user has confirmed his selection
  const [confirmed, setConfirmed] = useState<boolean>(false);
  // Holds the selected uba year value.
  const [selectedValue, setSelectedValue] = useState<string>('');
  /**
   * Trigger the action after selecting
   * the quota year.
   */
  const handleSelection = (event: any) => {
    // We set the selected value.
    setSelectedValue((event.target as HTMLInputElement).value)
    // We reset the confirmed prop.
    setConfirmed(false);
  }
  
  /**
   * The function creates a new UBA submission and then closes the modal
   */
  const handleSubmit = () => {
    createUBASubmission(selectedValue);
    // Close the modal.
    setModal(null);
  }

  return (
    <div>
      <BootstrapDialog
        onClose={() => setModal(null)}
        aria-labelledby="customized-dialog-title"
        open={true}
      >

        {/* The title of the dialog. */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setModal(null)}>
          {modal.type.toString()}
        </BootstrapDialogTitle>

        <DialogContent dividers>

          {/* The radio buttons for selecting the Quota year. */}
          <FormControl>

            <FormLabel id="demo-radio-buttons-group-label">Bitte wählen Sie das Quota-Jahr aus:</FormLabel>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleSelection}
            >
              {ubaYears.map((ubaYear: any) =>
                <FormControlLabel key={ubaYear.id} value={ubaYear.urlParam} control={<Radio />} label={ubaYear.label} />
              )}
            </RadioGroup>

          </FormControl>

          {selectedValue &&
            <Box sx={{ mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 2,}}>

              <Typography>Sind Sie sicher, dass Sie fortfahren möchten</Typography>

              <Button variant="contained" onClick={() => setConfirmed(true)}>JA</Button>

            </Box>
          }

        </DialogContent>

        <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

          <Button onClick={() => setModal(null)}>SCHLIEßEN</Button>

          {confirmed && <Button onClick={() => handleSubmit()}>EINREICHEN</Button>}

        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default CreateUBASubmissionDialog
