import { Typography } from "@mui/material";

interface IFormTitleProps {
  label: string;
  style?: any;
}

/**
 * Displays a title element using the provided
 * label and style properties.
 */
const FormTitle: React.FC<IFormTitleProps> = ({ label, style }) => {
  return (
    <Typography sx={style}>{label}</Typography>
  )
}

export default FormTitle;