import { Button } from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { ContextScopeEnum, ContextActionEnum } from "../../context/GlobalState";

/**
 * Check if the marketable filter has been selectedUsernames
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedUsernames string
 * @returns boolean
 */
const ShowTemplateAction = (props: { selectedUsernames: string[], setModal: Function, context: ContextScopeEnum }) => {
  const show = !!props.selectedUsernames.length && props.context === ContextScopeEnum.USERS;

  if (show) {
    return (
      <Button color="inherit" onClick={() => props.setModal({ type: ContextActionEnum.BULK_EMAIL })}>
        <ForwardToInboxIcon />
        &nbsp;Send {props.selectedUsernames.length} { props.selectedUsernames.length > 1 ? 'emails' : 'email' }
      </Button>
    );
  }
  return <></>;
}

export default ShowTemplateAction;