import { IFormField } from '../../form-generator/interfaces'
import { Box, Button } from '@mui/material'
import { useState } from 'react'

/* Defining the props that are passed to the component. */
export interface IDeleteBankDataProps {
  accountOwnerField: IFormField
  ibanField: IFormField
  onChange: (field: IFormField, event: any) => void
}

/**
 * It renders a button that deletes the bank data
 */
export default function DeleteBankData(props: IDeleteBankDataProps) {
  const [deleting, setDeleting] = useState(false)
  /* It checks if the user has entered bank data. */
  const hasBankData = props.accountOwnerField.value || props.ibanField.value

  /* It deletes the bank data */
  const deleteBankData = async () => {
    setDeleting(true)

    props.onChange(props.ibanField, { target: { value: '' } })
    await new Promise(r => setTimeout(r, 100)) // workaround to update both form fields
    props.onChange(props.accountOwnerField, { target: { value: '' } })

    setDeleting(false)
  }

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Button
        disabled={!hasBankData || deleting}
        variant='contained'
        color='error'
        onClick={deleteBankData}
      >
        Bankdaten löschen
      </Button>
    </Box>
  )
}
