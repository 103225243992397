import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Box } from '@mui/material'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController
)

const options = {
  plugins: {
    datalabels: {
      backgroundColor: '#FFF',
      borderRadius: 50,
      font: {
        size: 15,
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      type: 'linear' as const,
      display: true,
      stacked: true,
      position: 'left' as const,
    },
    y1: {
      type: 'linear' as const,
      display: true,
      stacked: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
}

/**
 * It's a React component that renders a Chart.js chart
 * @param props - { data: any }
 */
const CommonChart = (props: { data: any }) => (
  <Box
    sx={{
      width: 1000,
      height: 500,
      display: 'inline-block',
      background: 'white',
      padding: '.5rem',
      borderRadius: '0.5rem',
      boxShadow: '0 30px 40px rgba(0,0,0,.1)',
    }}
  >
    <Chart
      type='bar'
      options={options}
      data={props.data}
      plugins={[ChartDataLabels]}
    />
  </Box>
)

export default CommonChart
