import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Make an API request using the provided url.
 */
export const getDebtorCSV = (isB2C: boolean): Promise<any> => {
  // Build up the complete url
  const url = `${API_URL}/users/csv/${isB2C ? 'b2c' : 'b2b'}`;

  return fetcher(url, { method: 'GET' })
    .then((data) => data)
    .catch((error) => {
      return error;
    });
};
