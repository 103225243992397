import NotAdmin from "../components/Admin/common/NotAdmin"
import getRole from "../utils/getRole"
import AdminRoute from "./AdminRoute"

const AdminPanel = () => {
  // implement here for testing purpose
  const role = getRole()

  return (['admin', 'analytics'].includes(role) ? <AdminRoute /> : <NotAdmin />)
}

export default AdminPanel