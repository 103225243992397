import { API_URL } from '../../../../constants/api'
import { stringify } from 'query-string'
import { fetcher } from '../../../../utils/fetcher'
import { getVehiclesByUsernames } from '../../vehicles-admin/api/getVehiclesByUsernames'

/**
 * It gets all the users from the API, then gets all the vehicles from the API, then maps the users to
 * their vehicles
 * @param  - {
 * @returns An object with the following properties:
 *   - data: An array of objects with the following properties:
 *     - username: The username of the user.
 *     - email: The email of the user.
 *     - firstName: The first name of the user.
 *     - lastName: The last name of the user.
 */
export const getUsersPromise = async ({
  username,
  email,
  firstName,
  lastName,
}: {
  username?: string
  email?: string
  firstName?: string
  lastName?: string
}): Promise<any> => {
  // Add the required query params.
  const params = {
    username: username ?? '',
    email: email ?? '',
    firstName: firstName ?? '',
    lastName: lastName ?? '',
  }
  // Stringify the query params.
  const stringParams = `${stringify(params)}`
  // Build up the complete url
  const url = `${API_URL}/users?${stringParams}`

  /* A promise that returns the data from the API. */
  const users = await fetcher(url)

  /* Mapping the users to their usernames. */
  const usernames = users.data.map((user: any) => user.username)

  /* Getting all the vehicles from the API and mapping them to their usernames. */
  let vehicles: any = null;
  if (!!usernames?.length) {
    vehicles = await getVehiclesByUsernames(usernames)
  }

  /* Mapping the users to their vehicles. */
  const data = users.data.map((user: any) => ({
    ...user, vehicleRegistrations: vehicles?.data?.find((vehicle: any) => vehicle.username === user.username)?.vehicleRegistrations || []
  }))
  
  /* Returning the original users object with the data property replaced with the new data. */
  return {...users, data}
}
