import { IFormField } from "./interfaces";

/**
 * Helps with the field value operations like
 * updating value and converting values to the
 * required type.
 */
export class ValueHelper {

  /**
   * Helps convert different values coming from the
   * event to the required value by the field.
   */
  public convertEventValueToValue(field: IFormField, event: any): any {
    /**
     * Check if we need to get a value from
     * a provided object dependent on the
     * current field key.
     */
    switch (field.key) {
      case 'ownerUsername':
        return event?.username ?? '';
      default:
        break;
    }
    /**
     * Otherwise we return the event value
     * depending on the field type.
     */
    switch (field.type) {
      case 'date':
        try {
          return (event as Date).toISOString();
        } catch (error) {
          return event;
        }
      case 'string':
        return event.target.value;
      case 'boolean':
        return event.target.checked;
      case 'select':
        return event.target.value;
      case 'remote-email':
        return event;
      case 'business-changer':
        return event.target.checked;
      case 'link':
        return event.target.value;
      case 'textarea':
        return event.target.value;
      case 'vin':
        return (event as string | undefined)?.toUpperCase();
      case 'number':
        return parseInt(event.target.value, 10);
      default:
        return event.target.value;
    }
  }

  /**
   * Get the formatted value for the provided
   * value by figuring out the type of primitive
   * so when we save the data we don't get errors
   * from the backend side.
   * 
   * @param value any
   * @returns any
   */
  public getEmptyValue(value: any): any {
    switch (typeof value) {
      case 'boolean':
        return value;
      case 'string':
        return value || '';
      case 'number':
        return value || 0;
      default:
        return '';
    }
  }
}