import { readToken } from "./auth/auth"

const parseJwt = (token: string) => {
  var base64Url = token?.split('.')?.[1]
  var base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(window.atob(base64)?.split('')?.map(c => '%' + ('00' + c?.charCodeAt(0)?.toString(16))?.slice(-2))?.join(''))

  return JSON.parse(jsonPayload)
};

const getRole = () => {
  const token = readToken()
  if (!token) return false

  return parseJwt(token)?.['custom:role']
}

export default getRole