export interface TemplateMapItem {
  isCustom: boolean;
  template: string;
  placeholders: string[];
}

export const templatePlaceholderMapping: TemplateMapItem[] = [
  { isCustom: false, template: 'SignupTemplate', placeholders: ['firstName', 'lastName', 'link', 'userName', 'verificationCode'] },
  { isCustom: false, template: 'ForgotPasswordTemplate', placeholders: ['firstName', 'lastName', 'link', 'userName', 'resetCode'] },
  { isCustom: false, template: 'AdminCreateUserTemplate', placeholders: ['username', 'temporaryPassword'] },
  { isCustom: false, template: 'ContactEmail', placeholders: ['email', 'name', 'message'] },
  { isCustom: false, template: 'UbaFailedTemplate', placeholders: ['firstName', 'lastName', 'errorMessage'] },
  { isCustom: false, template: 'UbaApprovedTemplate', placeholders: ['firstName', 'lastName', 'vehicleIdentificationNumber'] },
  { isCustom: false, template: 'VehicleIncompleteTemplate', placeholders: ['firstName', 'lastName', 'errorMessage'] },
  { isCustom: true, template: 'CustomTemplate', placeholders: ['firstName', 'lastName', 'company'] },
]
