import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It creates a UBA submission for a given year
 * @param {string} ubaYear - The year for which the UBA submission is being created.
 * @returns A promise that resolves to null.
 */
export const createUBASubmission = (ubaYear: string): Promise<any> => {
  // Resolve silently if no UBA year provided.
  if (!ubaYear) return new Promise((resolve) => resolve(null));

  const url = `${API_URL}/quota-sales/generateUbaSubmission/${ubaYear}`;
  return fetcher(`${url}`, { method: 'POST' }).then();
};
