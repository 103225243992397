import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'

/**
 * It sends a POST request to the `/users/{username}/resetPassword` endpoint
 * @param {string} username - The username of the user you want to reset the password for.
 * @returns A promise that resolves to the response from the server.
 */
export const resetPassword = async (username: string) => {
  const url = `${API_URL}/users/${username}/resetPassword`
  return await fetcher(`${url}`, {
    method: 'POST'
  })
}