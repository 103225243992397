import { Copyright } from '@mui/icons-material'
import { Box, Container, Grid, Toolbar } from '@mui/material'
import { useContext } from 'react';
import ProgressCard from '../common/cards/ProgressCard';
import { ContextActionEnum, GlobalContext } from '../context/GlobalState';
import DownloadBankTransferExportsDialog from './dialogs/DownloadBankTransferExportsDialog/DownloadBankTransferExportsDialog';
import MarkAsPaidOut from './dialogs/MarkAsPaidOut/MarkAsPaidOutDialog';
import InvoiceList from './InvoiceList';

function Invoices() {
  /**
   * Get all the required properties from the
   * global context.
   */
  const {
    modal,
    setModal,
    triggerAction,
    selectedIds,
  } = useContext(GlobalContext);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
      }}
    >
      <Toolbar />

      <Container sx={{ mt: 4, mb: 4 }} maxWidth={false}>
        <Grid container spacing={2}>

          {triggerAction &&
            <Grid item xs={12}>
              <ProgressCard />
            </Grid>
          }

          <Grid item lg={12}>

            <InvoiceList />

          </Grid>

        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>

      {/* The Download Bank Transfer Exports Dialog. */}
      {modal && modal.type === ContextActionEnum.BANK_TRANSFER_EXPORTS &&
        <DownloadBankTransferExportsDialog
          setModal={setModal}
        />
      }

      {/* The Download Bank Transfer Exports Dialog. */}
      {modal && modal.type === ContextActionEnum.MARK_PAID_OUT &&
        <MarkAsPaidOut
          setModal={setModal}
        />
      }

    </Box>
  )
}

export default Invoices

