import { useContext, useEffect, useRef, useState } from 'react'
import { GlobalContext } from '../context/GlobalState'
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro'
import { Grid, LinearProgress, Stack } from '@mui/material'
import { getColumns } from './Columns'

/**
 * Custom loading overlay to indicate to the user that
 * requested data is being loaded from the backend.
 */
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}

const InvoiceTemplatesList = () => {
  const {
    invoiceTemplates,
    loading,
    pagination,
    setPagination,
    total,
    selectedIds,
    setSelectedIds,
    setTriggerAction,
    setRefreshList,
  } = useContext(GlobalContext)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rows, setRows] = useState<any[]>([])
  /**
   * Refs used for the file download links
   * we need to trigger automatically for
   * saving the files.
   */
  const fileDownloadLink = useRef<any>(null)

  /**
   * Prepare the invoice templates table.
   */
  useEffect(() => {
    setRows(invoiceTemplates?.length ? invoiceTemplates : [])
  }, [invoiceTemplates])

  const getItemsRange = (
    currentPageNumber: number,
    currentPageSize: number
  ): void => {
    var totalItemsCount = total
    var numberOfItemsPerPage = currentPageSize
    var page = currentPageNumber

    // page: 0 => 10 start: 0 end: 9
    // page: 1 => 10 start: 11 end: 20
    var start = page * numberOfItemsPerPage
    // start = start < 0 ? 0 : start;
    var end = Math.min(start + numberOfItemsPerPage - 1, totalItemsCount)
    /**
     * Set the changed pagination properties on the
     * global context.
     */
    setPagination({ range: [start, end], pageSize: currentPageSize })
    // Set the current page in the component state.
    setCurrentPage(page)
  }

  return (
    <Grid container>
      <Grid item md={12}>
        <div style={{ height: pagination.pageSize > 10 ? '85vh' : 630 }}>
          <DataGridPro
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  No rows in DataGrid
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  There are no results to display
                </Stack>
              ),
            }}
            sx={{ backgroundColor: 'white' }}
            rows={rows}
            columns={getColumns(
              setTriggerAction,
              setRefreshList,
              fileDownloadLink
            )}
            loading={loading}
            paginationMode='server'
            page={currentPage}
            columnBuffer={
              getColumns(setTriggerAction, setRefreshList, fileDownloadLink)
                ?.length + 1
            } // important for testing
            pageSize={pagination.pageSize}
            rowsPerPageOptions={[100, 500, 1000, 2000]}
            onPageChange={page =>
              !loading && getItemsRange(page, pagination.pageSize)
            }
            onPageSizeChange={newPageSize =>
              !loading && getItemsRange(currentPage, newPageSize)
            }
            pagination={true}
            rowCount={total}
            onSelectionModelChange={(newSelection: any) =>
              setSelectedIds(newSelection)
            }
            selectionModel={selectedIds}
            checkboxSelection
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default InvoiceTemplatesList
