import { TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { IFieldConfig } from './generator'
import { validate } from './validators';

interface IInputFieldProps {
  name: string;
  autofocus?: boolean;
  field?: IFieldConfig;
  style?: any;
}

/**
 * Generates an input field based on the
 * provided configuration properties.
 */
const InputField: React.FC<IInputFieldProps> = (props) => {
  // The configured default border color for the input element.
  const defaultBorderColor = '#BBB';
  // The configured error border color for the input element.
  const errorBorderColor = '#C00';
  // Holds the input value of the current field.
  const [ inputValue, setInputValue ] = useState<string>('');
  // Indicates if the current input is valid or not.
  const [ valid, setValid ] = useState<boolean>(true);
  // Holds the validation message for the current field.
  const [ message, setMessage ] = useState<string>('');
  // Indicates the current border color of the input element.
  const [ borderColor, setBorderColor ] = useState<string>(defaultBorderColor);
  // Used to set the autofocus if required.
  const inputRef = useRef(null);
  /**
   * Applies the value changes to the
   * current field.
   */
  const handleChange = (event: any) => {
    const newValue = event.target.value;
    // Reflect the new value in the input field.
    setInputValue(newValue);
    // Set the value on the current field object.
    props.field.value = newValue;
    // Show validation errors if any.
    validateField(newValue);
  }
  /**
   * Run the validation rules currently
   * present on this field object.
   */
  const validateField = (value: string) => {
    const validationMessage = validate(value, props.field.validators);
    /**
     * If there is a validation message and it's
     * different from the previous one then update
     * the message.
     */
    if (validationMessage !== message) {
      setMessage(validationMessage);
    }
    /**
     * Set the validation status on the
     * field object.
     */
    props.field.valid = !validationMessage;
    /**
     * Update the border color of the
     * input element.
     */
    setBorderColor(!!validationMessage ? errorBorderColor : defaultBorderColor);
    /**
     * Update the validity of the current
     * field and reflect it in the UI.
     */
    setValid(!validationMessage);
  }

  return (
    <TextField
      sx={props.style}
      error={!valid}
      ref={inputRef}
      id={props.field.name}
      label={props.field.label}
      type={props.field.type}
      autoComplete="off"
      helperText={message}
      onChange={(event: any) => handleChange(event)}
      value={inputValue}
      placeholder={props.field.placeholder}
    />
  )
}

export default InputField