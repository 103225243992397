import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { Button, ButtonGroup } from '@mui/material'

interface PreviewVehicleIdsProps {
  vehicleIds: number[]
  variant?: 'text' | 'outlined' | 'contained'
  limit?: number
  customElement?: EmotionJSX.Element,
  handleClick: any
}

/**
 * It renders a list of vehicle ids, and when one is clicked, it opens a modal to edit the vehicle
 * @param {PreviewVehicleIdsProps}  - `vehicleIds` - An array of vehicle IDs to display.
 */
const PreviewVehicleIds = ({
  vehicleIds,
  variant,
  limit,
  customElement,
  handleClick
}: PreviewVehicleIdsProps) => {

  return (
    <>
      <ButtonGroup variant={variant ?? 'text'} size='small'>
        {vehicleIds
          .sort((a, b) => a - b)
          .slice(0, limit ?? vehicleIds.length)
          .map(id => (
            <Button
              key={id}
              onClick={() => handleClick(id)}
            >
              {id}
            </Button>
          ))}
        {customElement}
      </ButtonGroup>
    </>
  )
}

export default PreviewVehicleIds
