import CommonChart from '../components/CommonChart'
import { useContext, useState } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import { Box, CircularProgress, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'

type VehicleClassType = 'M1' | 'N1' | 'M3' | 'other' | 'all'
type StatsType = Record<VehicleClassType, number>
export type VehicleAnalyticsType = {
  total: StatsType
  monthly: {
    month: string
    data: StatsType
  }[]
}

/**
 * It returns a chart component that displays the number of vehicles per month
 * @returns A chart with the number of vehicles per month.
 */
const VehicleAnalytics = () => {
  const [vehicleClassFilter, setVehicleClassFilter] = useState<VehicleClassType>('all')

  const { analytics } = useContext(GlobalContext)
  if (!analytics?.vehicles?.res?.monthly?.length) return <CircularProgress />

  const vehicleAnalytics = analytics.vehicles.res as VehicleAnalyticsType
  const monthly = vehicleAnalytics.monthly

  const data = {
    labels: monthly
      .map(data => data.month)
      .map(date => date.split('-').reverse().join('/')),
    datasets: [
      vehicleClassFilter === 'all'
        ? {
            type: 'line' as const,
            fill: true,
            label: 'Total',
            text: 'Total',
            data: monthly.map(data => data.data.all),
            backgroundColor: '#FCF5C7',
          }
        : {},
      ['M1', 'all'].includes(vehicleClassFilter) ?{
        type: 'bar' as const,
        label: 'M1',
        text: 'M1',
        data: monthly.map(data => data.data.M1),
        backgroundColor: '#ADF7B6',
      }: {},
      ['N1', 'all'].includes(vehicleClassFilter) ?{
        type: 'bar' as const,
        label: 'N1',
        text: 'N1',
        data: monthly.map(data => data.data.N1),
        backgroundColor: '#A0CED9',
      }: {},
      ['M3', 'all'].includes(vehicleClassFilter) ?{
        type: 'bar' as const,
        label: 'M3',
        text: 'M3',
        data: monthly.map(data => data.data.M3),
        backgroundColor: '#FFEE93',
      }: {},
      ['other', 'all'].includes(vehicleClassFilter) ?{
        type: 'bar' as const,
        label: 'Andere',
        text: 'Andere',
        data: monthly.map(data => data.data.other),
        backgroundColor: '#FFC09F',
      }: {},
    ].filter(dataset => dataset?.label),
  }

  return (
    <Box>
      {/* A radio group that allows the user to select a filter. */}
      <Box display='flex' alignItems='center'>
        <Typography mr={4}>Vehicle Class:</Typography>
        <RadioGroup
          row
          onChange={event =>
            setVehicleClassFilter(event.target.value as VehicleClassType)
          }
        >
          <FormControlLabel
            value='M1'
            control={<Radio />}
            label='M1'
            checked={vehicleClassFilter === 'M1'}
          />
          <FormControlLabel
            value='N1'
            control={<Radio />}
            label='N1'
            checked={vehicleClassFilter === 'N1'}
          />
          <FormControlLabel
            value='M3'
            control={<Radio />}
            label='M3'
            checked={vehicleClassFilter === 'M3'}
          />
          <FormControlLabel
            value='other'
            control={<Radio />}
            label='Andere'
            checked={vehicleClassFilter === 'other'}
          />
          <FormControlLabel
            value='all'
            control={<Radio />}
            label='All'
            checked={vehicleClassFilter === 'all'}
          />
        </RadioGroup>
      </Box>

      <CommonChart data={data} />
    </Box>
  )
}

export default VehicleAnalytics
