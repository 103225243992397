import { API_URL } from '../../../../constants/api'
import { fetcher } from '../../../../utils/fetcher'
import { VehicleAnalyticsType } from '../tables/VehicleAnalytics'

/**
 * It makes a GET request to the `/vehicle-registrations/analytics` endpoint and returns the data
 * @returns A promise that resolves to an object with the following properties:
 */
export const getVehicleAnalytics = async (): Promise<{res: VehicleAnalyticsType, timestamp: string}> => {
  const url = `${API_URL}/vehicle-registrations/analytics`
  return { res: await fetcher(url), timestamp: new Date().toLocaleTimeString() }
}