/*
 * model VehicleRegistration {
 *  id                 Int                       @id @default(autoincrement())
 *  uniqueId           String?
 *  uniqueUserId       String?
 *  frontImage         String?
 *  backImage          String?
 *  owner              User                      @relation(fields: [ownerId], references: [id])
 *  ownerId            Int
 *  registeredAt       DateTime?
 *  firstRegisteredAt  DateTime?
 *  firstName          String?
 *  lastName           String?
 *  street             String?
 *  zip                String?
 *  city               String?
 *  licensePlate       String?                   //@unique // TODO
 *  carId              String?                   //@unique // TODO
 *  mainInspectionDate String?
 *  manufacturerCode   String?
 *  modelCode          String?
 *  vehicleClass       String?
 *  verificationStatus Int                       @default(0)
 *  createdAt          DateTime                  @default(now())
 *  verifiedAt         DateTime?
 *  ubaVerifiedAt      DateTime?
 *  soldAt             DateTime?
 *  bevChecked         Boolean                   @default(false)
 *  imageErrorStatus   Int?
 *  errMsg             String?
 *  carbonifyAuditor   String?
 *  auditStatus        String?
 *  apiSessionId       String?
 *  status             VehicleRegistrationStatus @default(UPLOADED)
 *  quotaSales         QuotaSale[]
 *}
*/

export const emptyVehicle = {
  uniqueId: '',
  uniqueUserId: '',
  ownerId: 0,
  ownerUsername: '',
  carId: '',
  firstName: '',
  lastName: '',
  street: '',
  zip: '',
  city: '',
  licensePlate: '',
  mainInspectionDate: '',
  manufacturerCode: '',
  manufacturer: '',
  modelCode: '',
  model: '',
  vehicleClass: '',
  frontImage: '',
  backImage: '',
  registeredAt: '',
  firstRegisteredAt: '',
  verificationStatus: 0,
  createdAt: '',
  verifiedAt: '',
  ubaVerifiedAt: '',
  soldAt: '',
  // TODO: Check what the default value should be.
  bevChecked: false,
  errMsg: '',
  carbonifyAuditor: '',
  auditStatus: '',
  apiSessionId: '',
  remoteEmail: '',
  status: '',
  uba2022: false,
  uba2023: false,
};