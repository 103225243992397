import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import toast from 'react-hot-toast'
import DialogActions from '@mui/material/DialogActions'
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../../common/dialog/BootstrapDialog'
import { generateInvoices } from '../../../users-admin/api/generateInvoices'
import { Typography } from '@mui/material'
import InvoiceBatch from '../../../common/dialog/InvoiceBatch'
import { useState } from 'react'

export interface GenerateInvoicesDialogProps {
  selectedIds: number[]
  setModal: Function
}

export default function GenerateInvoicesDialog(
  props: GenerateInvoicesDialogProps
) {
  const [limit, setLimit] = useState(300)
  const [skip, setSkip] = useState(0)

  const handleInvoiceTrigger = async () => {
    try {
      const res = await generateInvoices({ limit, skip })
      if (res.status !== 204) throw new Error('')
      toast.success('Invoice generation triggered')
    } catch (error) {
      toast.error('Invoice generation failed')
    } finally {
      props.setModal(null)
    }
  }

  return (
    <BootstrapDialog
      onClose={() => props.setModal(null)}
      aria-labelledby='customized-dialog-title'
      open={true}
    >
      <BootstrapDialogTitle
        id='customized-dialog-title'
        onClose={() => props.setModal(null)}
      >
        Generate invoices
      </BootstrapDialogTitle>
      <DialogContent
        dividers
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography align='center'>
          Are you sure you want to bulk generate invoices for all quotas in
          status SOLD?
        </Typography>
        <InvoiceBatch
          limit={limit}
          skip={skip}
          setLimit={setLimit}
          setSkip={setSkip}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setModal(null)}>Cancel</Button>
        <Button onClick={handleInvoiceTrigger}>Generate invoices</Button>
      </DialogActions>
    </BootstrapDialog>
  )
}
